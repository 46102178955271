import { Component, Input, OnInit } from '@angular/core';
import { ChatMessage } from 'src/app/models/chatroom';
import { Company } from 'src/app/models/company';
import { User } from 'src/app/models/user';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit {
  @Input() chat: ChatMessage;
  @Input() canaction = true;
  // mock current user
  private curentUser: User;
  public company: Company;

  datetime = '';
  myChat = false;
  constructor(private chatService: ChatService) {}

  ngOnInit(): void {
    this.chatService.user.subscribe((currentUser) => {
      this.curentUser = currentUser;
      if (this.chat) {
        this.myChat = this.isMyChat();
        this.datetime = this.getTimeFormat();
      }
    });

    this.chatService.company.subscribe((company) => {
      this.company = company;
    });
  }

  public isMyChat(): boolean {
    return this.chat ? this.chat.user.id === this.curentUser.id : false;
  }

  public getTimeFormat(): string {
    const today = new Date();
    const format: any = {
      hour: 'numeric',
      minute: 'numeric',
    };
    if (this.chat.sent_at.getDate() !== today.getDate()) {
      format.year = 'numeric';
      format.month = 'long';
      format.day = 'numeric';
    }
    return Intl.DateTimeFormat('sg', format).format(this.chat.sent_at);
  }

  public openMenu(event: Event): void {
    const customeEvent = new CustomEvent('openmenu', { bubbles: true });
    event.target.dispatchEvent(customeEvent);
  }
}
