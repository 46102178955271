import { Injectable } from '@angular/core';
import { Auth, signOut } from '@angular/fire/auth';
import { signInWithCustomToken } from '@firebase/auth';

/**
 * This service is a thin wrapper around the Firebase Auth
 * object, which was provided in AppModule. It provides a
 * single point of access to the Firebase Auth APIs. It is also
 * useful for providing mocks the Firebase Auth APIs in unit tests.
 */
@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  constructor(private firebaseAuth: Auth) {}

  /**
   * Thin wrapper for signInWithCustomToken API
   * @param token the firebase token to be used for firebase auth
   * @returns a promise resolving to UserCredential associated with
   * the provided token
   */
  signInWithCustomToken(token: string) {
    return signInWithCustomToken(this.firebaseAuth, token);
  }

  /**
   * Thin wrapper for signOut API, simply terminates the
   * current firebase auth session
   */
  signOut() {
    return signOut(this.firebaseAuth);
  }
}
