import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import chatIntegration from '../../assets/content/services/chat-integration-service.json';
import {
  ChatMessage,
  ChatRoomMessageRead,
  ChatRoomUpdate,
} from '../models/chatroom';
import { SHOW_LOADER } from '../interceptors/interceptor-context';

@Injectable({
  providedIn: 'root',
})
export class ChatIntegrationService {
  protected serviceURL = chatIntegration;
  protected readonly httpOptions = {
    headers: new HttpHeaders({}),
    body: {},
    params: new HttpParams(),
    context: new HttpContext(),
  };
  constructor(protected http: HttpClient) {}

  getChatroom(pageSize: number, page: number): Observable<any> {
    return this.http.get<any[]>(
      `${this.serviceURL.Chatroom}?page=${page}&page_size=${pageSize}`,
      this.httpOptions,
    );
  }

  getRoomMessage(
    chatroomId: string,
    pageSize: number,
    page: number,
  ): Observable<any> {
    return this.http.get<any[]>(
      `${this.serviceURL.Chatroom}${chatroomId}/messages/?page=${page}&page_size=${pageSize}`,
      this.httpOptions,
    );
  }

  sendMessage(chatroomId: string, body: ChatMessage): Observable<any> {
    const sendChatHttpOptions = {
      headers: new HttpHeaders({}),
      body: {},
      params: new HttpParams(),
      context: new HttpContext().set(SHOW_LOADER, false),
    };
    return this.http.post<any[]>(
      `${this.serviceURL.Chatroom}${chatroomId}/messages/`,
      body,
      sendChatHttpOptions,
    );
  }

  readMessage(chatroomId: string, body: ChatRoomMessageRead): Observable<any> {
    const readChatHttpOptions = {
      headers: new HttpHeaders({}),
      body: {},
      params: new HttpParams(),
      context: new HttpContext().set(SHOW_LOADER, false),
    };
    return this.http.post<any[]>(
      `${this.serviceURL.Chatroom}${chatroomId}/messages/read/`,
      body,
      readChatHttpOptions,
    );
  }

  updateChatroom(chatroomId: string, body: ChatRoomUpdate): Observable<any> {
    return this.http.put<any[]>(
      `${this.serviceURL.Chatroom}${chatroomId}/`,
      body,
      this.httpOptions,
    );
  }

  downloadChatroomHistory(
    chatroomId: string,
    startDatetime: string,
    endDatetime: string,
  ): Observable<any> {
    return this.http.get(
      `${this.serviceURL.Chatroom}${chatroomId}/download_history/?start_dt=${startDatetime}&end_dt=${endDatetime}`,
      {
        responseType: 'text',
      },
    );
  }

  setGlobalLoadingContext(globalLoading = true) {
    this.httpOptions.context = new HttpContext().set(
      SHOW_LOADER,
      globalLoading,
    );
  }
}
