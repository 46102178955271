import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Message } from '../message';

export class ForbiddenMessage extends Message {
  getMessage(content: HttpErrorResponse | string): Message {
    this.icon = 'error';
    this.color = 'info';
    this.type = HttpStatusCode.Forbidden;
    this.content = content;
    this.processMessage(content as HttpErrorResponse);
    return this;
  }
  processMessage(error: HttpErrorResponse) {
    // need to supress message based on exception details
    if (
      error.error.detail === 'Password Expired.' &&
      error.error.uid &&
      error.error.reset_token
    ) {
      this.enableCustomException = true;
    }
  }
  close(): void {
    // Close method
  }

  action(): void {
    // Action method
  }
}
