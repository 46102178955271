import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { FileWrapper } from 'src/app/models/file-wrapper';

@Component({
  selector: 'arp-file-upload-preview',
  templateUrl: './arp-file-upload-preview.component.html',
  styleUrls: ['./arp-file-upload-preview.component.scss'],
})
export class ArpFileUploadPreviewComponent {
  /**
   * Required FileWrapper @Input to provide file name,
   * file type, and the file itself (could be a url to the file, or
   * a BLOB object directly)
   */
  @Input() fileWrapper: FileWrapper;
  /**
   * Remove event emitted when clicking on remove button. Re-emit the
   * FileWrapper object to parent component.
   */
  @Output() remove = new EventEmitter<FileWrapper>();
  /**
   * Event emitted when clicking on the file container. Re-emit the
   * FileWrapper object to parent component to decide what to do.
   */
  @Output() fileClick = new EventEmitter<FileWrapper>();
  /**
   * Used to access and manually control when to launch ripple. This is needed
   * to limit ripple to launch only when clicking on the file container,
   * not when clicking on the remove button.
   */
  @ViewChild(MatRipple) matRipple: MatRipple;

  /**
   * Event handler for when user click on remove button
   * @param event simply used to stop propagation to file container
   */
  onRemoveClick(event: Event) {
    event.stopImmediatePropagation();
    this.remove.emit(this.fileWrapper);
  }

  /**
   * Event handler for when user clicking on file container
   */
  onFileClick() {
    this.matRipple.launch({ centered: true });
    this.fileClick.emit(this.fileWrapper);
  }
}
