import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChatMessage, ChatRoom } from 'src/app/models/chatroom';
import { ChatService } from 'src/app/services/chat.service';
import { finalize, take } from 'rxjs/operators';
import { SvgLoaderService } from 'src/app/services/svg-loader.service';

@Component({
  selector: 'app-chat-popup',
  templateUrl: './chat-popup.component.html',
  styleUrls: ['./chat-popup.component.scss'],
})
export class ChatPopupComponent implements OnInit {
  @Input() room: ChatRoom;
  @ViewChild('chatContent', { static: false }) set chatContentElement(
    content: ElementRef,
  ) {
    if (content?.nativeElement) {
      this.chatbody = content.nativeElement as HTMLElement;
      this.scoreToDown();
    }
  }
  @ViewChild('chatbox', { static: false }) set chatboxElement(
    content: ElementRef,
  ) {
    if (content?.nativeElement) {
      this.chatbox = content.nativeElement as HTMLElement;
    }
  }

  @ViewChild('menu', { static: false }) set menuElement(content: ElementRef) {
    if (content?.nativeElement) {
      content.nativeElement.focus();
    }
  }

  chatbody: HTMLElement;
  chatbox: HTMLElement;
  isonline = false;
  disabledSend = false;
  openPopup = true;
  openMenu = false;
  menuStyle: any;
  focusMessage: ChatMessage;
  replyMessage: ChatMessage;
  mute = false;
  perPage = 20;
  page = 1;
  chatMessages: ChatMessage[];

  svgContents: { [key: string]: string } = {};

  constructor(
    private chatService: ChatService,
    private svgLoaderService: SvgLoaderService,
  ) {}

  ngOnInit(): void {
    this.chatMessages = [];
    if (this.room) {
      this.setOnlineStatus();
      this.loadChatMessages();
    }
    this.chatService.membersStatusUpdated.subscribe(() => {
      this.setOnlineStatus();
    });

    this.loadSvgIcons();
  }

  loadSvgIcons() {
    const svgUrls = [
      { name: 'chat-sender', url: `assets/images/chat-sender.svg` },
    ];

    svgUrls.forEach((svg) => {
      this.svgLoaderService
        .loadSvg(svg.url)
        .pipe(take(1))
        .subscribe(
          (content) => (this.svgContents[svg.name] = content),
          (error) => console.error('Error loading SVG:', error),
        );
    });
  }

  private loadChatMessages(): void {
    this.chatService.loadChatMessages(this.room.id, this.perPage, this.page);
    this.chatService
      .retriveChat(this.room.id)
      .subscribe((messages: ChatMessage[]) => {
        this.chatMessages = messages;
        setTimeout(() => this.scoreToDown());
      });
  }

  scoreToDown() {
    this.chatbody.scrollTop = (
      this.chatbody.children[0] as HTMLElement
    ).offsetHeight;
  }

  togglePopup(): void {
    this.openPopup = !this.openPopup;
  }

  toggleSound(): void {
    this.mute = !this.mute;
  }

  close(): void {
    this.chatService.close(this.room);
  }

  onChatScroll(): void {
    this.closeMenu();
  }

  openDialogTool(event: Event, chatMessage: ChatMessage) {
    this.openMenu = true;
    this.focusMessage = chatMessage;
    const menuPosition = (event.target as HTMLElement).getBoundingClientRect();
    const boxPosition = this.chatbody.getBoundingClientRect();
    const popupHeight = this.focusMessage.type === 'quotation' ? 26.6 : 80;
    const top =
      menuPosition.y - boxPosition.y + popupHeight + 20 > boxPosition.height
        ? menuPosition.y - boxPosition.y - popupHeight
        : menuPosition.y - boxPosition.y;
    this.menuStyle = {
      'left.px': menuPosition.x - boxPosition.x,
      'top.px': top,
    };
  }

  closeMenu(): void {
    if (this.openMenu) {
      this.openMenu = false;
    }
  }

  cancelReply(): void {
    this.replyMessage = null;
  }

  reply(): void {
    this.replyMessage = this.focusMessage;
    this.chatbox.focus();
  }

  forwardMessage(): void {
    this.chatService.forwardMessage.next(this.focusMessage);
    this.chatbox.focus();
  }

  saveMessage(): void {
    this.chatbox.focus();
  }

  deleteMessage(): void {
    this.chatbox.focus();
  }

  enterSend(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      const textArea = event.target as HTMLTextAreaElement;
      const message = textArea.value;
      if (message) {
        this.chatService
          .sendChatMessage(this.room.id, {
            message,
            user: this.chatService.user.value,
          } as ChatMessage)
          .pipe(
            finalize(() => {
              textArea.value = '';
              textArea.style.height = '18px';
            }),
          )
          .subscribe();
      }
    }
  }

  checkLine(event: KeyboardEvent): void {
    const message = (event.target as any).value;
    const numberOfLineBreaks = (message.match(/\n/g) || []).length;
    const newHeight = 18 + numberOfLineBreaks * 18;
    (event.target as any).style.height =
      (newHeight > 90 ? 90 : newHeight) + 'px';
  }

  activeRoom(): void {
    this.chatService.activeRoom.next(this.room);
    if (this.room.unread_messages > 0) {
      this.chatService.readMessage(this.room);
    }
  }

  inactiveRoom(): void {
    this.chatService.activeRoom.next(undefined);
  }

  setOnlineStatus(): void {
    this.isonline = this.room.members.some((member) => {
      // Exclude from the check the same user
      if (
        this.chatService.user.value &&
        this.chatService.user.value.id === member.user_id
      ) {
        return false;
      }
      return member.online;
    });
  }

  closeError(): void {
    this.room.error = '';
  }
}
