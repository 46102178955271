import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from './base-api.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PasswordSetupService extends BaseAPIService {
  protected serviceURL = {
    auth_password_reset_confirm_create: 'auth/password/reset/confirm/',
    auth_password_reset_confirm_read: 'auth/password/reset/confirm/',
  };
  constructor(protected http: HttpClient) {
    super(http);
  }

  postToPasswordReset(userId, token, data): Observable<any> {
    return this.http
      .post<any[]>(
        this.baseUrl +
          this.serviceURL.auth_password_reset_confirm_create +
          userId +
          '/' +
          token +
          '/',
        data,
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
          return throwError(() => err);
        }),
      );
  }

  getUserData(uid, token) {
    return this.http
      .get<any[]>(
        this.baseUrl +
          this.serviceURL.auth_password_reset_confirm_read +
          uid +
          '/' +
          token +
          '/',
        this.httpOptions,
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
          return throwError(() => err);
        }),
      );
  }
}
