import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DynamicSettingsResponse } from 'src/app/models/api/responses/dynamic-settings';
import { GPSNRForm } from 'src/app/models/api/responses/user-info';
import { ProfileName } from 'src/app/models/profile-name';
import { GPSNRFormService } from 'src/app/services/gpsnr-form.service';
import { LoginService } from 'src/app/services/login.service';
import { StorageService } from 'src/app/services/storage.service';
import configuration from './forms-configuration.json';
import { Manufacturer } from 'src/app/helpers/enum-manufacturer';

@Component({
  selector: 'app-gpsnr-form',
  templateUrl: './gpsnr-form.component.html',
  styleUrls: ['./conti-form.component.scss', './pirelli-form.component.scss'],
})
export class GPSNRFormComponent implements OnInit, OnDestroy {
  private paperFormElement: ElementRef;
  gpsnrForms: GPSNRForm[];
  gpsnrForm: GPSNRForm;
  private gpsnrFormName: string;
  showGPSNRForm: boolean;
  companyName: string;
  profileName: string;
  showProfileDropDown: boolean;
  defaultImageSource = '/assets/images/user.svg';
  imageSource = this.defaultImageSource;
  notices: string[];
  title: string;
  manufacturer: Manufacturer;
  paperFormScript: HTMLScriptElement;

  constructor(
    private loginService: LoginService,
    private gpsnrFormService: GPSNRFormService,
    private storage: StorageService,
    private router: Router,
  ) {}

  @ViewChild('paperForm') set content(content: ElementRef) {
    if (content) {
      this.paperFormElement = content;
      this.setupPaperForm();
    }
  }

  ngOnInit(): void {
    this.gpsnrFormService.getGPSNRFormsData().subscribe((result) => {
      this.gpsnrForms = result.results;
      const relativeUrl = this.router.url;
      if (relativeUrl === '/continental-gpsnr-form') {
        this.manufacturer = Manufacturer.CONTINENTAL;
      } else if (relativeUrl === '/pirelli-gpsnr-form') {
        this.manufacturer = Manufacturer.PIRELLI;
      }
      this.gpsnrForm = this.gpsnrForms.find(
        (item: GPSNRForm) => item.manufacturer == this.manufacturer,
      );
      this.showGPSNRForm = !this.gpsnrForm.submitted_form;
      this.gpsnrFormName = this.getGPSNRFormName();

      if (this.manufacturer == Manufacturer.CONTINENTAL) {
        this.notices = configuration[Manufacturer.CONTINENTAL]['notices'];
        this.title = configuration[Manufacturer.CONTINENTAL]['title'];
      } else if (this.manufacturer == Manufacturer.PIRELLI) {
        this.notices = configuration[Manufacturer.PIRELLI]['notices'];
        this.title = configuration[Manufacturer.PIRELLI]['title'];
      }
    });

    const profile = this.storage.retrieve<ProfileName>('profileName');
    this.profileName = profile.name;
    this.companyName = profile.companyName;
    this.showProfileDropDown = false;
  }

  setupPaperForm() {
    window.addEventListener('PaperformSubmission', this.formSubmitted);
    window.addEventListener('PaperformPageChange', this.pageChanged);
    this.paperFormElement.nativeElement.setAttribute(
      'data-paperform-id',
      this.gpsnrFormName,
    );
    this.paperFormElement.nativeElement.setAttribute(
      'prefill',
      `scp_email_address=${this.storage.retrieve<string>(
        'email',
      )}&scp_company_name=${this.companyName}&force=1`,
    );
    const script = document.createElement('script');
    script.src = 'https://paperform.co/__embed.min.js';
    document.body.appendChild(script);
  }

  // temp fix to check validity of user's token to fix an unintended behaviour which required the user to start filling up the form from blank again.
  // when a user is logged in for more than 8 hours and on press 'Submit' button of paperform,
  // the submission is correctly recorded on paperform's dashboard but not on the backend as the token is expired.
  pageChanged = (data) => {
    if (data?.detail?.totalPages === data?.detail?.currentPage) {
      this.gpsnrFormService.getGPSNRFormData(this.gpsnrForm.id).subscribe();
    }
  };

  formSubmitted = (data) => {
    const patchData = {
      submission_id: data.detail.submission_id,
      form_name: this.gpsnrFormName,
    } as GPSNRForm;
    this.paperFormScript?.parentNode?.removeChild(this.paperFormScript);
    this.gpsnrFormService
      .patchGPSNRFormData(this.gpsnrForm.id, patchData)
      .subscribe((result) => {
        this.showGPSNRForm = false;
        this.gpsnrForm = result;
        const gpsnrForms =
          this.storage.retrieve<GPSNRForm[]>('gpsnrForm') ?? [];
        const updatedGpsnrForms = gpsnrForms.map((item) => {
          if (item.manufacturer == this.manufacturer) {
            return result;
          } else {
            return item;
          }
        });
        this.storage.store('gpsnrForm', updatedGpsnrForms);
      });
  };

  onProfileClick() {
    this.showProfileDropDown = !this.showProfileDropDown;
  }

  onLogoutClick() {
    this.loginService.postLogout().subscribe((_) => {
      this.storage.store('lastLogout', Date.now());
      this.storage.clear();
      if (this.manufacturer == Manufacturer.CONTINENTAL) {
        this.router.navigate(['continental']);
      } else if (this.manufacturer == Manufacturer.PIRELLI) {
        this.router.navigate(['pirelli']);
      }
    });
  }

  getGPSNRFormName() {
    const dynamicSettings =
      this.storage.retrieve<DynamicSettingsResponse>('dynamicSettings');
    let formName = '';
    if (this.manufacturer == Manufacturer.CONTINENTAL) {
      formName = 'CONTI_FORM_NAME';
    } else if (this.manufacturer == Manufacturer.PIRELLI) {
      formName = 'PIRELLI_FORM_NAME';
    }
    return (
      dynamicSettings.settings.find((item) => item.key === formName)?.value ??
      ''
    );
  }

  downloadSubmittedData() {
    this.gpsnrFormService
      .getGPSNRFormSubmittedDataPdf(this.gpsnrForm.id)
      .subscribe();
  }

  ngOnDestroy(): void {
    this.paperFormScript?.parentNode?.removeChild(this.paperFormScript);
    window.removeEventListener('PaperformSubmission', this.formSubmitted);
    window.removeEventListener('PaperformPageChange', this.pageChanged);
  }
}
