const copySymbol = Symbol();

/**
 * TAKEN FROM: https://github.com/angular/angular-cli/blob/master/packages/angular_devkit/core/src/utils/object.ts
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepCopy<T>(value: T): T {
  if (Array.isArray(value)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return value.map((o: any) => deepCopy(o)) as unknown as T;
  } else if (value && typeof value === 'object') {
    const valueCasted = value as {
      [copySymbol]?: T;
      toJSON?: () => string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    };
    if (valueCasted[copySymbol]) {
      // This is a circular dependency. Just return the cloned value.
      return valueCasted[copySymbol];
    }

    if (valueCasted['toJSON']) {
      return JSON.parse(valueCasted['toJSON']());
    }

    const copy = Object.create(Object.getPrototypeOf(valueCasted));
    valueCasted[copySymbol] = copy;
    for (const key of Object.getOwnPropertyNames(valueCasted)) {
      copy[key] = deepCopy(valueCasted[key]);
    }
    // Previously the [copySymbol] key is set to undefined, leaving it behind and dirty the original
    // object. We should delete the key from the original object.
    // valueCasted[copySymbol] = undefined;
    delete valueCasted[copySymbol];
    return copy;
  } else {
    return value;
  }
}

/**
 * Check if every value of the object is truthy
 * @param obj the object to be checked
 * @returns true if every value of the object is truthy, false otherwise
 */
export function isObjectValuesTruthy(obj: object): boolean {
  return Object.values(obj).every((value) => value);
}
