import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { GPSNRForm, GPSNRFormResult } from '../models/api/responses/user-info';
import GPSNRFormServiceURL from 'src/assets/content/services/conti-form-service.json';
import { saveAs } from 'file-saver';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GPSNRFormService {
  protected readonly httpOptions = {
    headers: new HttpHeaders({}),
    body: {},
    params: new HttpParams(),
    context: new HttpContext(),
  };
  protected serviceURL = GPSNRFormServiceURL;

  constructor(protected http: HttpClient) {}

  getGPSNRFormsData(): Observable<GPSNRFormResult> {
    return this.http.get<GPSNRFormResult>(
      `${this.serviceURL.GPSNRForm}`,
      this.httpOptions,
    );
  }

  getGPSNRFormData(id: number): Observable<GPSNRForm> {
    return this.http.get<GPSNRForm>(
      `${this.serviceURL.GPSNRForm}${id}/`,
      this.httpOptions,
    );
  }

  patchGPSNRFormData(id: number, data: GPSNRForm): Observable<GPSNRForm> {
    return this.http.patch<GPSNRForm>(
      `${this.serviceURL.GPSNRForm}${id}/`,
      data,
    );
  }

  getGPSNRFormSubmittedDataPdf(id: number) {
    return this.http
      .get<any>(`${this.serviceURL.GPSNRForm}${id}/download_pdf/`, {
        responseType: 'blob' as 'json',
      })
      .pipe(
        tap((result) => {
          saveAs(result, 'submission_results.pdf');
        }),
      );
  }
}
