import { BaseUrlService } from './base-url.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SvgLoaderService {
  constructor(
    private http: HttpClient,
    private baseUrlService: BaseUrlService,
  ) {}

  loadSvg(svgUrl: string): Observable<string> {
    return this.http.get(this.baseUrlService.baseUrl + svgUrl, {
      responseType: 'text',
    });
  }
}
