import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
declare let navigator: any;
@Injectable({
  providedIn: 'root',
})
export class ExceptionHandlingService {
  constructor(private httpClient: HttpClient) {}
  log(message: any) {
    if (!environment.production) {
      console.error('Exception:', message);
    } else {
      const error = this.buildPayload(message);
      this.httpClient.post('error/', { error }).subscribe();
    }
  }
  private buildPayload(message) {
    const url = new URL(document.location.href);
    return {
      message: message?.message ?? 'NA',
      stack: message?.stack ?? 'NA',
      pdfViewerEnabled: navigator?.pdfViewerEnabled ?? 'NA',
      networkStrength: navigator?.connection?.effectiveType ?? 'NA',
      url,
    };
  }
}
