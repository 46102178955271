import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { catchError, from, map, of } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { defaultTheme, Theme, ThemeService } from '../services/theme.service';
import { TranslocoService } from '@ngneat/transloco';
import { AppStorage } from '../utils/storage-constants';

export const tenantGuard: CanActivateFn = (route) => {
  const storageService = inject(StorageService);
  const themeService = inject(ThemeService);
  const translocoService = inject(TranslocoService);
  const router = inject(Router);
  const tenantId = route.paramMap.get('tenantId');
  const authorizationFromStorage = storageService.retrieve(
    AppStorage.Authorization,
  );

  if (authorizationFromStorage) {
    router.navigate(['/dashboard']);
    return of(false);
  }

  if (tenantId) {
    storageService.store(AppStorage.tenantId, tenantId);
    return from(themeService.getTheme(tenantId)).pipe(
      map((theme: Theme) => {
        themeService.setTheme({
          ...theme,
        });
        themeService.loadTheme();
        translocoService.setActiveLang(
          localStorage?.getItem(AppStorage.language) ||
            theme?.locale?.defaultLanguage ||
            'engb',
        );
        return true;
      }),
      catchError((error) => {
        console.error('Error fetching theme:', error);
        router.navigate(['/login']);
        return of(false);
      }),
    );
  } else {
    storageService.remove('tenantId');
    themeService.setTheme(defaultTheme);
    themeService.loadTheme();
    translocoService.setActiveLang(
      localStorage?.getItem(AppStorage.language) ||
        defaultTheme?.locale?.defaultLanguage ||
        'engb',
    );
    return of(true);
  }
};
