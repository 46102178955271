import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PasswordSetupService } from 'src/app/services/password-setup.service';

@Component({
  selector: 'app-gpsnr-password-setup',
  templateUrl: './gpsnr-password-setup.component.html',
  styleUrls: ['./gpsnr-password-setup.component.scss'],
})
export class GpsnrPasswordSetupComponent implements OnInit {
  username = '';
  uid = '';
  token = '';
  isLinkExpired = false;
  isPasswordReset = false;
  // flag to indicate if form is being used to reset expired password
  passwordExpired = false;
  infoMessage = '';

  constructor(
    private route: ActivatedRoute,
    private passwordSetupService: PasswordSetupService,
  ) {}

  ngOnInit(): void {
    this.uid = this.route.snapshot.params.uid;
    this.token = this.route.snapshot.params.token;
    if (this.route.snapshot.queryParams.passwordExpired) {
      this.passwordExpired = this.route.snapshot.queryParams.passwordExpired;
    }
    this.infoMessage = this.passwordExpired
      ? 'Your password has expired'
      : 'Reset/Forgot password';
    this.getUserData();
  }
  getUserData() {
    this.passwordSetupService
      .getUserData(this.uid, this.token)
      .pipe(
        catchError((error) => {
          this.isLinkExpired = true;
          return throwError(() => error);
        }),
      )
      .subscribe((response) => {
        if (
          typeof response === 'object' &&
          typeof response['email'] !== 'undefined'
        ) {
          this.username = response['email'];
        } else {
          this.isLinkExpired = true;
        }
      });
  }
  passwordResetSuccess() {
    this.isPasswordReset = true;
  }
}
