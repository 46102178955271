import { MessageDialogComponent } from './../modules/exception-handling/message/message-dialog.component';
// MODULES DEPENDENCIES
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  CommonModule,
  DatePipe,
  DecimalPipe,
  SlicePipe,
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { CoreModule } from '../core/core.module';
// WK: TO BE MOVED TO A CHART MODULE
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
// WK: KNOWNED SHARED COMPONENTS
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { InputComponent } from './input/input.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { NavigationModalComponent } from './modal/navigation-modal/navigation-modal.component';
import { PaginatorDirective } from './pagination/pagination.directive';
// WK: MAY NEED TO REFACTOR
import { ModalComponent } from './modal/modal.component';
// RS; Should be kept in shared module or to profile module
import { RouterModule } from '@angular/router';
import { NgxIntlTelInputModule } from '@justin-s/ngx-intl-tel-input';
import { TranslocoModule } from '@ngneat/transloco';
import { ArpLightweightChartDirective } from '../directives/arp-lightweight-chart/arp-lightweight-chart.directive';
import { ImageAbsoluteUrlPipe } from '../pipes/image-absolute-url.pipe';
import { NoStringDataPipe } from '../pipes/no-string-data.pipe';
import { OpenOfferBidPipe } from '../pipes/open-offer-bid.pipe';
import { PricePipe } from '../pipes/price.pipe';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { SafeUrlPipe } from '../pipes/safe-url.pipe';
import { TradeTimePipe } from '../pipes/trade-time.pipe';
import { ActionControlPanelComponent } from './action-control-panel/action-control-panel.component';
import { ArpDataTableComponent } from './arp-data-table/arp-data-table.component';
import { ArpFileUploadPreviewComponent } from './arp-file-upload-preview/arp-file-upload-preview.component';
import { ArpFilterPanelComponent } from './arp-filter-panel/arp-filter-panel.component';
import { ArpLanguagePickerComponent } from './arp-language-picker/arp-language-picker.component';
import { ArpLoaderComponent } from './arp-loader/arp-loader.component';
import { BaseFilterComponent } from './base-filter/base-filter.component';
import { ChipLabelsComponent } from './chip-labels/chip-labels.component';
import { GPSNRFormComponent } from './gpsnr-form/gpsnr-form.component';
import { SingleTextModalComponent } from './modal/single-text-modal/single-text-modal.component';
import { TouViewerComponent } from './modal/tou-viewer/tou-viewer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { PriceTimeChartComponent } from './price-time-chart/price-time-chart.component';
import { SiFilterComponent } from './si-filter/si-filter.component';
import { SiListComponent } from './si-list/si-list.component';
import { StepControlPanelComponent } from './step-control-panel/step-control-panel.component';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faLinkedin,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import { ArpDividerComponent } from './arp-divider/arp-divider.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    NgChartsModule,
    ScrollingModule,
    TranslocoModule,
    RouterModule,
    FontAwesomeModule,
  ],
  declarations: [
    SafeUrlPipe,
    SafeHtmlPipe,
    BarChartComponent,
    DoughnutChartComponent,
    PriceTimeChartComponent,
    BreadcrumbComponent,
    PaginatorDirective,
    InputComponent,
    MainMenuComponent,
    ModalComponent,
    NavigationModalComponent,
    MessageDialogComponent,
    NoStringDataPipe,
    PricePipe,
    ImageAbsoluteUrlPipe,
    OpenOfferBidPipe,
    NoStringDataPipe,
    SingleTextModalComponent,
    TouViewerComponent,
    TradeTimePipe,
    PhoneInputComponent,
    SiListComponent,
    SiFilterComponent,
    GPSNRFormComponent,
    BaseFilterComponent,
    ActionControlPanelComponent,
    NotFoundComponent,
    ArpLanguagePickerComponent,
    ArpFilterPanelComponent,
    ArpDataTableComponent,
    ArpFileUploadPreviewComponent,
    StepControlPanelComponent,
    ArpLightweightChartDirective,
    ChipLabelsComponent,
    ArpLoaderComponent,
    ArpDividerComponent,
  ],
  exports: [
    FontAwesomeModule,
    SafeUrlPipe,
    SafeHtmlPipe,
    ImageAbsoluteUrlPipe,
    BarChartComponent,
    DoughnutChartComponent,
    PriceTimeChartComponent,
    BreadcrumbComponent,
    PaginatorDirective,
    InputComponent,
    MainMenuComponent,
    ModalComponent,
    NavigationModalComponent,
    MessageDialogComponent,
    OpenOfferBidPipe,
    NoStringDataPipe,
    SingleTextModalComponent,
    TouViewerComponent,
    PricePipe,
    ImageAbsoluteUrlPipe,
    TradeTimePipe,
    PhoneInputComponent,
    SiListComponent,
    SiFilterComponent,
    BaseFilterComponent,
    ActionControlPanelComponent,
    ArpLanguagePickerComponent,
    ArpFilterPanelComponent,
    ArpDataTableComponent,
    ArpFileUploadPreviewComponent,
    StepControlPanelComponent,
    ArpLightweightChartDirective,
    ChipLabelsComponent,
    ArpLoaderComponent,
    ArpDividerComponent,
  ],
  providers: [DecimalPipe, DatePipe, SafeUrlPipe, SlicePipe],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faLinkedin, faYoutubeSquare);
  }
}
