import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { GPSNRForm } from '../models/api/responses/user-info';
import { OpenTabsService } from '../services/open-tabs.service';
import { StorageService } from '../services/storage.service';
import { ToastService } from '../services/toast.service';
import { checkFormBaseURL } from '../utils/checkFormBaseURL';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private storage: StorageService,
    private tabService: OpenTabsService,
    private router: Router,
    private toast: ToastService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {}

  /**
   * Check if protected route can be activate
   * @returns true if storage contains Authorization, else false and redirect to '/'
   */

  canActivate(): boolean | UrlTree {
    this.tabService.addTab();
    // if is hc user, can proceed, else force back to login as form user can only access the form via form domain
    const gpsnrForm: GPSNRForm[] =
      this.storage.retrieve<GPSNRForm[]>('gpsnrForm') ?? [];
    const formUserOnly = gpsnrForm.find((item) => item.form_user_only) ?? false;

    if (checkFormBaseURL(location.href) && formUserOnly) {
      this.toast.negativeToast('You are not allowed to login to the platform');
      this.storage.clear();
      return this.router.parseUrl('/login');
    }

    if (
      this.storage.check('Authorization') &&
      this.storage.retrieve<boolean>('TermsOfUse')
    ) {
      return true;
    }
    this.storage.clear();
    // If the platform is server (SSR), ignore the login route and use empty route
    return this.isBrowser()
      ? this.router.parseUrl('/login')
      : this.router.parseUrl('/empty');
  }

  isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
