import { HttpErrorResponse } from '@angular/common/http';
import { Message } from '../message';

export class DefaultMessage extends Message {
  getMessage(content: string | HttpErrorResponse): Message {
    this.icon = 'error';
    this.color = 'warn';
    this.content = this.processMessage(content);
    this.enableCustomException = true;
    return this;
  }
  private processMessage(error): string {
    let message = '';
    if (error.error.error === 'ValidationError') {
      message = this.unpackErrorMessage(error.error.message);
    } else if (error.error.constructor === Object) {
      message = this.unpackErrorMessage(error.error);
    }
    console.error(message);
    return message;
  }
  close(): void {
    // Close method
  }

  action(): void {
    // Action method
  }
}
