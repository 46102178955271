import { Injectable } from '@angular/core';
import Format from '@date-format';
import { format, parse, sub } from 'date-fns';
import { CandlestickData, ChartOptions, ISeriesApi } from 'lightweight-charts';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  filter,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { ArpLightweightChartDirective } from '../directives/arp-lightweight-chart/arp-lightweight-chart.directive';
import { ButtonToggleGroupModel } from '../models/form-field-models';
import { Instrument } from '../models/sicom-latest-price';
import {
  ChartRange,
  ChartRefs,
  createTooltipElement,
  fxTooltipHandler,
} from '../utils/lightweight-chart-utils';
import { isObjectValuesTruthy } from '../utils/object';
import { SixStreamService } from './six-stream.service';

/**
 * Interface for the chart references
 * specific to the Fx Display Component
 */
interface FxChartRefs extends ChartRefs {
  candleStickSeries: ISeriesApi<'Candlestick'>;
}

@Injectable()
export class FxDisplayLogicService {
  /**
   * Subject for terminating component's subscription
   */
  private destroy$ = new Subject<boolean>();
  /**
   * Subject for storing/updating reference to the latest IChartApi
   * candlestickSeries, histogramSeries objects retrieved from View
   * Query in the component
   */
  private chartRefs$ = new ReplaySubject<FxChartRefs>(1);
  /**
   * Subject for emitting loading state of the chart
   */
  private chartLoadingSubject = new BehaviorSubject<boolean>(true);
  /**
   * Retrieve the fxInstrumentMap$ stream from the SixStreamService
   */
  private fxInstrumentMap$ = this.sixStreamService.fxInstrumentMap$;
  /**
   * Subject for emitting selected currency pair
   */
  private selectedCurrencyPair$ = new ReplaySubject<string>(1);
  /**
   * Subject for emitting selected graph range
   */
  private selectedRange$ = new BehaviorSubject<ChartRange>('Year');
  /**
   * Compute the fxChartData for selected currency pair and range:
   * 1. Combine the selectedCurrencyPair$, selectedRange$, and fxInstrumentMap$ streams
   * 2. Map the combined stream to an object containing the instrumentCode and range
   * 3. Tap to emit chartLoadingSubject with true
   * 4. SwitchMap to queryInstrumentData with the instrumentCode and range
   * 5. Map the instrumentData to an object containing the candlestick data
   * 6. Tap to emit chartLoadingSubject with false
   */
  private fxChartData$ = combineLatest(
    [this.selectedCurrencyPair$, this.selectedRange$, this.fxInstrumentMap$],
    (selectedCurrencyPair, selectedRange, fxInstrumentMap) => {
      return {
        instrumentCode: fxInstrumentMap[selectedCurrencyPair],
        range: selectedRange,
      };
    },
  ).pipe(
    tap((_) => this.chartLoadingSubject.next(true)),
    switchMap(({ instrumentCode, range }) =>
      this.queryInstrumentData(instrumentCode, range),
    ),
    map((instrumentData) => {
      return {
        candleStick: this.mapInstrumentToCandlestick(instrumentData),
      };
    }),
    tap((_) => this.chartLoadingSubject.next(false)),
  );
  /**
   * The chart renderer engine.
   * 1. Combine the fxChartData$ and chartRefs$ streams
   * 2. Stream is only active until the component is destroyed
   * 3. Filter out the stream if the chartRefs$ is not available
   * 4. Imperatively set the series data
   */
  private chartRenderer$ = combineLatest([
    this.fxChartData$,
    this.chartRefs$,
  ]).pipe(
    takeUntil(this.destroy$),
    filter(([, chartRefs]) => isObjectValuesTruthy(chartRefs)),
    tap(([fxChartData, chartRefs]) => {
      const { candleStick } = fxChartData;
      const { candleStickSeries } = chartRefs;
      candleStickSeries.setData(candleStick);
      chartRefs.chart.timeScale().fitContent();
    }),
  );
  /**
   * Retrieve the sixStreamStatus$ stream from the SixStreamService
   */
  get sixStreamStatus$() {
    return this.sixStreamService.sixStreamStatus$;
  }
  /**
   * Retrieve the lastUpdatedTime stream from the SixStreamService, formatted
   * in dd/MM/yyyy | hh:mm:ss a
   */
  get lastUpdatedTimeString$() {
    return this.sixStreamService.lastUpdatedTime$.pipe(
      map((time) => format(time, 'dd/MM/yyyy | hh:mm:ss a')),
    );
  }
  /**
   * Expose the chartLoadingSubject as a read-only observable
   */
  get chartLoading$() {
    return this.chartLoadingSubject.asObservable();
  }
  /**
   * Retrieve the fx$ stream from the SixStreamService
   */
  get fx$() {
    return this.sixStreamService.fx$;
  }
  /**
   * Retrieve the currencyPairSelectField$ stream from the SixStreamService
   */
  get currencyPairSelectField$() {
    return this.sixStreamService.currencyPairSelectField$;
  }
  /**
   * The range toggle field stream for Sicom Display Component
   */
  get rangeToggleField$() {
    return of({
      appearance: 'standard',
      selectedOptions: 'Year',
      options: ['Week', 'Month', 'Year'],
      name: 'range',
      for: 'range',
    } as ButtonToggleGroupModel<ChartRange>);
  }
  /**
   * Compute the selected fx price by combining
   * the selectedCurrencyPair$, and fx$ streams
   */
  get selectedFx$() {
    return combineLatest(
      [this.selectedCurrencyPair$, this.fx$],
      (selectedCurrencyPair, fxStream) => {
        return fxStream[selectedCurrencyPair] as number;
      },
    );
  }

  constructor(private sixStreamService: SixStreamService) {}

  /**
   * Initialize the states of the service
   */
  initializeStates() {
    this.currencyPairSelectField$
      .pipe(
        take(1),
        tap((currencyPairSelectField) =>
          this.selectCurrencyPair(
            currencyPairSelectField.selectedOptions as string,
          ),
        ),
      )
      .subscribe();
    this.chartRenderer$.subscribe();
  }
  /**
   * Clean up internal subscriptions
   * by emitting a value to the destroy$ subject
   */
  cleanUp() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  /**
   * Update the selected currency pair
   * @param currencyPair currency pair string
   */
  selectCurrencyPair(currencyPair: string) {
    this.selectedCurrencyPair$.next(currencyPair);
  }
  /**
   * Update the selected range
   * @param range range string
   */
  selectRange(range: ChartRange) {
    this.selectedRange$.next(range);
  }
  /**
   * Update the chartRefs
   * @param chartDirective the chart directive retrieved from template reference
   */
  updateChartRefs(chartDirective: ArpLightweightChartDirective) {
    const chartRefs = this.createChartRefs(chartDirective);
    this.chartRefs$.next(chartRefs);
  }
  /**
   * Map the Fx Instrument to Candlestick Data for charting FX
   * @param fxInstrument the Fx Instrument object
   * @returns array of Candlestick Data
   */
  mapInstrumentToCandlestick(fxInstrument: Instrument): CandlestickData[] {
    return fxInstrument.Values.map((value) => ({
      time: format(
        parse(value.Date, 'yyyyMMdd', new Date()),
        Format.apiDateFormat,
      ),
      open: parseFloat(value.Data.open_price),
      close: parseFloat(value.Data.most_recent_settlement_price),
      high: parseFloat(value.Data.high_price),
      low: parseFloat(value.Data.low_price),
    }));
  }
  /**
   * Query the instrument data for selected instrument code,
   * default to 7 days of data for now
   * @param instrumentCode the instrument code of the sicom commodity
   * @param range the range of the query
   * @returns an observable wrapping the Instrument Data
   */
  queryInstrumentData(instrumentCode: string, range: ChartRange) {
    const today = new Date();
    const dateto = format(today, Format.apiDateFormat);
    const datefrom = format(
      sub(today, { [`${range.toLowerCase()}s`]: 1 }),
      Format.apiDateFormat,
    );
    return this.sixStreamService.queryInstrumentData(
      instrumentCode,
      datefrom,
      dateto,
    );
  }
  /**
   * 1. Create the chart object.
   * 2. Add the candlestick series to the chart,
   * and apply necessary options.
   * 3. Return the chart container, chart object and the series objects.
   * @param chartDirective the chart directive retrieved from template reference
   * @returns the chart container, chart object and the series objects
   */
  createChartRefs(chartDirective: ArpLightweightChartDirective) {
    const chart = chartDirective.createChart({
      autoSize: true,
      timeScale: {
        fixLeftEdge: true,
        fixRightEdge: true,
        ticksVisible: true,
      },
      rightPriceScale: {
        ticksVisible: true,
      },
    } as ChartOptions);
    const candleStickSeries = chart.addCandlestickSeries();
    candleStickSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.1,
        bottom: 0.325,
      },
    });
    const chartContainer = chartDirective.getChartContainer();
    const toolTip = createTooltipElement();
    chartContainer.appendChild(toolTip);
    chart.subscribeCrosshairMove((param) =>
      fxTooltipHandler(param, toolTip, chartContainer, candleStickSeries),
    );
    return { chartContainer, chart, candleStickSeries };
  }
}
