<div class="chat-download" *ngIf="showDownloadChatBox">
  <div class="chat-download-header">
    <div class="subject">{{ "COMMON.DOWNLOAD_CHAT" | transloco }}</div>
    <div class="chat-download-header-action">
      <div class="selected">
        {{ numSelected }} {{ "COMMON.SELECTED" | transloco }}
      </div>
      <div
        class="action-icon"
        (click)="close()"
        (keyup.enter)="close()"
        tabindex="0">
        <img src="/assets/images/close.svg" alt="" />
      </div>
    </div>
  </div>
  <hr />
  <div class="search-bar">
    <input
      [(ngModel)]="search"
      (ngModelChange)="searchList()"
      type="search"
      class="hc-search"
      [placeholder]="'COMMON.SEARCH_CHAT' | transloco" />
  </div>
  <div class="grouping" #roomList (scroll)="onRoomScroll($event)">
    <div
      class="drop-box drop-box--chat-timeline"
      tabindex="-1"
      (focusout)="closeTimeline($event)"
      #timeline
      *ngIf="openTimeline"
      [ngStyle]="timelineStyle">
      <app-chat-timeline-calendar
        (onapply)="changeTimeline($event)"
        [timeline]="currentTimeline"></app-chat-timeline-calendar>
    </div>
    <div class="grouping-header">
      <div
        class="header-chat-title"
        (click)="chatGroupExpansion = !chatGroupExpansion"
        (keyup.enter)="chatGroupExpansion = !chatGroupExpansion"
        tabindex="0">
        <div *ngIf="chatGroupExpansion" class="expand-icon"></div>
        <div *ngIf="!chatGroupExpansion" class="close-right-icon"></div>
        <div class="second-label">{{ "COMMON.GROUP_CHAT" | transloco }}</div>
      </div>
    </div>
    <ng-container *ngIf="chatGroupExpansion">
      <div class="room" *ngFor="let room of listGroupSearched">
        <mat-checkbox
          (change)="selectedRoom(room)"
          [checked]="selected[room.id]"
          [value]="selected[room.id]"
          >{{ room.name }}</mat-checkbox
        >
        <div class="time-selected" *ngIf="!!selected[room.id]">
          <div class="time-label">{{ selected[room.id].timeline }}</div>
          <em
            (click)="openChatTimeLine($event, selected[room.id])"
            (keyup.enter)="openChatTimeLine($event, selected[room.id])"
            tabindex="0"
            class="icon-calender">
            <!-- TODO: <div class="svg-icon icon-calender" *ngIf="svgContents['calendar']" [innerHTML]="svgContents['calendar'] | safeHtml"></div>    -->
          </em>
        </div>
      </div>
    </ng-container>
    <div class="grouping-header">
      <div
        class="header-chat-title"
        (click)="chatUserExpansion = !chatUserExpansion"
        (keyup.enter)="chatUserExpansion = !chatUserExpansion"
        tabindex="0">
        <div *ngIf="chatUserExpansion" class="expand-icon"></div>
        <div *ngIf="!chatUserExpansion" class="close-right-icon"></div>
        <div class="second-label">
          {{ "COMMON.INDIVIDUAL_CHAT" | transloco }}
        </div>
      </div>
    </div>
    <ng-container *ngIf="chatUserExpansion">
      <div class="room" *ngFor="let room of listUserSearched">
        <mat-checkbox
          (change)="selectedRoom(room)"
          [checked]="selected[room.id]"
          [value]="selected[room.id]"
          >{{ room.name }}</mat-checkbox
        >
        <div class="time-selected" *ngIf="!!selected[room.id]">
          <div class="time-label">{{ selected[room.id].timeline }}</div>
          <em
            (click)="openChatTimeLine($event, selected[room.id])"
            tabindex="0"
            (keyup.enter)="openChatTimeLine($event, selected[room.id])"
            class="icon-calender">
            <!-- TODO: <div class="svg-icon icon-calender" *ngIf="svgContents['calendar']" [innerHTML]="svgContents['calendar'] | safeHtml"></div>    -->
          </em>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="download-action">
    <button
      mat-button
      [disabled]="disableDownload || !numSelected"
      (click)="downloadChatAction()"
      class="download-btn">
      {{
        (disableDownload ? "COMMON.LOADING" : "COMMON.DOWNLOAD_CHAT")
          | transloco
      }}
    </button>
  </div>
</div>
