import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AvailableStatus } from 'src/app/models/chatroom';
import { ProfileName } from 'src/app/models/profile-name';
import { Settings } from 'src/app/models/settings';
import { ChatService } from 'src/app/services/chat.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-chat-settings',
  templateUrl: './chat-settings.component.html',
  styleUrls: ['./chat-settings.component.scss'],
})
export class ChatSettingsComponent implements OnInit {
  @ViewChild('chatavailable', { static: false }) set chatavailableElement(
    content: ElementRef,
  ) {
    if (content?.nativeElement) {
      content.nativeElement.focus();
    }
  }

  activeDownloadChat = false;
  openAvailable = false;
  enableSounds: boolean;
  profileName: string;
  companyName: string;
  chatStatusList: AvailableStatus[];
  chatStatus: AvailableStatus;
  constructor(
    private storage: StorageService,
    private chatService: ChatService,
  ) {}

  ngOnInit(): void {
    this.setProfileData();
    this.setDownloadChat();
    this.setChatStatus();
    this.userSetting();
  }

  setChatStatus() {
    this.chatStatusList = this.chatService.chatStatusList;
    this.chatService.chatStatus.subscribe(
      (status) => (this.chatStatus = status),
    );
  }

  setProfileData() {
    const profile = this.storage.retrieve<ProfileName>('profileName');
    this.profileName = profile?.name;
    this.companyName = profile?.companyName;
  }

  setDownloadChat() {
    this.chatService.downloadChat.subscribe((res) => {
      this.activeDownloadChat = typeof res !== 'undefined';
    });
  }

  downloadChat() {
    if (typeof this.chatService.downloadChat.value === 'undefined') {
      this.chatService.downloadChat.next({});
    } else {
      this.chatService.downloadChat.next(undefined);
    }
  }

  onSettingScroll() {
    this.closeAvailable();
  }

  closeAvailable() {
    this.openAvailable = false;
  }

  openAvailablePopup() {
    this.openAvailable = true;
  }

  changeAvailable(status: AvailableStatus) {
    this.chatService.chatStatus.next(status);
  }

  userSetting() {
    this.chatService.userSetting.subscribe((setting: Settings) => {
      this.enableSounds = setting?.enable_sounds;
    });
  }

  toggleSoundSetting() {
    this.chatService.toggleSoundSetting();
  }
}
