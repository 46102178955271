import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Message } from '../message';

export class BadRequestMessage extends Message {
  icon: string;
  color: string;
  content: string;
  message: Message = {} as Message;

  getMessage(content: string | HttpErrorResponse): Message {
    this.icon = 'error';
    this.color = 'info';
    this.type = HttpStatusCode.BadRequest;
    this.content = this.processMessage(content);
    return this;
  }

  private processMessage(error): string {
    let message = '';
    // Handle Http Error (error.status ===  400)
    if (typeof error === 'string') {
      message = error;
    } else if (error.error.error === 'ValidationError') {
      message = this.unpackErrorMessage(error.error.message);
    } else if (error.error.constructor === Object) {
      // Case the error message is an object
      message = this.unpackErrorMessage(error.error);
    } else if (error.error.constructor === Array) {
      message = this.unpackErrorMessage(error.error);
    } else {
      message = `${error.status} - ${error.message}`;
    }
    return message;
  }

  close(): void {
    // Close method
  }

  action(): void {
    // Action method
  }
}
