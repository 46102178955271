<div class="container">
  <div class="header">
    <div class="d-flex justify-content-space-between align-items-center">
      <img
        [src]="logoUrl === '' ? './../../../assets/images/logo.svg' : logoUrl"
        alt="" />
      <a href="/" class="btn btn-pill btn-primary">{{
        "PASSWORD_SETUP.GOTO_AGRIDENCE_PLATFORM" | transloco
      }}</a>
    </div>
    <hr />
  </div>
  <div class="" style="display: flex">
    <div style="padding-top: 5%">
      <img
        src="./../../../assets/images/reset-password.svg"
        class="p-4 w-80 mx-auto"
        alt="" />
    </div>
    <div>
      <div *ngIf="!isLinkExpired && !isPasswordReset; else redirectLink">
        <div>
          <h2 class="mb-4 mt-0">{{ infoMessage }}</h2>
          <p *ngIf="username !== ''; else noUsername">
            {{ "PASSWORD_SETUP.PLEASE_SET_PASSWORD" | transloco }}:
            <strong class="username text-black font-weight-bold">{{
              username
            }}</strong>
          </p>
          <ng-template #noUsername>
            {{ "PASSWORD_SETUP.PLEASE_WAIT" | transloco }}
          </ng-template>
        </div>
        <div *ngIf="username !== ''">
          <app-password-setup-form
            [token]="token"
            [uid]="uid"
            (passwordReset)="passwordResetSuccess()"></app-password-setup-form>
        </div>
      </div>
      <ng-template #redirectLink>
        <div class="redirectLink">
          <h2 class="mb-4 mt-0" *ngIf="isLinkExpired">
            {{ "PASSWORD_SETUP.LINKED_EXPIRED" | transloco }}
          </h2>
          <p *ngIf="isLinkExpired">
            {{ "PASSWORD_SETUP.LINKED_ALREADY_EXPIRED_OR_INVALID" | transloco }}
          </p>
          <h2 class="mb-4 mt-0" *ngIf="isPasswordReset">
            {{ "PASSWORD_SETUP.PASSWORD_CHANGED_SUCCESSFULLY" | transloco }}
          </h2>
          <a *ngIf="isPasswordReset" href="/">{{
            "PASSWORD_SETUP.GO_BACK_TO_LOGIN" | transloco
          }}</a>
        </div>
      </ng-template>
    </div>
  </div>
</div>
