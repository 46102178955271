<mat-card class="mat-elevation-z0">
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button mat-button id="hc-button-basic" (click)="backEvent.emit()">
      Back
    </button>
    <button mat-button id="hc-button-basic" (click)="saveEvent.emit()">
      Save
    </button>
    <button
      mat-button
      id="hc-button-primary"
      style="padding: 1px 40px"
      [disabled]="nextButtonDisabled"
      (click)="nextEvent.emit()">
      {{ nextButtonText }}
    </button>
  </mat-card-actions>
</mat-card>
