import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA,
  MatLegacyDialogRef,
} from '@angular/material/legacy-dialog';
import { ModalData } from 'src/app/models/modal-data';

@Component({
  selector: 'app-backup-code-modal',
  templateUrl: './backup-code-modal.component.html',
  styleUrls: ['./backup-code-modal.component.scss'],
})
export class BackupCodeModalComponent implements OnInit {
  modalData: ModalData;
  backupCodeValue: string;
  errorBackupCodeStatus: boolean;
  errorMessage: string;
  constructor(
    private backupCodeModalRef: MatLegacyDialogRef<BackupCodeModalComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) data: ModalData,
  ) {
    this.modalData = data;
  }

  ngOnInit(): void {
    this.errorBackupCodeStatus = !!this.modalData.state?.showError;
    if (this.errorBackupCodeStatus) {
      this.errorMessage = 'Backup code is invalid. Please try again.';
    }
  }

  backupCodeFill(event: Event) {
    if (event) {
      this.backupCodeValue = (event.target as HTMLInputElement).value;
    }

    this.errorBackupCodeStatus = !this.backupCodeValue;
    if (this.errorBackupCodeStatus) {
      this.errorMessage = 'Please enter your backup code.';
    }
  }

  onCloseClick() {
    this.backupCodeModalRef.close();
  }

  onSendClick() {
    this.backupCodeModalRef.close({ backupCode: this.backupCodeValue });
  }
}
