<h3>{{ dataSource?.chartTitle }}</h3>
<div>
  <canvas
    baseChart
    *ngIf="dataSource"
    [datasets]="dataSource?.dataset"
    [labels]="dataSource?.label"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [type]="barChartType">
  </canvas>
</div>
