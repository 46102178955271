import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import {
  debounceTime,
  distinct,
  filter,
  finalize,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NotificationManagerService } from '../notification-manager.service';

const DEFAULT_PAGE_SIZE = 10;

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit, OnDestroy {
  /**
   * Whether to retrieve list for ALL or UNREAD notifications
   */
  @Input() isUnreadOnly = false;
  /**
   * To get reference to CdkVirtualScrollViewport
   */
  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;
  /**
   * Whether there are more notifications to be retrieved
   */
  hasNextPage = false;
  /**
   * Keep track of the current page of notifications
   */
  currentPage = 1;
  /**
   * Show or hide the loading animation in the
   * notification list
   */
  isLoading = true;
  /**
   * A subject used to emit the current page of notifications
   * to retrieve from Backend. The emission is controlled by
   * the viewport's (scrolledIndexChange) event
   */
  offset$ = new BehaviorSubject(1);
  /**
   * Retrieve the infinite list of notifications from the
   * notification manager service.
   */
  infiniteList$ = this.notificationManagerService.notificationList$;

  constructor(private notificationManagerService: NotificationManagerService) {}

  /**
   * Init the component through the handleInfiniteList method
   */
  ngOnInit() {
    this.handleInfiniteList();
  }

  /**
   * Empty notification list whenever component is destroyed
   * and also terminate the offset$ subscription
   */
  ngOnDestroy() {
    // Switching between All and Unread needs empty list
    this.notificationManagerService.reset();
    this.offset$.unsubscribe();
  }

  /**
   * Subscribe to the offset$ subject to trigger API call
   * for paginated notification list from the
   * notification manager service.
   */
  handleInfiniteList() {
    this.offset$
      .pipe(
        filter((page) => !!page),
        debounceTime(500),
        distinct((page) => page),
        mergeMap((page) => this.getNotificationList(page)),
      )
      .subscribe();
  }

  /**
   * Construct query param from given page number
   * and isUnreadOnly flag, then request
   * Notification Manager Service to retrieve the
   * list of notifications
   * @param page the page of notifications to retrieve
   * @returns an observable of the notification list
   */
  getNotificationList(page = 1) {
    const query = {
      page,
      page_size: DEFAULT_PAGE_SIZE,
    };

    if (this.isUnreadOnly) {
      query['read_at_isnull'] = true;
    }

    return this.notificationManagerService.getNotificationList(query).pipe(
      tap((data) => {
        this.isLoading = true;
        this.hasNextPage = data.next !== null;
        this.currentPage = data.page;
      }),
      finalize(() => (this.isLoading = false)),
    );
  }

  /**
   * Mark a notification as read
   * @param notification the notification clicked on
   */
  readNotification(notification) {
    this.notificationManagerService.readNotification(notification);
  }

  /**
   * Used to control the emission of the offset$ subject
   */
  nextPage() {
    if (!this.hasNextPage) return;

    const end = this.viewport.getRenderedRange().end;
    const total = this.viewport.getDataLength();

    if (end === total) {
      this.offset$.next(this.currentPage + 1);
    }
  }

  /**
   * Used trackBy to improve rendering performance
   * of the infinite list
   * @param i index of the notification in the list
   * @returns the unique index of the notification
   */
  trackByIdx(i) {
    return i;
  }
}
