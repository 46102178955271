import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'src/app/core/core.module';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [TopMenuComponent],
  imports: [CommonModule, CoreModule, TranslocoModule],
  exports: [TopMenuComponent],
})
export class TopMenuModule {}
