import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { NotificationManagerService } from '../notification-manager.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-notification-dropdown',
  templateUrl: './notification-dropdown.component.html',
  styleUrls: ['./notification-dropdown.component.scss'],
})
export class NotificationDropdownComponent implements OnInit, OnDestroy {
  /**
   * To terminate all subscriptions
   * on component destroy
   */
  destroy$ = new Subject<boolean>();
  /**
   * HostListener to close the dropdown when user clicks
   * outside of the notification button and the dropdown
   * @param eventTarget
   */
  @HostListener('document:click', ['$event.target'])
  clickedOut(eventTarget: HTMLElement) {
    if (!this.elRef.nativeElement.contains(eventTarget)) {
      this.active = false;
    }
  }
  /**
   * Control the visibility of the notification badge
   */
  hideNotificationBadge = true;
  /**
   * The badge that shows the number of unacknowledged
   * notifications
   */
  unacknowledgedBadge = '0';
  /**
   * Whether the notification list dropdown is displayed
   */
  active = false;
  /**
   * The index of the current tab (All or Unread) in
   * the notification list dropdown
   */
  selectedIndex = 0;

  constructor(
    private elRef: ElementRef,
    private notificationManagerService: NotificationManagerService,
  ) {}

  /**
   * Initialize the notification manager service (registering websocket
   * and such), and subscribe to unread notifications count
   */
  ngOnInit() {
    this.notificationManagerService.initialize();
    // Keep tracks of unread notifications
    this.notificationManagerService.unreadNotificationsCount$
      .pipe(
        takeUntil(this.destroy$),
        tap((count) => {
          this.hideNotificationBadge = count === 0;
        }),
        map((count) => (count < 10 ? count : '9+') as string),
        tap((count) => {
          this.unacknowledgedBadge = count;
        }),
      )
      .subscribe();
  }

  /**
   * Terminate all subscriptions
   */
  ngOnDestroy(): void {
    this.notificationManagerService.cleanUp();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * When user clicks on the notification button,
   * trigger an API to mark all notifications as
   * acknowledged ONLY IF the dropdown is not displayed.
   * Toggle the active state to show/hide the dropdown
   */
  markAllAsAcknowledged() {
    if (!this.active) {
      this.notificationManagerService
        .markAllAsAcknowledged()
        .pipe(
          tap((_) => {
            this.hideNotificationBadge = true;
          }),
        )
        .subscribe();
    }
    this.active = !this.active;
  }

  /**
   * When user clicks on marks all as read,
   * trigger an API to mark all notifications as read,
   * then does a reload of the notification list
   */
  markAllAsRead() {
    this.notificationManagerService
      .markAllAsRead()
      .pipe(
        mergeMap(() => {
          const query = {
            page: 1,
            page_size: 10,
          };
          if (this.selectedIndex === 1) {
            query['read_at_isnull'] = true;
          }
          return this.notificationManagerService.getNotificationList(query);
        }),
      )
      .subscribe();
  }
}
