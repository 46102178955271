import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { MAT_LEGACY_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/legacy-checkbox';
import { MAT_LEGACY_RADIO_DEFAULT_OPTIONS } from '@angular/material/legacy-radio';
import { MAT_LEGACY_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/legacy-slide-toggle';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { NotifierOptions } from 'angular-notifier';
import { environment } from 'src/environments/environment';
import {
  initializeDomainService,
  preLoadTheme,
  registerCustomIcons,
} from './app-initializer';
import { DefaultLayoutComponent } from './containers';
import { IconService } from './hc-icons/icon.service';
import { BaseUrlInterceptor } from './interceptors/base-url.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { LoadingIconInterceptor } from './interceptors/loading-icon.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { StorageService } from './services/storage.service';
import { ThemeService } from './services/theme.service';
import { TranslocoHttpLoader } from './services/transloco-http-loader.service';
import { DomainService } from './services/domain.service';
import { LANGS } from './constants/lang.const';

/**
 * The default layout component for the application
 */
export const APP_CONTAINERS = [DefaultLayoutComponent];
/**
 * List of interceptors to be used in the application
 * Interceptors are applied by their order in this array.
 * Consider to replace these by Functional Interceptors
 */
export const DI_INTERCEPTORS = [
  { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoadingIconInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
];
/**
 * The default theme pallette for certain Material Modules in the application
 */
export const DEFAULT_THEME_PALLETTE = [
  {
    provide: MAT_LEGACY_SLIDE_TOGGLE_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  },
  {
    provide: MAT_LEGACY_CHECKBOX_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  },
  { provide: MAT_LEGACY_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
];
/**
 * Transloco configuration for Transloco services
 */
export const TRANSLOCO_CONFIG = {
  config: {
    availableLangs: [...LANGS],
    defaultLang: 'engb',
    fallbackLang: 'engb',
    // Remove this option if your application doesn't support changing language in runtime.
    reRenderOnLangChange: true,
    prodMode: environment.production,
  },
  loader: TranslocoHttpLoader,
};
/**
 * Transloco persist language configuration for Transloco services
 */
export const TRANSLOCO_PERSIST_LANG_CONFIG = {
  storage: {
    useValue: localStorage,
  },
};

/**
 * Application initializers to be used in the application
 * 1. Initializer for preloading language json files
 * 2. Initializer for registering custom icons
 */
export const APP_INITIALIZERS: Provider[] = [
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: initializeDomainService,
    deps: [DomainService, TranslocoService],
  },
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: registerCustomIcons,
    deps: [IconService],
  },
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: preLoadTheme,
    deps: [StorageService, ThemeService, TranslocoService, Router],
  },
];
/**
 * Custom angular notifier options
 */
export const NOTIFIER_OPTIONS: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12,
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 3000,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};
