import { Message } from '../message';

export class WarnMessage extends Message {
  getMessage(content: string): Message {
    this.icon = 'warning';
    this.color = 'warn';
    this.content = content;
    return this;
  }

  close(): void {
    // Close method
  }

  action(): void {
    // Action method
  }
}
