<a
  *ngIf="quote"
  class="quotation-link"
  [routerLink]="quotationLink"
  [queryParams]="{ id: quote?.id }">
  <div class="trade-info">
    <div class="header">
      {{ quote?.producer?.name }}-{{ quote?.consumer?.name }}
    </div>

    <div class="infomation">
      <div class="header-text">{{ "COMMON.DATE" | transloco }} :</div>
      <div class="value-text">
        <div>{{ quote?.last_modified_at | date: "dd/MM/yyyy" }}</div>
      </div>
      <div class="header-text">{{ "COMMON.QUOTATION" | transloco }} :</div>
      <div class="value-text">
        <div>{{ quote?.name || "-" }}</div>
      </div>
      <div class="header-text">{{ "COMMON.FACTORY" | transloco }} :</div>
      <div class="value-text">
        <div>{{ quote?.factory?.name || "-" }}</div>
      </div>
      <div class="header-text">{{ "COMMON.PORT" | transloco }} :</div>
      <div class="value-text">
        <div>{{ quote?.port?.name || "-" }}</div>
      </div>
      <div class="header-text">{{ "COMMON.SYMBOL" | transloco }} :</div>
      <div class="value-text">
        <div>{{ quote?.factory?.factory_code || "-" }}</div>
      </div>
      <div class="header-text">{{ "COMMON.GRADE" | transloco }} :</div>
      <div class="value-text">
        <div>{{ quote?.grade?.name || "-" }}</div>
      </div>
      <div class="header-text">{{ "COMMON.PACKING" | transloco }} :</div>
      <div class="value-text">
        <div>{{ quote?.packing?.name || "-" }}</div>
      </div>

      <div class="header-text">{{ "COMMON.INCOTERM" | transloco }} :</div>
      <div class="value-text">
        <div>{{ quote?.shipping?.incoterm || "-" }}</div>
      </div>

      <div class="header-text">{{ "COMMON.PAYMENT_TERM" | transloco }} :</div>
      <div class="value-text">
        <div>{{ quote?.payment_term?.name || "-" }}</div>
      </div>

      <div class="header-text">{{ "COMMON.DESTINATION" | transloco }} :</div>
      <div class="value-text">
        <div>{{ quote?.destination?.name || "-" }}</div>
      </div>

      <div class="header-text">{{ "COMMON.MONTH" | transloco }} :</div>
      <div class="value-text">
        {{ quote?.delivery_date | date: "MMM / yyyy" }}
      </div>
      <hr class="line" />
      <div class="header-price">
        {{ "COMMON.ASK_PRICE" | transloco }} (USC/KG) :
      </div>
      <div class="value-price">
        {{ (quote?.ask_price | price) || "-" }}
      </div>
      <div class="header-price">
        {{ "COMMON.ASK_VOLUME" | transloco }} (mT) :
      </div>
      <div class="value-price">
        {{ (quote?.ask_volume | number: "1.2-2") || "-" }}
      </div>

      <div class="header-price">
        {{ "COMMON.BID_PRICE" | transloco }} (USC/KG) :
      </div>
      <div class="value-price">
        {{ (quote?.bid_price | price) || "-" }}
      </div>
      <div class="header-price">
        {{ "COMMON.BID_VOLUME" | transloco }} (mT) :
      </div>
      <div class="value-price">
        {{ (quote?.bid_volume | number: "1.2-2") || "-" }}
      </div>
    </div>
    <div class="quote_status">{{ quote?.status || "-" }}</div>
  </div>
</a>
<div *ngIf="!quote">{{ chat?.message }}</div>
