import { Component, OnInit, Inject } from '@angular/core';
import {
  MatLegacyDialogRef,
  MAT_LEGACY_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { PasswordResetRequest } from 'src/app/models/api/requests/password-reset';
import { ModalData } from 'src/app/models/modal-data';

@Component({
  selector: 'app-forget-me-modal',
  templateUrl: './forget-me-modal.component.html',
  styleUrls: ['./forget-me-modal.component.scss'],
})
export class ForgetMeModalComponent implements OnInit {
  modalData: ModalData;
  forgetMeEmailValue: string;
  errorEmailStatus: boolean;
  constructor(
    private forgetMeModalRef: MatLegacyDialogRef<ForgetMeModalComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) data: ModalData,
  ) {
    this.modalData = data;
  }
  private validateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail,
      )
    ) {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.errorEmailStatus = false;
  }
  onCloseClick() {
    this.forgetMeModalRef.close();
  }
  forgetPasswordFill(event: Event) {
    this.forgetMeEmailValue = (event.target as HTMLInputElement).value;
    this.errorEmailStatus = false;
    this.errorEmailStatus = this.validateEmail(this.forgetMeEmailValue);
  }

  onSendClick() {
    this.forgetMeModalRef.close({
      email: this.forgetMeEmailValue,
    } as PasswordResetRequest);
  }
}
