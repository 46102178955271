import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReloadService {
  /**
   * Private RxJS subject, controlled through the quotationIsUpdated public method
   * to emit signal when a notification regarding quotation is received from the
   * notification websocket
   */
  private quotationUpdated = new Subject<void>();
  /**
   * Used to expose only the Observable side of the quotationUpdated subject, piped
   * through a throttleTime to limit fire rate to atmost once per second
   */
  quotationUpdatedStream = this.quotationUpdated
    .asObservable()
    .pipe(throttleTime(1000));
  /**
   * Private RxJS subject, controlled through the quotationApprovalIsUpdated public method
   * to emit signal when a notification regarding quotation approval is received from the
   * notification websocket
   */
  private quotationApprovalUpdated = new Subject<void>();
  /**
   * Used to expose only the Observable side of the quotationApprovalUpdated subject, piped
   * through a throttleTime to limit fire rate to atmost once per second
   */
  quotationApprovalUpdatedStream = this.quotationApprovalUpdated
    .asObservable()
    .pipe(throttleTime(1000));
  /**
   * Private RxJS subject, controlled through the tradeConfirmationIsUpdated public method
   * to emit signal when a notification regarding trade confirmation is received from the
   * notification websocket
   */
  private tradeConfirmationSubject = new Subject<void>();
  /**
   * Used to expose only the Observable side of the tradeConfirmationSubject subject, piped
   * through a throttleTime to limit fire rate to atmost once per second
   */
  tradeConfirmationStream = this.tradeConfirmationSubject
    .asObservable()
    .pipe(throttleTime(1000));
  /**
   * Private RxJS subject, controlled through the tradeConfirmationApprovalIsUpdated public method
   * to emit signal when a notification regarding trade confirmation approval is received from the
   * notification websocket
   */
  private tradeConfirmationApprovalSubject = new Subject<void>();
  /**
   * Used to expose only the Observable side of the tradeConfirmationApprovalSubject subject, piped
   * through a throttleTime to limit fire rate to atmost once per second
   */
  tradeConfirmationApprovalStream = this.tradeConfirmationSubject
    .asObservable()
    .pipe(throttleTime(1000));

  /**
   * Used to emit event from the quotationUpdated subject
   */
  quotationIsUpdated() {
    this.quotationUpdated.next();
  }

  /**
   * Used to emit event from the quotationApprovalUpdated subject
   */
  quotationApprovalIsUpdated() {
    this.quotationApprovalUpdated.next();
  }

  /**
   * Used to emit event from the tradeConfirmationSubject subject
   */
  tradeConfirmationIsUpdated() {
    this.tradeConfirmationSubject.next();
  }

  /**
   * Used to emit event from the tradeConfirmationApprovalSubject subject
   */
  tradeConfirmationApprovalIsUpdated() {
    this.tradeConfirmationApprovalSubject.next();
  }
}
