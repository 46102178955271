import { HttpContext, HttpContextToken } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface HttpRequestContextOptions {
  /**
   * To identify how to handle error.
   * 1.Should be true if handle error on component level
   * 2 .Otherwise will handled globally.
   */
  customExceptionEnabled?: boolean;
  /**
   * To disable sound when there is an error.
   */
  disableSound?: boolean;
}
const defaultContextToken = {
  customExceptionEnabled: false,
  disableSound: false,
};
/**
 * The default http request context token that will return defaultContextToken
 */
export const HTTP_REQUEST_CONTEXT_OPTIONS =
  new HttpContextToken<HttpRequestContextOptions>(() => {
    return defaultContextToken;
  });
/**
 * Used to create a new HttpContext with the http request context token set to the provided contextToken
 * @deprecated this method is rarely used. Consider removal from codebase.
 * @param contextToken
 * @returns a new HttpContext with defaultContextToken set by HTTP_REQUEST_CONTEXT_OPTIONS
 */
export function getHttpRequestContext(
  contextToken?: HttpRequestContextOptions,
) {
  return new HttpContext().set(HTTP_REQUEST_CONTEXT_OPTIONS, contextToken);
}
/**
 * The default base url context token that will return environment.BASE_URL if not set by the http request
 */
export const BASE_URL = new HttpContextToken<string>(
  () => environment.BASE_URL,
);
/**
 * The default show loader context token that will return true if not set by the http request
 */
export const SHOW_LOADER = new HttpContextToken<boolean>(() => true);
