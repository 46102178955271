/**
 * User's SicomSubscription plan
 */
export enum SicomSubscription {
  DELAYED = 'Delayed',
  LIVE = 'Live',
}
/**
 * Enum for Six Stream States
 */
export enum StreamState {
  DELAYED = 'Delayed',
  LIVE = 'Live',
  STALE = 'Stale',
}
/**
 * Interface for the status of the six stream,
 * include the state and its associated tooltip text
 */
export interface SixStreamStatus {
  state: StreamState;
  toolTip: string;
}
/**
 * Available Sicom markets
 */
export enum SicomMarkets {
  RSS = 'RSS',
  TSR20 = 'TSR20',
}
/**
 * Enum for currency pairs
 */
export enum CurrencyPair {
  NONE = '',
  USDSGD = 'USD/SGD',
  USDIDR = 'USD/IDR',
  USDMYR = 'USD/MYR',
  USDCNY = 'USD/CNY',
  USDTHB = 'USD/THB',
  USDJPY = 'USD/JPY',
  EURUSD = 'EUR/USD',
  JPYUSD = 'JPY/USD',
}
/**
 * @deprecated to be replaced by a SicomStream model
 */
export interface SicomPriceUpdate<T = any> {
  [SicomMarkets.TSR20]: {
    [date: string]: SicomPrice<T>;
  };
  [SicomMarkets.RSS]: {
    [date: string]: SicomPrice<T>;
  };
}
/**
 * Model for Sicom Price data within
 * a selected market and month
 */
export interface SicomPrice<T> {
  symbol: string;
  open_price: T;
  last_trade: T;
  high_price: T;
  low_price: T;
  most_recent_settlement_price: T;
  bid_price: T;
  ask_price: T;
  open_interest: T;
  cumulative_instrument_volume: T;
  bid_quantity: T;
  ask_quantity: T;
}
/**
 * Model for the Sicom Trend for the contract
 * from a specific delivery month
 */
export interface SicomTrend {
  price: number;
  change: number;
  changePercent: number;
  changeClass?: string;
  changeIcon?: string;
}
/**
 * Model for the Sicom Trend by Delivery Month
 * with delivery month as keys, and the Sicom Trend
 * for that delivery month as values
 */
export interface SicomTrendByDeliveryMonth {
  [date: string]: SicomTrend;
}
/**
 * Model for Six Stream directly from Firestore
 */
export interface SixStream<T> {
  id: string;
  data: SixStreamUpdate<T>;
  time: number;
  timeParsed: string;
}
/**
 * Model for the data object inside
 * the Six Stream
 */
export interface SixStreamUpdate<T> {
  [SicomMarkets.TSR20]: SicomMarketData<T>;
  [SicomMarkets.RSS]: SicomMarketData<T>;
  FX: FxStream;
}
/**
 * Model for the Sicom-specific Stream,
 * inside the Six Stream Update
 */
export interface SicomStream {
  [SicomMarkets.TSR20]: SicomMarketData<number>;
  [SicomMarkets.RSS]: SicomMarketData<number>;
}
/**
 * Model for the Sicom Market Data, typically
 * is an object with dates as keys and Sicom Price as values
 */
export interface SicomMarketData<T> {
  [date: string]: SicomPrice<T>;
}
/**
 * Model for the FX-specific stream inside
 * the Six Stream Update
 */
export interface FxStream {
  [CurrencyPair.USDSGD]: number;
  [CurrencyPair.USDIDR]: number;
  [CurrencyPair.USDMYR]: number;
  [CurrencyPair.USDCNY]: number;
  [CurrencyPair.USDTHB]: number;
  [CurrencyPair.USDJPY]: number;
  [CurrencyPair.EURUSD]: number;
  [CurrencyPair.JPYUSD]: number;
}
/**
 * Model for Physical Index Stream directly from Firestore
 */
export interface PhysicalIndexStream {
  id?: string;
  physical_price_index: number;
  sicom_price: number;
  week: string;
}

/**
 * Interface for the Sicom Instrument Query
 */
export interface Instrument {
  InstrumentId: string;
  Values: {
    Date: string;
    DateTime: string;
    Time: string;
    Data: InstrumentDataPoint;
  }[];
}

/**
 * Interface for the Sicom Instrument Data Point
 */
export interface InstrumentDataPoint {
  cumulative_vol?: string;
  last_price?: string;
  high_price: string;
  low_price: string;
  most_recent_settlement_price: string;
  open_interest?: string;
  open_price: string;
}

/**
 * Interface for the Physical Index Data Point
 */
export interface PhysicalIndexDataPoint {
  SICOM_Agridence_SIR20_Physical_Spread_Index: number;
  SICOM_Price: number;
  Week: string;
  date: string;
}
