<div
  class="chat-message"
  [ngClass]="{ 'my-chat': myChat, 'other-chat': !myChat }">
  <div class="chat-item" *ngIf="!!chat">
    <div class="chat-owner" *ngIf="myChat">
      <div class="chat-readed">
        <img *ngIf="canaction" src="/assets/images/read-indicator.svg" alt="" />
      </div>
      <span>{{ datetime }}</span>
    </div>
    <div class="chat-owner" *ngIf="!myChat">
      {{ chat?.user.name }}, {{ datetime }}
    </div>
    <div class="chat-line">
      <div class="chat-block">
        <ng-container [ngSwitch]="chat.type">
          <ng-container *ngSwitchCase="'quotation'">
            <app-chat-message-quotation
              [type]="company?.type"
              [chat]="chat"></app-chat-message-quotation>
          </ng-container>
          <ng-container *ngSwitchDefault>{{ chat.message }}</ng-container>
        </ng-container>
      </div>
      <div
        *ngIf="canaction"
        (click)="openMenu($event)"
        (keyup.enter)="openMenu($event)"
        tabindex="0"
        class="icon-three-dots"></div>
    </div>
  </div>
</div>
