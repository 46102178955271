<div class="hc-top-menu">
  <!-- User Profile -->
  <div
    class="profile"
    (click)="onProfileClick()"
    (keyup.enter)="onProfileClick()"
    tabindex="0">
    <img [src]="imageSource" class="icon-profile" alt="" />
    <div>
      <div class="profile-name">
        <span>{{ profileName }}</span>
        <mat-icon>{{
          showProfileDropDown ? "expand_less" : "expand_more"
        }}</mat-icon>
      </div>
      <span class="company-name">{{ companyName }}</span>
      <div class="drop-box dropbox-profile" *ngIf="showProfileDropDown">
        <div class="title-container">
          <h4
            (click)="onProfileURLClick()"
            (keyup.enter)="onProfileURLClick()"
            tabindex="0">
            {{ "COMMON.EDIT_PROFILE" | transloco }}
          </h4>
          <h4
            (click)="onSettingClick()"
            (keyup.enter)="onSettingClick()"
            tabindex="0">
            {{ "COMMON.MY_SETTINGS" | transloco }}
          </h4>
        </div>
        <div class="title-container">
          <h4 (click)="onToUClick()" (keyup.enter)="onToUClick()" tabindex="0">
            {{ "COMMON.TERMS_OF_USE" | transloco }}
          </h4>
          <h4
            (click)="onSupportURLClick()"
            (keyup.enter)="onSupportURLClick()"
            tabindex="0">
            {{ "COMMON.SUPPORT" | transloco }}
          </h4>
        </div>
        <div>
          <h4
            (click)="onLogoutClick()"
            class="logout"
            (keyup.enter)="onLogoutClick()"
            tabindex="0">
            <span class="icon-medium"> <mat-icon inline>logout</mat-icon> </span
            >{{ "COMMON.SIGN_OUT" | transloco }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>
