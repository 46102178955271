<ng-container *ngIf="infiniteList$ | async as notifications; else loading">
  <cdk-virtual-scroll-viewport
    itemSize="59"
    minBufferPx="100"
    maxBufferPx="150"
    (scrolledIndexChange)="nextPage()">
    <div
      class="item-container"
      *cdkVirtualFor="
        let notification of notifications;
        let i = index;
        trackBy: trackByIdx
      ">
      <app-notification
        (read)="readNotification(notification)"
        [notification]="notification">
      </app-notification>
    </div>
    <div class="item-container">
      <ng-container *ngTemplateOutlet="loading"></ng-container>
      <ng-container
        *ngTemplateOutlet="
          emptyList;
          context: { $implicit: notifications }
        "></ng-container>
    </div>
  </cdk-virtual-scroll-viewport>
</ng-container>

<ng-template #loading>
  <div *ngIf="isLoading" class="loader mt-1">
    <mat-spinner class="mx-auto" [diameter]="20"></mat-spinner>
  </div>
</ng-template>

<ng-template #emptyList let-notifications>
  <p
    *ngIf="!isLoading && notifications.length === 0"
    class="empty-notification mt-1 text-center">
    {{ "COMMON.NO_NOTIFICATIONS" | transloco }}
  </p>
</ng-template>
