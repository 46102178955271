export interface ActionValue {
  text: string;
  value: boolean;
}

enum Actions {
  ok = 'ok',
  cancel = 'cancel',
  reject = 'reject',
  download = 'download',
}

enum InputAction {
  approve = 'approve',
  accept = 'accept',
  reject = 'reject',
  decline = 'decline',
  cancel = 'cancel',
  download = 'download',
}

export const DefaultActionInputToValue = new Map<string, ActionValue>([
  [InputAction.approve, { text: 'Approve', value: false }],
  [InputAction.accept, { text: 'Accept', value: false }],
  [InputAction.reject, { text: 'Reject', value: false }],
  [InputAction.decline, { text: 'Decline', value: false }],
  [InputAction.cancel, { text: 'Cancel', value: false }],
  [InputAction.download, { text: 'Download', value: false }],
]);

export const DefaultActionInputConversion = new Map<string, string>([
  [InputAction.approve, Actions.ok],
  [InputAction.accept, Actions.ok],
  [InputAction.reject, Actions.reject],
  [InputAction.decline, Actions.reject],
  [InputAction.cancel, Actions.cancel],
  [InputAction.download, Actions.download],
]);
