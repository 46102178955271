<div id="hc-modal">
  <div class="hc-modal-container">
    <div class="header">
      <h3 class="modal-title">{{ "COMMON.FORWARD" | transloco }}</h3>
      <img
        src="../../../assets/images/close.svg"
        (click)="close()"
        (keyup.enter)="close()"
        tabindex="0"
        class="modal-close"
        alt="" />
    </div>
    <div>
      <textarea
        class="hc-textarea"
        name=""
        id=""
        disabled
        [(ngModel)]="message"
        cols="50"></textarea>
    </div>
    <div class="room-member">
      <div class="search-bar">
        <input
          [(ngModel)]="search"
          (ngModelChange)="searchList()"
          type="search"
          class="hc-search"
          [placeholder]="'COMMON.SEARCH_CHAT' | transloco" />
      </div>
      <div class="room-list">
        <div class="room" *ngFor="let room of listSearched">
          <mat-checkbox
            (change)="selectedRoom(room)"
            [checked]="selected[room.id]"
            [value]="selected[room.id]"
            >{{ room.name }}</mat-checkbox
          >
        </div>
      </div>
    </div>

    <div class="button-container">
      <button mat-button (click)="forward()" id="hc-button-primary">
        {{ "COMMON.FORWARD" | transloco }}
      </button>
    </div>
  </div>
</div>
