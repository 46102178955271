<div [class]="active ? 'active' : 'fade'" class="dropdown-menu">
  <div class="bell-icon">
    <button mat-icon-button (click)="markAllAsAcknowledged()">
      <mat-icon
        [matBadgeHidden]="hideNotificationBadge"
        [matBadge]="unacknowledgedBadge"
        matBadgeSize="small"
        aria-hidden="false"
        >notifications
      </mat-icon>
    </button>
  </div>
  <ng-container *ngIf="active; then dropdownMenu"></ng-container>
</div>

<ng-template #dropdownMenu>
  <div class="dropdown-menu-content-container">
    <div class="dropdown-menu-content">
      <div class="header">
        <h2 class="hc-title-text">{{ "COMMON.NOTIFICATIONS" | transloco }}</h2>
        <button class="mark-all-read" mat-button (click)="markAllAsRead()">
          <mat-icon>done_all</mat-icon>
          Mark all as read
        </button>
      </div>
      <mat-tab-group
        [disableRipple]="true"
        [animationDuration]="0"
        [selectedIndex]="selectedIndex">
        <mat-tab labelClass="custom-label">
          <ng-template mat-tab-label>
            <h3 class="hc-title-text my-0">All</h3>
          </ng-template>
          <ng-template matTabContent>
            <app-notification-list></app-notification-list>
          </ng-template>
        </mat-tab>
        <mat-tab labelClass="custom-label">
          <ng-template mat-tab-label>
            <h3 class="hc-title-text my-0">Unread</h3>
          </ng-template>
          <ng-template matTabContent>
            <app-notification-list
              [isUnreadOnly]="true"></app-notification-list>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</ng-template>
