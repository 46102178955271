import { Injectable } from '@angular/core';
// import { environmentCNDev } from 'src/environments/cn/environment.dev';
// import { environmentCNProd } from 'src/environments/cn/environment.prod';
// import { environmentCNStag } from 'src/environments/cn/environment.stag';
// import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DomainService {
  chinaDomains = ['agridencecn'];
  isChina: boolean;

  setAppConfiguration(): void {
    // const appConfigName = environment.name.toLowerCase();
    // if (appConfigName === 'development' || appConfigName === 'testing') {
    //   Object.assign(environment, environmentCNDev);
    // } else if (appConfigName === 'staging') {
    //   Object.assign(environment, environmentCNStag);
    // } else if (appConfigName === 'production') {
    //   Object.assign(environment, environmentCNProd);
    // }
  }

  setIsChina(isChina: boolean): void {
    this.isChina = isChina;
  }
}
