import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import {
  ActionValue,
  DefaultActionInputToValue,
  DefaultActionInputConversion,
} from 'src/app/models/action-control-type';

@Component({
  selector: 'app-action-control-panel',
  templateUrl: './action-control-panel.component.html',
  styleUrls: ['./action-control-panel.component.scss'],
})
export class ActionControlPanelComponent implements OnChanges {
  @Input() isProducer: boolean;
  @Input() selectedCount = 0;
  @Input() inputActions: Map<string, boolean>;
  @Output() ok = new EventEmitter<void>();
  @Output() reject = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Output() download = new EventEmitter<string>();
  private actions: Map<string, ActionValue> = new Map<string, ActionValue>();

  ngOnChanges() {
    if (this.inputActions) {
      this.setActions(this.inputActions);
    }
  }

  setActions(actionValues: Map<string, boolean>) {
    Object.entries(actionValues).forEach(([key, value]) => {
      const defaultActionValue: ActionValue =
        DefaultActionInputToValue.get(key);
      const actionKey = DefaultActionInputConversion.get(key);
      if (actionKey && defaultActionValue) {
        const actionValue = { ...defaultActionValue, value: value };
        this.actions.set(actionKey, actionValue);
      }
    });
  }

  emitOk() {
    this.ok.emit();
  }

  emitReject() {
    this.reject.emit();
  }

  emitCancel() {
    this.cancel.emit();
  }

  emitDownload(fileType: 'pdf' | 'csv') {
    this.download.emit(fileType);
  }

  isButtonDisabled(): boolean {
    return this.selectedCount === 0;
  }

  showButton(names: Array<string> | string): boolean {
    if (Array.isArray(names)) {
      let showButton = false;
      names.forEach((name) => {
        const actionValue = this.actions.get(name);
        if (actionValue && actionValue.value) {
          showButton = true;
        }
      });
      return showButton;
    } else {
      const actionValue = this.actions.get(names);
      return actionValue ? actionValue.value : false;
    }
  }

  getText(key): string {
    const actionValue = this.actions.get(key);
    return actionValue ? actionValue.text : '';
  }

  showCancelSelectedButton(): boolean {
    //temporarily disabling the cancel selected button until confirmation on whether this is needed and
    // the functionality of this is clear to the end users, as said by product
    return false;
  }
}
