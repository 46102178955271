import { InjectionToken } from '@angular/core';
import CustomIcons from 'src/assets/icons/icons.json';

/**
 * An injection token for the global window object.
 * Mostly used to make it easier to mock and test
 * functionalities that relies on the global window object
 */
export const WINDOW_TOKEN = new InjectionToken<Window>('Window', {
  providedIn: 'root',
  factory(): Window {
    return window;
  },
});

/**
 * The interface for a custom icon
 */
export interface Icon {
  /**
   * The name of the icon
   */
  name: string;
  /**
   * The path to the icon, e.g 'assets/icons/icon.svg'
   */
  path: string;
}
/**
 * An injection token for the list of custom icons.
 * Has a default factory that will inject this in 'root'
 * and provide the list of custom icons from the icons.json file
 */
export const CUSTOM_ICONS = new InjectionToken<Icon[]>('CUSTOM_ICONS', {
  providedIn: 'root',
  factory: () => Array.from(CustomIcons),
});
