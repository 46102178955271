import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SafeUrlPipe } from './safe-url.pipe';

/*
 * Returns the absolute url
 * Takes an referenceURL argument.
 *   {{imageAbsoluteUrl:referenceURL }}
 */
@Pipe({ name: 'imageAbsoluteUrl' })
export class ImageAbsoluteUrlPipe implements PipeTransform {
  constructor(private safeUrlPipe: SafeUrlPipe) {}
  transform(referenceURL: string): string {
    const baseUrl = environment.MEDIA_BASE_URL;
    return this.safeUrlPipe.transform(`${baseUrl}${referenceURL}`);
  }
}
