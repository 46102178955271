import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from 'src/app/components/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { ChatDiscussComponent } from './chat-discuss/chat-discuss.component';
import { ChatDownloadComponent } from './chat-download/chat-download.component';
import { ChatFavoritesComponent } from './chat-favorites/chat-favorites.component';
import { ChatForwardMessageComponent } from './chat-forward-message/chat-forward-message.component';
import { ChatMessageQuotationComponent } from './chat-message-quotation/chat-message-quotation.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { ChatPopupComponent } from './chat-popup/chat-popup.component';
import { ChatSettingsComponent } from './chat-settings/chat-settings.component';
import { ChatTimelineCalendarComponent } from './chat-timeline-calendar/chat-timeline-calendar.component';
import { ChatComponent } from './chat/chat.component';

@NgModule({
  declarations: [
    ChatComponent,
    ChatDiscussComponent,
    ChatDownloadComponent,
    ChatFavoritesComponent,
    ChatForwardMessageComponent,
    ChatMessageComponent,
    ChatMessageQuotationComponent,
    ChatPopupComponent,
    ChatSettingsComponent,
    ChatTimelineCalendarComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    TranslocoModule,
    SharedModule,
    FormsModule,
    RouterModule,
  ],
  exports: [ChatComponent],
})
export class ChatModule {}
