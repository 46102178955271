import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { OpenTabsService } from '../services/open-tabs.service';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(
    private storage: StorageService,
    private tabService: OpenTabsService,
    private router: Router,
  ) {}

  /**
   * Check if login route can be access
   * @returns true no Authorization found and clear storage, else redirect to ['/dashboard'] and return false
   */
  canActivate(): boolean | UrlTree {
    this.tabService.addTab();
    if (this.storage.check('Authorization')) {
      return this.router.parseUrl('/dashboard');
    } else {
      this.storage.clear();
      return true;
    }
  }
}
