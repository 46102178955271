import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { retry } from 'rxjs/operators';
import { WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { SHOW_LOADER } from '../interceptors/interceptor-context';
import { LoginResponse } from '../models/api/responses/login';
import { StorageService } from './storage.service';
import {
  RetryLogic,
  WebSocketFactoryService,
} from './websocket-factory.service';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationService {
  protected serviceURL = {
    user_notification_list: 'user/notification/',
    user_notification_update: 'user/notification/',
  };
  protected readonly httpOptions = {
    headers: new HttpHeaders({}),
    body: {},
    params: new HttpParams(),
    context: new HttpContext(),
  };
  private retryLogic: RetryLogic = {
    transitionThreshold: 5,
    scalingDuration: 1000,
    maxDuration: 5000,
  };

  constructor(
    protected http: HttpClient,
    private webSocketFactoryService: WebSocketFactoryService,
    private storageService: StorageService,
  ) {}

  setGlobalLoadingContext(globalLoading = true) {
    this.httpOptions.context = new HttpContext().set(
      SHOW_LOADER,
      globalLoading,
    );
  }

  getList(param = {}): Observable<any> {
    this.setGlobalLoadingContext(false);
    const httpParam = new HttpParams({ fromObject: param });
    return this.http.get<any[]>(
      `${this.serviceURL.user_notification_list}?${httpParam.toString()}`,
      this.httpOptions,
    );
  }

  read(param): Observable<any> {
    return this.http.patch<any[]>(
      `${this.serviceURL.user_notification_update}${param['id']}/read/`,
      param,
    );
  }

  acknowledgeAll() {
    this.setGlobalLoadingContext(false);
    return this.http.patch(
      `${this.serviceURL.user_notification_list}mass_acknowledge/`,
      {},
      this.httpOptions,
    );
  }

  readAll() {
    this.setGlobalLoadingContext(false);
    return this.http.patch(
      `${this.serviceURL.user_notification_list}mass_read/`,
      {},
      this.httpOptions,
    );
  }

  register() {
    const loginResponse =
      this.storageService.retrieve<LoginResponse>('Authorization');
    const token = loginResponse?.token ?? null;
    const wsBaseUrl = this.getBaseUrl();
    return this.applyRetryLogic(
      this.webSocketFactoryService.makeSocket(
        `${wsBaseUrl}notifications/?token=${token}`,
      ),
    );
  }

  createRetryObs(
    retryLogic: RetryLogic,
  ): (error: any, retryAttempt: number) => Observable<any> {
    return (_, i) => {
      const retryAttempt = i + 1;
      if (retryAttempt <= retryLogic.transitionThreshold) {
        return timer(retryAttempt * retryLogic.scalingDuration);
      } else {
        return timer(retryLogic.maxDuration);
      }
    };
  }

  applyRetryLogic(webSocket: WebSocketSubject<any>): Observable<any> {
    return webSocket.pipe(
      retry({ delay: this.createRetryObs(this.retryLogic) }),
    );
  }

  /**
   * Returns the base url from the environment setting
   * Useful when mocking the environment url in unit test
   * @returns url string
   */
  getBaseUrl() {
    return `${environment.WS_BASE_URL}`;
  }
}
