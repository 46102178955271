export {};
declare global {
  interface Number {
    toPrice(this): any;
  }
}
Number.prototype.toPrice = toPrice;
function toPrice(): any {
  const fractionDigits = 3;
  return this.toFixed(fractionDigits);
}
