import {
  BarData,
  HistogramData,
  IChartApi,
  ISeriesApi,
  MouseEventParams,
  Time,
} from 'lightweight-charts';

/**
 * Interface for the tooltip data
 */
export interface ToolTipData {
  dateStr: Time;
  open?: number;
  close?: number;
  high?: number;
  low?: number;
  volume?: number;
  value?: number;
}
/**
 * Interface for the tooltip coordinate
 */
export interface ToolTipCoordinate {
  shiftedCoordinate: number;
  coordinateY: number;
}
/**
 * Interface for the chart references
 */
export interface ChartRefs {
  chart: IChartApi;
  chartContainer: HTMLElement;
}
/**
 * String Literal type for the chart range
 */
export type ChartRange = 'Week' | 'Month' | 'Year';
/**
 * A Crosshair Move Event handler, specifically for showing tooltip
 * on crosshair move event, to be used in sicom chart
 * @param param the mouse event params captured by lightweight chart
 * @param toolTip the tooltip HTML element to be rendered
 * @param container the container of the chart, used to getting width/height
 * @param candleStickSeries the candlestick series object, used to retrieve series data at pointer
 * @param histogramSeries the histogram series object, used to retrieve series data at pointer
 */
export function sicomTooltipHandler(
  param: MouseEventParams,
  toolTip: HTMLElement,
  container: HTMLElement,
  candleStickSeries: ISeriesApi<'Candlestick'>,
  histogramSeries: ISeriesApi<'Histogram'>,
) {
  if (!isMouseOnChart(param, container)) {
    toolTip.style.display = 'none';
  } else {
    const toolTipData = getSicomTooltipData(
      param,
      candleStickSeries,
      histogramSeries,
    );
    const toolTipCoord = getTooltipCoordinate(
      param,
      toolTip,
      container,
      toolTipData,
      candleStickSeries,
    );
    if (toolTipCoord.shiftedCoordinate && toolTipCoord.coordinateY) {
      toolTip.innerHTML = createSicomTooltipHTML(toolTipData);
      showTooltip(toolTip, toolTipCoord);
    }
  }
}
/**
 * A Crosshair Move Event handler, specifically for showing tooltip
 * on crosshair move event, to be used in fx chart
 * @param param the mouse event params captured by lightweight chart
 * @param toolTip the tooltip HTML element to be rendered
 * @param container the container of the chart, used to getting width/height
 * @param candleStickSeries the candlestick series object, used to retrieve series data at pointer
 */
export function fxTooltipHandler(
  param: MouseEventParams,
  toolTip: HTMLElement,
  container: HTMLElement,
  candleStickSeries: ISeriesApi<'Candlestick'>,
) {
  if (!isMouseOnChart(param, container)) {
    toolTip.style.display = 'none';
  } else {
    const toolTipData = getFxTooltipData(param, candleStickSeries);
    const toolTipCoord = getTooltipCoordinate(
      param,
      toolTip,
      container,
      toolTipData,
      candleStickSeries,
    );
    if (toolTipCoord.shiftedCoordinate && toolTipCoord.coordinateY) {
      toolTip.innerHTML = createFxTooltipHTML(toolTipData);
      showTooltip(toolTip, toolTipCoord);
    }
  }
}
/**
 * A Crosshair Move Event handler, specifically for showing tooltip
 * on crosshair move event, to be used in physical index chart
 * @param param the mouse event params captured by lightweight chart
 * @param toolTip the tooltip HTML element to be rendered
 * @param container the container of the chart, used to getting width/height
 * @param histogramSeries the histogram series object, used to retrieve series data at pointer
 */
export function physicalIndexTooltipHandler(
  param: MouseEventParams,
  toolTip: HTMLElement,
  container: HTMLElement,
  histogramSeries: ISeriesApi<'Histogram'>,
) {
  if (!isMouseOnChart(param, container)) {
    toolTip.style.display = 'none';
  } else {
    const toolTipData = getPhysicalIndexTooltipData(param, histogramSeries);
    const toolTipCoord = getTooltipCoordinate(
      param,
      toolTip,
      container,
      toolTipData,
      histogramSeries,
    );
    if (toolTipCoord.shiftedCoordinate && toolTipCoord.coordinateY) {
      toolTip.innerHTML = createPhysicalIndexTooltipHTML(toolTipData);
      showTooltip(toolTip, toolTipCoord);
    }
  }
}

/**
 * Create the tooltip HTML element
 * @returns the HTML element for tooltip
 */
export function createTooltipElement(): HTMLElement {
  const toolTip = document.createElement('div');
  toolTip.setAttribute(
    'style',
    `width: 96px; position: absolute; top: 0; left: 0; display: none;
    padding: 8px; box-sizing: border-box; text-align: left; z-index: 1000;
    pointer-events: none; border: 1px solid; border-radius: 2px; opacity: 0.85;
    background-color: white; color: black; border-color: #2962FF;`,
  );
  return toolTip;
}
/**
 * Determine the color for the data point by comparing
 * open and close prices
 * @param open open price in string
 * @param close close price in string
 * @returns hex color string for the data point
 */
export function getOHCLColor(
  open: number | string,
  close: number | string,
): string {
  const openPrice = typeof open === 'string' ? parseFloat(open) : open;
  const closePrice = typeof close === 'string' ? parseFloat(close) : close;
  return openPrice >= closePrice ? '#ef5350' : '#26a69a';
}
/**
 * Determine the color for the histogram bar by comparing
 * the value against 0
 * @param value the value of the histogram bar
 * @returns hex color string for the histogram bar
 */
export function getHistogramColor(value: number | string): string {
  const histogramValue = typeof value === 'string' ? parseFloat(value) : value;
  return histogramValue >= 0 ? '#26a69a' : '#ef5350';
}
/**
 * Only when tooltip coordinates are valid, then
 * create tooltip HTML and show tooltip, otherwise skip.
 * @param toolTip the tooltip HTML element to be rendered
 * @param toolTipData the tooltip data object
 * @param toolTipCoord the coordinate of the tooltip
 */
function showTooltip(toolTip: HTMLElement, toolTipCoord: ToolTipCoordinate) {
  const { shiftedCoordinate, coordinateY } = toolTipCoord;
  toolTip.style.left = `${shiftedCoordinate}px`;
  toolTip.style.top = `${coordinateY}px`;
  toolTip.style.display = 'block';
}
/**
 * Whether the mounse event is on the chart
 * @param param param of the mouse event captured by lightweight chart
 * @param container the chart container
 * @returns true if the mouse event is on the chart, false otherwise
 */
function isMouseOnChart(param: MouseEventParams, container: HTMLElement) {
  return !(
    param.point === undefined ||
    !param.time ||
    param.point.x < 0 ||
    param.point.x > container.clientWidth ||
    param.point.y < 0 ||
    param.point.y > container.clientHeight
  );
}
/**
 * Get data to populate the tooltip for Sicom chart
 * @param param param of the mouse event captured by lightweight chart
 * @param candleStickSeries the candlestick series object
 * @param histogramSeries the histogram series object
 * @returns the tooltip data object
 */
function getSicomTooltipData(
  param: MouseEventParams,
  candleStickSeries: ISeriesApi<'Candlestick'>,
  histogramSeries: ISeriesApi<'Histogram'>,
): ToolTipData {
  const dateStr = param.time;
  const candlestickData = param.seriesData.get(candleStickSeries) as BarData;
  const histogramData = param.seriesData.get(histogramSeries) as HistogramData;
  let open = NaN,
    close = NaN,
    high = NaN,
    low = NaN,
    value = NaN;
  if (candlestickData) {
    ({ open, close, high, low } = candlestickData);
  }
  if (histogramData) {
    ({ value } = histogramData);
  }
  return { dateStr, open, close, high, low, volume: value };
}
/**
 * Get data to populate the tooltip for Fx chart
 * @param param param of the mouse event captured by lightweight chart
 * @param candleStickSeries the candlestick series object
 * @returns the tooltip data object
 */
function getFxTooltipData(
  param: MouseEventParams,
  candleStickSeries: ISeriesApi<'Candlestick'>,
): ToolTipData {
  const dateStr = param.time;
  const candlestickData = param.seriesData.get(candleStickSeries) as BarData;
  const { open, close, high, low } = candlestickData;
  return { dateStr, open, close, high, low };
}
/**
 * Get data to populate the tooltip for Physical Index chart
 * @param param param of the mouse event captured by lightweight chart
 * @param histogramSeries the histogram series object
 * @returns the tooltip data object
 */
function getPhysicalIndexTooltipData(
  param: MouseEventParams,
  histogramSeries: ISeriesApi<'Histogram'>,
): ToolTipData {
  const dateStr = param.time;
  const histogramData = param.seriesData.get(histogramSeries) as HistogramData;
  const { value } = histogramData;
  return { dateStr, value };
}
/**
 * Create innerHTML for sicom tooltip
 * @param toolTipData the tooltip data object
 * @returns the innerHTML for the tooltip
 */
function createSicomTooltipHTML(toolTipData: ToolTipData) {
  const { dateStr, open, close, high, low, volume } = toolTipData;
  const innerHTML = `<div class="small-font" style="display: flex; flex-flow: column nowrap; row-gap: 10px;">
    <div>
      <span class="emphasized text-blue">Open: </span><span>${open.toFixed(
        2,
      )}</span>
    </div>
    <div>
      <span class="emphasized text-blue">Close: </span><span>${close.toFixed(
        2,
      )}</span>
    </div>
    <div>
      <span class="emphasized text-blue">High: </span><span>${high.toFixed(
        2,
      )}</span>
    </div>
    <div>
      <span class="emphasized text-blue">Low: </span><span>${low.toFixed(
        2,
      )}</span>
    </div>  
    <div>
      <span class="emphasized text-blue">Vol: </span><span>${volume.toFixed(
        2,
      )}</span>
    </div>  
    <div>
      <span>${dateStr}</span>
    </div>
  </div>`;
  return innerHTML;
}
/**
 * Create innerHTML for fx tooltip
 * @param toolTipData the tooltip data object
 * @returns the innerHTML for the tooltip
 */
function createFxTooltipHTML(toolTipData: ToolTipData) {
  const { dateStr, open, close, high, low } = toolTipData;
  const innerHTML = `<div class="small-font" style="display: flex; flex-flow: column nowrap; row-gap: 10px;">
    <div>
      <span class="emphasized text-blue">Open: </span><span>${open.toFixed(
        5,
      )}</span>
    </div>
    <div>
      <span class="emphasized text-blue">Close: </span><span>${close.toFixed(
        5,
      )}</span>
    </div>
    <div>
      <span class="emphasized text-blue">High: </span><span>${high.toFixed(
        5,
      )}</span>
    </div>
    <div>
      <span class="emphasized text-blue">Low: </span><span>${low.toFixed(
        5,
      )}</span>
    </div>
    <div>
      <span>${dateStr}</span>
    </div>
  </div>`;
  return innerHTML;
}
/**
 * Create innerHTML for Physical Index tooltip
 * @param toolTipData the tooltip data object
 * @returns the innerHTML for the tooltip
 */
function createPhysicalIndexTooltipHTML(toolTipData: ToolTipData) {
  const { dateStr, value } = toolTipData;
  const innerHTML = `<div class="small-font" style="display: flex; flex-flow: column nowrap; row-gap: 10px;">
    <div>
      <span class="emphasized text-blue">Index: </span><span>${value.toFixed(
        2,
      )}</span>
    <div>
      <span>${dateStr}</span>
    </div>
  </div>`;
  return innerHTML;
}
/**
 * Compute the tooltip coordinate for rendering
 * @param param the mouse event params captured by lightweight chart
 * @param container the container of the chart, used to getting width/height
 * @param toolTip the tooltip HTML element to be rendered
 * @param toolTipData the tooltip data object
 * @param series the series object to retrieve coordinate from
 * @returns the tooltip coordinate
 */
function getTooltipCoordinate(
  param: MouseEventParams,
  toolTip: HTMLElement,
  container: HTMLElement,
  toolTipData: ToolTipData,
  series: ISeriesApi<'Candlestick' | 'Histogram'>,
) {
  let shiftedCoordinate = param.point.x + 15;
  shiftedCoordinate = Math.max(
    0,
    Math.min(container.clientWidth - toolTip.clientWidth, shiftedCoordinate),
  );
  const value = toolTipData?.close ? toolTipData.close : toolTipData?.value;
  const coordinate = series.priceToCoordinate(value);
  if (isNaN(coordinate)) {
    return { shiftedCoordinate, coordinateY: coordinate };
  }
  const coordinateY =
    coordinate - toolTip.clientHeight - 15 > 0
      ? coordinate - toolTip.clientHeight - 15
      : Math.max(
          0,
          Math.min(
            container.clientHeight - toolTip.clientHeight - 15,
            coordinate + 15,
          ),
        );
  return { shiftedCoordinate, coordinateY };
}
