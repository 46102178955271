<div class="arp-fx-display-container">
  <div class="bootstrap-wrapper">
    <div class="container-fluid">
      <div class="row row-gap">
        <div class="col-md-5 col-sm-12">
          <div class="row mb-1 align-center">
            <div class="col-xl-4 col-5">
              <ng-container
                *ngIf="
                  currencyPairSelectField$ | async as currencyPairSelectField
                ">
                <mat-form-field
                  class="arp-mat-form-field-outline full-width"
                  appearance="outline">
                  <mat-label>{{ currencyPairSelectField.label }}</mat-label>
                  <mat-select
                    [placeholder]="currencyPairSelectField.placeholder"
                    [value]="currencyPairSelectField.selectedOptions"
                    (valueChange)="onSelectCurrencyPair($event)">
                    <mat-option
                      *ngFor="let option of currencyPairSelectField.options"
                      [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
            </div>
          </div>
          <div class="row row-gap align-center">
            <ng-container *ngIf="selectedFx$ | async as selectedFx">
              <div class="col-12">
                <div class="expanded-display">
                  <span class="normal-font mr-1">FX Mid Market Rate:</span>
                  <span class="extra-large-font emphasized text-accent mr-1">{{
                    selectedFx | number: "1.5"
                  }}</span>
                </div>
                <ng-container *ngIf="lastUpdatedTimeString$ | async as time">
                  <ng-container
                    *ngIf="sixStreamStatus$ | async as streamStatus">
                    <div class="align-center">
                      <mat-icon
                        class="mr-1"
                        [class.not-display]="
                          streamStatus.state === 'Live' ||
                          streamStatus.state === undefined
                        "
                        [class.delayed-color]="streamStatus.state === 'Delayed'"
                        [class.stale-color]="streamStatus.state === 'Stale'"
                        [matTooltip]="streamStatus.toolTip | transloco"
                        matTooltipClass="custom-mat-tooltip px-2">
                        warning
                      </mat-icon>
                      <span>Last updated: {{ time }}</span>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="fx$ | async as fx">
              <div class="col-lg-3 col-md-4 col-6">
                <div class="field-name">USD/MYR</div>
                <div class="field-value">
                  {{ fx["USD/MYR"] | number: "1.5" }}
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6">
                <div class="field-name">USD/JPY</div>
                <div class="field-value">
                  {{ fx["USD/JPY"] | number: "1.5" }}
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6">
                <div class="field-name">USD/THB</div>
                <div class="field-value">
                  {{ fx["USD/THB"] | number: "1.5" }}
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6">
                <div class="field-name">EUR/USD</div>
                <div class="field-value">
                  {{ fx["EUR/USD"] | number: "1.5" }}
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6">
                <div class="field-name">USD/SGD</div>
                <div class="field-value">
                  {{ fx["USD/SGD"] | number: "1.5" }}
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6">
                <div class="field-name">USD/IDR</div>
                <div class="field-value">
                  {{ fx["USD/IDR"] | number: "1.5" }}
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-6">
                <div class="field-name">USD/CNY</div>
                <div class="field-value">
                  {{ fx["USD/CNY"] | number: "1.5" }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-md-7 col-sm-12 chart-container">
          <ng-container *ngIf="chartLoading$ | async">
            <div class="local-loader">
              <app-arp-loader></app-arp-loader>
            </div>
          </ng-container>
          <div class="range-picker">
            <ng-container *ngIf="rangeToggleField$ | async as rangeToggleField">
              <mat-button-toggle-group
                class="arp-mat-button-toggle-group"
                [name]="rangeToggleField.name"
                aria-label="range"
                [appearance]="rangeToggleField.appearance"
                [value]="rangeToggleField.selectedOptions"
                (change)="onSelectRange($event)">
                <mat-button-toggle
                  *ngFor="let option of rangeToggleField.options"
                  [value]="option"
                  >{{ option }}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </ng-container>
          </div>
          <div class="chart-area">
            <div class="chart" arpLightweightChart></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
