import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BaseAPIService } from './base-api.service';

// URL JSON
import NonHCURL from '../../assets/content/services/non-hc-service.json';

@Injectable({
  providedIn: 'root',
})
export class NonHcService extends BaseAPIService {
  protected serviceURL = NonHCURL;

  constructor(protected http: HttpClient) {
    super(http);
  }

  requestOTP(token: string): Observable<any> {
    return this.http
      .post<any>(
        this.baseUrl + this.serviceURL.nonHCUrl + token + '/request_otp/',
        {},
      )
      .pipe(
        catchError((error) => {
          error = this.handleError(error);
          return throwError(() => error);
        }),
      );
  }

  getContract(token: string, otp: string): Observable<any> {
    return this.http
      .get<any>(
        this.baseUrl + this.serviceURL.nonHCUrl + token + '/?otp=' + otp,
      )
      .pipe(
        catchError((error) => {
          error = this.handleError(error);
          return throwError(() => error);
        }),
      );
  }
}
