<nav class="hc-main-menu">
  <ng-container *ngFor="let item of mainMenuItems">
    <div
      class="menu-title-container"
      *ngIf="!hasChildMenu(item)"
      [routerLink]="item.route"
      routerLinkActive="active">
      <a>
        <span class="icon-medium">
          <mat-icon *ngIf="item.isMatIcon; else customIcon" inline>{{
            item.iconName
          }}</mat-icon>
          <ng-template #customIcon>
            <mat-icon [svgIcon]="item.iconName" inline></mat-icon>
          </ng-template>
        </span>
        <h5>{{ item.displayName | transloco }}</h5>
      </a>
    </div>
    <div
      class="menu-title-container"
      *ngIf="hasChildMenu(item)"
      [class.active]="isMenuItemActive(item.route, defaultMatchOptions)"
      (click)="toggleMenuExpansion(item)"
      (keyup.enter)="toggleMenuExpansion(item)"
      tabindex="0">
      <a>
        <span class="icon-medium">
          <mat-icon *ngIf="item.isMatIcon; else customIconParent" inline>{{
            item.iconName
          }}</mat-icon>
          <ng-template #customIconParent>
            <mat-icon [svgIcon]="item.iconName" inline></mat-icon>
          </ng-template>
        </span>
        <h5>
          {{ item.displayName | transloco }}
          <span *ngIf="hasChildMenu(item)" class="icon-x-small">
            <mat-icon
              [svgIcon]="item?.expanded ? 'arrow-down' : 'arrow-right'"
              inline></mat-icon>
          </span>
        </h5>
      </a>
    </div>
    <ng-container *ngIf="hasChildMenu(item) && item.expanded">
      <div
        class="sub-menu"
        *ngFor="let subMenu of item.children"
        [routerLink]="subMenu.route"
        [queryParams]="subMenu.routeQueryParams"
        [routerLinkActiveOptions]="defaultMatchOptions"
        routerLinkActive="active">
        <a>
          <h5>{{ subMenu.displayName | transloco }}</h5>
        </a>
      </div>
    </ng-container>
  </ng-container>
</nav>
