import { StreamState } from '../models/sicom-latest-price';

/**
 * Get the correct tooltip translation key based on the stream status
 * @param streamStatus the stream status of the six stream
 * @returns the translation key of the tooltip text
 **/
export function getTooltipByStreamStatus(streamStatus: StreamState) {
  return streamStatus === StreamState.LIVE
    ? ''
    : streamStatus === StreamState.DELAYED
    ? 'TOOLTIPS.DELAYED_DATA'
    : 'TOOLTIPS.STALE_DATA';
}
