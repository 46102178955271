<div class="hc-modal-container">
  <h3 class="mat-dialog-title">{{ modalData.title | transloco }}</h3>
  <mat-dialog-content>
    <!-- TODO: change to material input -->
    <input
      class="hc-backup-code"
      type="password"
      [placeholder]="'Enter backup code' | transloco"
      (input)="backupCodeFill($event)" />
    <span class="error" *ngIf="errorBackupCodeStatus">{{ errorMessage }}</span>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>
      {{ "COMMON.CANCEL" | transloco }}
    </button>
    <button
      mat-button
      id="hc-button-primary"
      [disabled]="errorBackupCodeStatus || !backupCodeValue"
      (click)="onSendClick()">
      {{ "COMMON.VERIFY" | transloco }}
    </button>
  </mat-dialog-actions>
</div>
