import { Component, Input, OnInit } from '@angular/core';
import {
  Chart,
  ChartConfiguration,
  ChartData,
  ChartType,
  Plugin,
} from 'chart.js';
import { ChartConfig } from 'src/app/models/chart-config';

export const doughnutChartCenterDisplayPlugin: Plugin = {
  id: 'custom_doughnut_chart_center_display',
  afterDraw(chart: Chart) {
    const ctx = chart.ctx;
    const activeElements = chart.getActiveElements();
    let value: number;

    if (activeElements.length > 0) {
      value = chart.data.datasets[activeElements[0].datasetIndex].data[
        activeElements[0].index
      ] as number;
    } else {
      value = chart.data.datasets[0].data.reduce(
        (accumulator: number, currentValue: number) =>
          accumulator + currentValue,
      ) as number;
    }
    const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
    const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
    ctx.font = '20px Nunito Sans';
    ctx.fillStyle = 'black';
    ctx.textAlign = 'center';
    ctx.fillText(`${value.toFixed(2)} MT`, centerX, centerY);
  },
};
@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styles: [
    `
      h3 {
        margin-top: unset;
      }

      div {
        position: relative;
        height: 30vh;
      }
    `,
  ],
})
export class DoughnutChartComponent implements OnInit {
  @Input() dataSource: ChartConfig;
  doughnutChartType: ChartType = 'doughnut';
  doughnutChartData: ChartData;
  doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: '85%',
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          padding: 15,
          font: {
            size: 12,
            family: 'Nunito Sans',
          },
          usePointStyle: true,
          color: 'rgb(143, 142, 142)',
          boxWidth: 10,
        },
      },
      tooltip: {
        bodyFont: {
          size: 15,
          family: 'Nunito Sans',
        },
        callbacks: {
          title: () => null,
          label: (context): string => {
            const allData = context.dataset.data as number[];
            const tooltipData = allData[context.dataIndex];
            const total = allData.reduce(this.sumAccumulator);
            return `${context.label}: ${((tooltipData / total) * 100).toFixed(
              2,
            )}%`;
          },
        },
      },
    },
  };
  doughnutChartPlugin = [doughnutChartCenterDisplayPlugin];
  total;

  ngOnInit() {
    this.doughnutChartData = {
      datasets: [{ data: this.dataSource.dataset as number[] }],
    };
  }

  private sumAccumulator = (accumulator, currentValue) =>
    accumulator + currentValue;
}
