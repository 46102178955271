import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  downloadCsv(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[],
    headers: { title: string; key: string }[],
    filename: string,
  ) {
    const csvData = this.convertToCsv(data, headers);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('style', 'display: none');
    document.body.appendChild(a);
    a.href = url;
    a.download = `${filename}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  private convertToCsv(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any[],
    headers: { title: string; key: string }[],
  ): string {
    if (!data || !data.length) {
      return '';
    }

    const separator = ',';
    const csvHeaders = headers.map((header) => header.title).join(separator);
    const csvContent =
      csvHeaders +
      '\n' +
      data
        .map((row) => headers.map((header) => row[header.key]).join(separator))
        .join('\n');

    return csvContent;
  }
}
