import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { AppStorage } from '../utils/storage-constants';

export const emptyGuard: CanActivateFn = (route, state) => {
  const storageService = inject(StorageService);
  const router = inject(Router);

  const tenantId = storageService.retrieve(AppStorage.tenantId);
  if (tenantId)
    if (tenantId) {
      router.navigate([`/org/${tenantId}/login`]);
    } else {
      router.navigate(['/login']);
    }
  return true;
};
