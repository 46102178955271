<div class="no-result-found" *ngIf="!tableData?.count">
  <h2 class="title">No result found</h2>
  <p class="text">Please try other filters or different keywords</p>
</div>
<div class="table-container" *ngIf="tableData?.count">
  <table mat-table [dataSource]="tableData.results">
    <ng-container matColumnDef="checkbox" [sticky]="true">
      <th [style]="'width: 50px'" mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="masterToggle($event)"
          [checked]="isMasterCheckboxChecked()"
          [indeterminate]="isMasterCheckboxIndeterminate()">
        </mat-checkbox>
      </th>
      <td [style]="'width: 50px'" mat-cell *matCellDef="let element">
        <mat-checkbox
          (click)="rowCheckboxClicked($event)"
          (change)="rowCheckboxChanged($event, element)"
          [checked]="isRowCheckboxChecked(element)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container
      *ngFor="let columnConfig of tableConfig"
      [matColumnDef]="columnConfig.columnName">
      <th [style]="columnConfig.columnStyle" mat-header-cell *matHeaderCellDef>
        {{ columnConfig.columnDisplayName | transloco }}
        {{ columnConfig?.columnSuffix ? " " + columnConfig?.columnSuffix : "" }}
      </th>
      <td [style]="columnConfig.columnStyle" mat-cell *matCellDef="let element">
        {{ element[columnConfig.columnDisplayedField].displayName
        }}{{ columnConfig.columnDisplayedFieldSuffix ?? "" }}
      </td>
    </ng-container>
    <ng-container
      *ngIf="actionsColumnConfig"
      [matColumnDef]="actionsColumnConfig.columnName"
      [stickyEnd]="true">
      <th
        [style]="actionsColumnConfig.columnStyle"
        mat-header-cell
        *matHeaderCellDef>
        {{ actionsColumnConfig.columnDisplayName | transloco }}
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        [style]="actionsColumnConfig.columnStyle">
        <ng-container
          *ngFor="let buttonConfig of actionsColumnConfig.buttonConfigs">
          <button
            mat-button
            *ngIf="buttonConfig.buttonType === 'basic'"
            [style]="buttonConfig.buttonStyle"
            (click)="actionClicked($event, element, buttonConfig.eventName)">
            {{ buttonConfig.buttonName | transloco }}
          </button>
          <button
            mat-icon-button
            *ngIf="buttonConfig.buttonType === 'icon'"
            [style]="buttonConfig.buttonStyle"
            (click)="actionClicked($event, element, buttonConfig.eventName)">
            <mat-icon>{{ buttonConfig.iconName | transloco }}</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="{
        selected: isRowCheckboxChecked(row),
        hoverable: isRowHoverable
      }"
      (click)="rowClicked(row)"></tr>
  </table>
</div>
<!-- Table Pagination-->
<div class="pagination-container" *ngIf="tableData?.count">
  <mat-paginator
    appPaginator
    [disabled]="selectedRows.selected.length > 0"
    [length]="tableData.count"
    [pageIndex]="tableData.page - 1"
    [pageSize]="tableData.page_size"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageChangeEvent($event)">
  </mat-paginator>
</div>
