export enum ApprovalType {
  SuperManager = 'Super Manager',
  Manager = 'Manager',
  Trader = 'Trader',
  Normal = 'Normal',
}
export enum CompanyApprovalType {
  quotationRequired = 'Quotation required',
  notRequired = 'Not required',
  required = 'Required',
  contractRequired = 'Contract required',
}
export interface Approval {
  user: number;
  type: ApprovalType;
}

export interface QuotationApprovalActions {
  approve: boolean;
  reject: boolean;
  cancel: boolean;
  edit: boolean;
  checkbox: boolean;
  status: boolean;
}

export interface ManagerApprovalType {
  name: string;
  condition: Array<string>;
}

export const ManagerApproval: Array<ManagerApprovalType> = [
  {
    name: 'required',
    condition: [CompanyApprovalType.required],
  },
  {
    name: 'contract',
    condition: [CompanyApprovalType.contractRequired],
  },
  {
    name: 'quotation',
    condition: [CompanyApprovalType.quotationRequired],
  },
];
