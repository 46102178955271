import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { isPlatformBrowser } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class OpenTabsService {
  constructor(
    private storageService: StorageService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {}

  addTab() {
    if (isPlatformBrowser(this.platformId)) {
      let tabHash = sessionStorage.getItem('tab');
      let activeTabs = this.storageService.retrieve('tabs');

      // WK: add tab hash to session storage
      if (!tabHash) {
        tabHash = `tab_${Date.now()}`;
        sessionStorage.setItem('tab', tabHash);

        // WK: new all tabs has been closed previously
        // so have to redirect to login and clear storage
        if (activeTabs) {
          if (Object.keys(activeTabs).length === 0) {
            this.router.navigate(['/login']);
            this.storageService.clear();
          }
        }
      }

      // WK: create active tabs if first tab
      if (!activeTabs) {
        activeTabs = {};
      }
      activeTabs[tabHash] = true;

      this.storageService.store('tabs', activeTabs);
    }
  }

  deleteTab() {
    if (isPlatformBrowser(this.platformId)) {
      // WK: no need clear session storage it's auto cleared
      const tabHash = sessionStorage.getItem('tab');

      const activeTabs = this.storageService.retrieve('tabs');
      if (activeTabs[tabHash]) {
        delete activeTabs[tabHash];
      }
      this.storageService.store('tabs', activeTabs);
    }
  }
}
