<div class="maximize-body" (scroll)="onSettingScroll()">
  <div class="wrapper">
    <div>
      <div class="profile-name text-overflow-ellipsis">{{ profileName }}</div>
      <div class="company-name text-overflow-ellipsis">{{ companyName }}</div>
    </div>
    <hr />
    <div>
      <div class="setting-item">
        <div
          class="item-line"
          (click)="openAvailablePopup()"
          (keyup.enter)="openAvailablePopup()"
          tabindex="0">
          <div class="status-wrapper">
            <div class="online-status online" [ngClass]="chatStatus.icon"></div>
            <div>{{ chatStatus.label }}</div>
          </div>
          <div>
            <img src="/assets/images/black-arrow-right.svg" alt="" />
          </div>
        </div>
        <div
          class="drop-box drop-box--chat-available"
          tabindex="-1"
          (focusout)="closeAvailable()"
          #chatavailable
          *ngIf="openAvailable">
          <div
            class="more-menu-item"
            (click)="changeAvailable(item)"
            (keyup.enter)="changeAvailable(item)"
            tabindex="0"
            *ngFor="let item of chatStatusList">
            <div class="status-wrapper">
              <div class="online-status" [ngClass]="item.icon"></div>
              <div>{{ item.label }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div>
      <div
        (click)="downloadChat()"
        (keyup.enter)="downloadChat()"
        tabindex="0"
        class="setting-item"
        [ngClass]="{ active: activeDownloadChat }">
        <div>{{ "COMMON.DOWNLOAD_CHAT" | transloco }}</div>
      </div>
    </div>
  </div>
</div>
