import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// SERVICE
import { StorageService } from '../services/storage.service';

// MODEL
import { LoginResponse } from '../models/api/responses/login';

/**
 * Intercept all http request to inject storage token
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService) {}

  /**
   * intercept http request handling
   * @param req intercepted request
   * @param next next interceptor in chain
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const loginResponse =
      this.storageService.retrieve<LoginResponse>('Authorization');
    if (loginResponse !== null) {
      req = req.clone({
        setHeaders: {
          Authorization: `Token ${loginResponse.token}`,
        },
      });
    }
    return next.handle(req);
  }
}
