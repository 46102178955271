import { Injectable } from '@angular/core';
import { MatLegacySnackBar } from '@angular/material/legacy-snack-bar';
import { ToastAction } from '../models/toast-action';

/**
 * @description Wrapper Service for Material Toast
 */
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  /**
   * @description default value for duration
   */
  private static readonly DURATION = 3000;

  constructor(private snackBar: MatLegacySnackBar) {}

  /**
   * @description creates an action toast
   * @param message message to show
   * @param action label and callback function for action
   * @param duration default is 3000
   */
  actionToast(
    message: string,
    action: ToastAction,
    duration: number = ToastService.DURATION,
  ) {
    const ref = this.snackBar.open(message, action.label, {
      duration,
      panelClass: ['action-toast'],
    });

    ref.onAction().subscribe(action.action);
    return ref;
  }

  /**
   * @description creates an positive toast
   * @param message message to show
   * @param duration default is 3000
   */
  positiveToast(message: string, duration: number = ToastService.DURATION) {
    return this.snackBar.open(message, null, {
      duration,
      panelClass: ['positive-toast'],
    });
  }

  /**
   * @description creates an primary toast
   * @param message message to show
   * @param duration default is 3000
   */
  primaryToast(message: string, duration: number = ToastService.DURATION) {
    return this.snackBar.open(message, null, {
      duration,
      panelClass: ['primary-toast'],
    });
  }

  /**
   * @description creates an neutral toast
   * @param message message to show
   * @param duration default is 3000
   */
  neutralToast(message: string, duration: number = ToastService.DURATION) {
    return this.snackBar.open(message, null, {
      duration,
      panelClass: ['neutral-toast'],
    });
  }

  /**
   * @description creates an negative toast
   * @param message message to show
   * @param duration default is 1000
   */
  negativeToast(message: string, duration: number = ToastService.DURATION) {
    return this.snackBar.open(message, null, {
      duration,
      panelClass: ['negative-toast'],
    });
  }

  /**
   * @description dismisses any toast that is currently showing
   */
  dismissToast() {
    this.snackBar.dismiss();
  }

  /**
   *
   * @param toast toast properties to be emitted
   * @description emits toast for CRD and Traceability v2 - listen from iFrame events
   */
  emitToast(toast: { message: string; isSuccess: boolean }) {
    if (!toast) return;

    if (toast.isSuccess) {
      this.positiveToast(toast.message, 5000);
      return;
    }

    this.negativeToast(toast.message, 5000);
  }
}
