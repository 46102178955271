import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChatList, ChatMessageType, ChatRoom } from 'src/app/models/chatroom';
import { ChatWebsocketService } from 'src/app/services/chat-websocket.service';
import { ChatService } from 'src/app/services/chat.service';
import { SoundService, SoundTypes } from 'src/app/services/sound.service';
import { NotificationMessageType } from 'src/app/models/notification-message-type';
import { StorageService } from 'src/app/services/storage.service';
import { Subject, take, takeUntil } from 'rxjs';
import { SvgLoaderService } from 'src/app/services/svg-loader.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  showMaximizeChat: boolean;
  showForwardMessage: boolean;
  currentRooms: ChatRoom[] = [];
  chatList: ChatList;
  unreadMessageCount: number;
  unreadMessageCountDisplay: string;
  chatListSearched: ChatList = {
    chatGroupList: [],
    chatUserList: [],
  };

  search: string;

  chatRoomExpansion = {
    chatGroup: true,
    chatUser: true,
  };

  selectedMenu: string;
  unreadChatRoom = {};
  svgContents: { [key: string]: string } = {};

  constructor(
    private chatService: ChatService,
    private chatWebsocketService: ChatWebsocketService,
    private storageService: StorageService,
    private soundService: SoundService,
    private svgLoaderService: SvgLoaderService,
  ) {}

  ngOnInit() {
    this.chatService.initialService();
    this.chatWebsocketService
      .register()
      .pipe(takeUntil(this.destroy$))
      .subscribe((messageData: any) => {
        this.newMessageReceived(messageData);
      });
    this.selectedMenu = 'discuss';
    this.chatService.showChat
      .pipe(takeUntil(this.destroy$))
      .subscribe((showChat) => (this.showMaximizeChat = showChat));
    this.chatService.openRooms
      .pipe(takeUntil(this.destroy$))
      .subscribe((rooms: ChatRoom[]) => (this.currentRooms = rooms));
    this.chatService.roomList
      .pipe(takeUntil(this.destroy$))
      .subscribe((room) => {
        const messages_count = room.flatMap((p) => p.unread_messages);

        if (messages_count.length) {
          this.unreadMessageCount = messages_count.reduce(
            (total, item) => total + item,
          );
          this.unreadMessageCountDisplay =
            this.unreadMessageCount < 10 ? `${this.unreadMessageCount}` : '9+';
        }
      });

    this.chatService.forwardMessage
      .pipe(takeUntil(this.destroy$))
      .subscribe((message) => (this.showForwardMessage = !!message));

    this.loadSvgIcons();
  }

  loadSvgIcons() {
    const svgUrls = [
      { name: 'blue-arrow-down', url: `assets/images/blue-arrow-down.svg` },
    ];

    svgUrls.forEach((svg) => {
      this.svgLoaderService
        .loadSvg(svg.url)
        .pipe(take(1))
        .subscribe(
          (content) => (this.svgContents[svg.name] = content),
          (error) => console.error('Error loading SVG:', error),
        );
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.chatService.closeService();
  }

  protected newMessageReceived(messageData: any) {
    if (
      messageData?.type === NotificationMessageType.NewMessage &&
      messageData?.message?.type
    ) {
      const chatObj = this.chatService.formatChatMessage(messageData.message);
      const roomId = chatObj.room;
      this.chatService.setChatMessage(roomId, [chatObj]);
      this.chatService.setUnread(roomId);
      if (this.checktoPlaySound(messageData.message, roomId)) {
        this.playSound();
      }
    } else if (messageData?.type === NotificationMessageType.Online) {
      const roomId = messageData.chatroom_id;
      const userId = messageData.user_id;
      this.chatService.updateMemberStatus(roomId, userId, true);
    } else if (messageData?.type === NotificationMessageType.Offline) {
      const roomId = messageData.chatroom_id;
      const userId = messageData.user_id;
      this.chatService.updateMemberStatus(roomId, userId, false);
    }
  }

  onMaximizeClick() {
    this.chatService.chatMaximize();
  }

  onMinimizeClick() {
    this.chatService.chatMinimize();
  }

  onSelectMenu(menu: string) {
    this.selectedMenu = menu;
  }

  openChat(room: ChatRoom) {
    this.chatService.open(room);
  }

  private checktoPlaySound(message, roomId: number) {
    const chatSoundEnabled =
      this.storageService.retrieve<boolean>('enableChatSound');
    const notificationSoundEnabled = this.storageService.retrieve<boolean>(
      'enableNotificationSound',
    );
    // Do not play sound if chat sound is disabled in user settings
    if (!chatSoundEnabled) {
      return false;
    }
    // check if it is not active room
    if (this.chatService.isActiveRoom(roomId)) {
      return false;
    }

    // if the notification sound is disabled and the message type is quotation
    if (
      !notificationSoundEnabled &&
      message?.type === ChatMessageType.quotation
    ) {
      return true;
    }
    // if it is chat message
    if (message?.type === ChatMessageType.message) {
      return true;
    }
    return false;
  }
  playSound() {
    this.soundService.playSound(SoundTypes.chat);
  }
}
