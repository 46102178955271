import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { retry } from 'rxjs/operators';
import { WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { LoginResponse } from '../models/api/responses/login';
import { StorageService } from './storage.service';
import {
  RetryLogic,
  WebSocketFactoryService,
} from './websocket-factory.service';

@Injectable({
  providedIn: 'root',
})
export class ChatWebsocketService {
  constructor(
    private storageService: StorageService,
    private webSocketFactoryService: WebSocketFactoryService,
  ) {}
  private retryLogic: RetryLogic = {
    transitionThreshold: 5,
    scalingDuration: 1000,
    maxDuration: 5000,
  };

  register() {
    const loginResponse =
      this.storageService.retrieve<LoginResponse>('Authorization');
    const token = loginResponse?.token ?? null;
    const url = this.getBaseUrl();
    return this.applyRetryLogic(
      this.webSocketFactoryService.makeSocket(
        url + 'chatroom/messages/?token=' + token,
      ),
    );
  }

  createRetryObs(
    retryLogic: RetryLogic,
  ): (error: any, retryAttempt: number) => Observable<any> {
    return (_, i) => {
      const retryAttempt = i + 1;
      if (retryAttempt <= retryLogic.transitionThreshold) {
        return timer(retryAttempt * retryLogic.scalingDuration);
      } else {
        return timer(retryLogic.maxDuration);
      }
    };
  }

  applyRetryLogic(webSocket: WebSocketSubject<any>): Observable<any> {
    return webSocket.pipe(
      retry({ delay: this.createRetryObs(this.retryLogic) }),
    );
  }

  /**
   * Returns the base url from the environment setting
   * Useful when mocking the environment url in unit test
   * @returns url string
   */
  getBaseUrl() {
    return `${environment.WS_BASE_URL}`;
  }
}
