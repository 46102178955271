import { HttpErrorResponse } from '@angular/common/http';
import { Injector } from '@angular/core';

export abstract class Message {
  icon?: string;
  color?: string;
  message = {} as Message;
  content?: string | HttpErrorResponse;
  type?: number;
  title?: string;
  disableSound?: boolean;
  enableCustomException = false;
  buttonPrimaryText = 'Back to Login';
  buttonSecondaryText?: string;

  public unpackErrorMessage(item) {
    let message = '';
    if (item.constructor === Object) {
      for (const [key, value] of Object.entries(item)) {
        message += `${key} - ${this.unpackErrorMessage(value)}\n`;
      }
    } else if (item.constructor === Array) {
      for (const element of item) {
        message += this.unpackErrorMessage(element) + '\n';
      }
    } else {
      message = item;
    }
    return message;
  }
  abstract close(inject?: Injector): void;
  abstract action(inject?: Injector): void;
  abstract getMessage(content: string | HttpErrorResponse): Message;
}
