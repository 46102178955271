import { Component, Input, Output, EventEmitter } from '@angular/core';
import Format from '@date-format';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  @Input() notification;
  @Output() read = new EventEmitter();

  dateFormat = Format.notificationDateFormat;
  timeFormat = Format.notificationTimeFormat;

  readInNewWindow() {
    const { quotation_id, contract_id } = this.notification.message;
    let link: string;

    if (quotation_id) {
      link = `/spot/trade?id=${quotation_id}`;
    } else if (contract_id) {
      link = `/contract-hub/contract?id=${contract_id}`;
    }

    if (!link) return;
    this.read.emit(true);
    window.open(link, '_blank');
  }

  readAndAcknowledge(event: Event) {
    this.read.emit(true);
    event.preventDefault();
    event.stopPropagation();
  }

  downloadSIFile() {
    if (
      this.notification.type === 'quality-download' &&
      this.notification.url !== ''
    ) {
      window.open(this.notification.url, '_blank');
    }
  }
}
