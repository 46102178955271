import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { HttpLoaderService } from '../services/http-loader.service';
import { SHOW_LOADER } from './interceptor-context';

@Injectable()
export class LoadingIconInterceptor implements HttpInterceptor {
  constructor(private httpLoaderService: HttpLoaderService) {}

  /**
   * Responsible to showing/hiding app loading icon using the HTTPLoaderService. Will:
   * 1. Ignore any requests that fail the isIgnoreUrl check.
   * 2. Show loading icon before request is forwarded.
   * 3. Hide loading icon after request is completed, regardless of success or failure.
   * @param request the outgoing http request
   * @param next the next http handler, typically is another interceptor or the final HttpBackend
   * @returns the observable wrapping the http event from the next http handler
   */
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (this.isIgnoreUrl(request.url)) {
      return next.handle(request);
    }
    if (request.context.get(SHOW_LOADER)) {
      this.httpLoaderService.setLoading(true, request.url);
    }
    return next.handle(request).pipe(
      finalize(() => {
        this.httpLoaderService.setLoading(false, request.url);
      }),
    );
  }
  /**
   * Ignore any http requests that include assets in the url
   * @param url the url of the http request
   * @returns true if the url includes assets, false otherwise
   */
  isIgnoreUrl(url: string): boolean {
    return url.includes('assets/');
  }
}
