import { Injectable } from '@angular/core';
import Format from '@date-format';
import { format, sub } from 'date-fns';
import { ChartOptions, HistogramData } from 'lightweight-charts';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  filter,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { ArpLightweightChartDirective } from '../directives/arp-lightweight-chart/arp-lightweight-chart.directive';
import {
  ButtonToggleGroupModel,
  SelectFieldModel,
} from '../models/form-field-models';
import { PhysicalIndexDataPoint } from '../models/sicom-latest-price';
import {
  ChartRange,
  createTooltipElement,
  getHistogramColor,
  physicalIndexTooltipHandler,
} from '../utils/lightweight-chart-utils';
import { isObjectValuesTruthy } from '../utils/object';
import { SixStreamService } from './six-stream.service';

export const PhysicalIndexDataKeyMaps = [
  {
    key: 'Week',
    title: 'Week',
  },
  {
    key: 'SICOM_Price',
    title: 'SICOM Price',
  },
  {
    key: 'SICOM_Agridence_SIR20_Physical_Spread_Index',
    title: 'SICOM Agridence SIR20 Physical Spread Index',
  },
];

@Injectable()
export class PhysicalIndexLogicService {
  /**
   * Subject for terminating component's subscription
   */
  private destroy$ = new Subject<boolean>();
  /**
   * Subject for updating the selected market
   */
  private selectedMarket$ = new ReplaySubject<string>(1);
  /**
   * State determining whether physical index and sicom should
   * be summed up and displayed
   */
  private _sumIndex$ = new BehaviorSubject<boolean>(false);
  /**
   * Subject for storing/updating reference to the latest chartContainer, IChartApi,
   * candlestickSeries, histogramSeries, etc objects retrieved from View
   * Query in the component
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private chartRefs$ = new ReplaySubject<any>(1);
  /**
   * Subject for emitting loading state of the chart
   */
  private chartLoadingSubject = new BehaviorSubject<boolean>(true);
  /**
   * Subject for emitting selected graph range
   */
  private selectedRange$ = new BehaviorSubject<ChartRange>('Year');
  /**
   * Subject for terminating component's subscription
   */
  private physicalIndexDataQuery$ = new BehaviorSubject<
    PhysicalIndexDataPoint[]
  >([]);
  /**
   * Compute the Physical Index chart data for the selected range
   */
  private physicalIndexChartData$ = this.selectedRange$.pipe(
    tap((_) => this.chartLoadingSubject.next(true)),
    switchMap((range) => this.queryPhysicalIndexData(range)),
    map((historicalPhysicalIndexData) => {
      this.physicalIndexDataQuery$.next(historicalPhysicalIndexData);

      return {
        histogram: this.mapPhysicalIndexDataToHistogram(
          historicalPhysicalIndexData,
        ),
      };
    }),
    tap((_) => this.chartLoadingSubject.next(false)),
  );
  /**
   * The chart renderer engine.
   * 1. Combine the physicalIndexChartData$ and chartRefs$ streams
   * 2. Stream is only active until the component is destroyed
   * 3. Filter out the stream if the chartRefs$ is not available
   * 4. Imperatively set the series data
   */
  private chartRenderer$ = combineLatest([
    this.physicalIndexChartData$,
    this.chartRefs$,
  ]).pipe(
    takeUntil(this.destroy$),
    filter(([, chartRefs]) => isObjectValuesTruthy(chartRefs)),
    tap(([chartData, chartRefs]) => {
      const { histogram } = chartData;
      const { histogramSeries } = chartRefs;
      histogramSeries.setData(histogram);
      chartRefs.chart.timeScale().fitContent();
    }),
  );
  /**
   * Retrieve the physical index stream from the Six Stream Service
   */
  get physicalIndex$() {
    return this.sixStreamService.physicalIndex$;
  }

  get physicalIndexData$() {
    return this.physicalIndexDataQuery$;
  }

  get selectedRangeData$() {
    return this.selectedRange$;
  }
  /**
   * The default market select field for Physical Index, because
   * the market selection for Physical Index is different from
   * the market selection for Sicom Stream.
   */
  get marketSelectField$() {
    return of({
      placeholder: 'Market',
      label: 'Market',
      multiple: false,
      options: ['SIR20'],
      selectedOptions: 'SIR20',
      for: 'market',
    } as SelectFieldModel<string>);
  }
  /**
   * The range toggle field stream for Physical Index Display Component
   */
  get rangeToggleField$() {
    return of({
      appearance: 'standard',
      selectedOptions: 'Year',
      options: ['Month', 'Year'],
      name: 'range',
      for: 'range',
    } as ButtonToggleGroupModel<ChartRange>);
  }
  /**
   * Expose the sumIndex$ as a read-only observable
   */
  get sumIndex$() {
    return this._sumIndex$.asObservable();
  }
  /**
   * Expose the chartLoadingSubject as a read-only observable
   */
  get chartLoading$() {
    return this.chartLoadingSubject.asObservable();
  }

  constructor(private sixStreamService: SixStreamService) {}
  /**
   * Initialize the states
   */
  initializeStates() {
    this.marketSelectField$
      .pipe(
        take(1),
        tap((marketSelectField) =>
          this.selectMarket(marketSelectField.selectedOptions as string),
        ),
      )
      .subscribe();
    this.chartRenderer$.subscribe();
  }

  /**
   * Unsubscribe from all subscriptions
   */
  cleanUp() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  /**
   * Update the selected market
   * @param market market name
   */
  selectMarket(market: string) {
    this.selectedMarket$.next(market);
  }
  /**
   * Update the selected range
   * @param range range string
   */
  selectRange(range: ChartRange) {
    this.selectedRange$.next(range);
  }
  /**
   * Update the sumIndex state
   * @param sumIndex boolean value to update the sumIndex state
   */
  toggleSumIndex(sumIndex: boolean) {
    this._sumIndex$.next(sumIndex);
  }
  /**
   * Update the chartRefs
   * @param chartDirective the chart directive retrieved from template reference
   */
  updateChartRefs(chartDirective: ArpLightweightChartDirective) {
    const chartRefs = this.createChartRefs(chartDirective);
    this.chartRefs$.next(chartRefs);
  }

  /**
   * 1. Create the chart object.
   * 2. Add the Histogram series to the chart,
   * and apply necessary options.
   * 3. Return the chart container, chart object and the series objects.
   * @param chartDirective the chart directive retrieved from template reference
   * @returns the chart container, chart object and the series objects
   */
  createChartRefs(chartDirective: ArpLightweightChartDirective) {
    const chart = chartDirective.createChart({
      autoSize: true,
      timeScale: {
        fixLeftEdge: true,
        fixRightEdge: true,
        ticksVisible: true,
      },
      rightPriceScale: {
        ticksVisible: true,
      },
    } as ChartOptions);
    const histogramSeries = chart.addHistogramSeries();
    const chartContainer = chartDirective.getChartContainer();
    const toolTip = createTooltipElement();
    chartContainer.appendChild(toolTip);
    chart.subscribeCrosshairMove((param) =>
      physicalIndexTooltipHandler(
        param,
        toolTip,
        chartContainer,
        histogramSeries,
      ),
    );
    return { chartContainer, chart, histogramSeries };
  }

  /**
   * Query the physical index data, given the range
   * @param range the range of the query
   * @returns an observable wrapping the Physical Index Historical data
   */
  queryPhysicalIndexData(range: ChartRange) {
    const today = new Date();
    const dateto = format(today, Format.apiDateFormat);
    const datefrom = format(
      sub(today, { [`${range.toLowerCase()}s`]: 1 }),
      Format.apiDateFormat,
    );
    return this.sixStreamService.queryPhysicalIndexData(datefrom, dateto);
  }

  /**
   * Map the Physical Index Data to the Histogram Data for charting
   * @param historicalPhysicalIndexData historical physical index data
   * @returns array of HistogramData for charting
   */
  mapPhysicalIndexDataToHistogram(
    historicalPhysicalIndexData: PhysicalIndexDataPoint[],
  ): HistogramData[] {
    return historicalPhysicalIndexData.map((dataPoint) => {
      return {
        time: dataPoint.date,
        value: dataPoint.SICOM_Agridence_SIR20_Physical_Spread_Index,
        color: getHistogramColor(
          dataPoint.SICOM_Agridence_SIR20_Physical_Spread_Index,
        ),
      };
    });
  }
}
