<div class="hc-chat-container">
  <div *ngIf="!showMaximizeChat">
    <button
      mat-fab
      color="chat-icon"
      aria-label="chart icon"
      (click)="onMaximizeClick()">
      <mat-icon
        aria-hidden="false"
        color="primary"
        matBadgeSize="small"
        [matBadgeHidden]="unreadMessageCount === 0"
        matBadgeColor="success"
        [matBadge]="unreadMessageCountDisplay"
        >chat_bubble
      </mat-icon>
    </button>
  </div>
  <div
    *ngIf="showMaximizeChat"
    class="chat-panel"
    [ngClass]="{ maximize: showMaximizeChat, minimize: !showMaximizeChat }">
    <div class="chat-panel-right">
      <ng-container *ngIf="showMaximizeChat">
        <div class="top-layout">
          <div
            class="maximise-icon"
            (click)="onMinimizeClick()"
            (keyup.enter)="onMinimizeClick()"
            tabindex="0">
            <div
              class="svg-icon"
              *ngIf="svgContents['blue-arrow-down']"
              [innerHTML]="svgContents['blue-arrow-down'] | safeHtml"></div>
          </div>
        </div>

        <ng-container *ngIf="selectedMenu === 'discuss'">
          <app-chat-discuss></app-chat-discuss>
        </ng-container>

        <ng-container *ngIf="selectedMenu === 'favorites'">
          <app-chat-favorites></app-chat-favorites>
        </ng-container>

        <ng-container *ngIf="selectedMenu === 'settings'">
          <app-chat-settings></app-chat-settings>
        </ng-container>
      </ng-container>

      <div class="chat-menu-wrapper" *ngIf="showMaximizeChat">
        <div class="chat-menu-content">
          <div
            class="chat-menu-item"
            (click)="onSelectMenu('discuss')"
            (keyup.enter)="onSelectMenu('discuss')"
            tabindex="0">
            <div
              *ngIf="selectedMenu !== 'discuss'"
              class="icon-chat-discuss-inactive"></div>
            <div *ngIf="selectedMenu === 'discuss'">
              <mat-icon
                color="primary"
                aria-hidden="false"
                matBadgeSize="small"
                [matBadgeHidden]="unreadMessageCount === 0"
                matBadgeColor="success"
                [matBadge]="unreadMessageCountDisplay"
                >chat_bubble</mat-icon
              >
              <div class="active-dot-container">
                <div class="active-dot-wrapper">
                  <div class="icon-chat-active-dot"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="chat-menu-item"
            (click)="onSelectMenu('favorites')"
            (keyup.enter)="onSelectMenu('favorites')"
            tabindex="0">
            <div
              *ngIf="selectedMenu !== 'favorites'"
              class="icon-chat-favorites-inactive"></div>
            <div *ngIf="selectedMenu === 'favorites'">
              <div class="icon-chat-favorites-active"></div>
              <div class="active-dot-container">
                <div class="active-dot-wrapper">
                  <div class="icon-chat-active-dot"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="chat-menu-item"
            (click)="onSelectMenu('settings')"
            (keyup.enter)="onSelectMenu('settings')"
            tabindex="0">
            <div
              *ngIf="selectedMenu !== 'settings'"
              class="icon-chat-settings-inactive"></div>
            <div *ngIf="selectedMenu === 'settings'">
              <div class="icon-chat-settings-active"></div>
              <div class="active-dot-container">
                <div class="active-dot-wrapper">
                  <div class="icon-chat-active-dot"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="showMaximizeChat">
      <app-chat-download></app-chat-download>
    </ng-container>
  </div>
  <div class="chat-popup" *ngFor="let room of currentRooms">
    <app-chat-popup [room]="room"></app-chat-popup>
  </div>
</div>
<ng-container *ngIf="showForwardMessage">
  <app-chat-forward-message></app-chat-forward-message>
</ng-container>
