import {
  Component,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SIData } from 'src/app/models/si/response/response';
import { EventEmitter } from '@angular/core';
@Component({
  selector: 'app-si-list',
  templateUrl: './si-list.component.html',
  styleUrls: ['./si-list.component.scss'],
})
export class SiListComponent implements OnChanges {
  search = '';
  siListSearched: SIData[];

  @Input() selectedProducer = '';
  @Input() producers: string[] = [];
  @Input() siList: SIData[];
  @Input() selectedSIData: SIData;
  @Output() selectSIData = new EventEmitter<SIData>();
  @Output() switchSIList = new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges): void {
    this.searchList();
  }

  onSelectSIData(siData: SIData) {
    if (
      this.selectedSIData.si_numb === siData.si_numb &&
      (!this.selectedSIData.factory_cd ||
        !siData.factory_cd ||
        this.selectedSIData.factory_cd === siData.factory_cd)
    ) {
      return;
    }
    this.selectSIData.emit(siData);
  }

  searchList() {
    if (this.siList?.length > 0) {
      this.siListSearched = this.siList.filter((siData: SIData) =>
        !this.search ? true : this.checkSearch(siData),
      );
    }
  }

  onSwitchSIList(value: string) {
    this.switchSIList.emit(value);
  }

  private checkSearch(siData: SIData) {
    const key = siData['buyer_ref_numb'] ? 'buyer_ref_numb' : 'si_numb';

    return !siData[key]
      ? false
      : siData[key]
          .toString()
          .toLowerCase()
          .indexOf(this.search.toLowerCase()) > -1;
  }

  showHighlight(siData: SIData) {
    if (
      this.selectedSIData?.si_numb === siData.si_numb.toUpperCase() &&
      (!this.selectedSIData?.factory_cd ||
        !siData.factory_cd ||
        this.selectedSIData?.factory_cd === siData.factory_cd)
    ) {
      return true;
    } else {
      return false;
    }
  }

  showSINumber(data: SIData) {
    if (!data) return undefined;

    return data['buyer_ref_numb'] ?? data?.si_numb;
  }
}
