import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chip-labels',
  templateUrl: './chip-labels.component.html',
  styleUrls: ['./chip-labels.component.scss'],
})
export class ChipLabelsComponent {
  @Input() label = '';
  @Input() bgColor = '#1446ce';
  @Input() textColor = '#fff';
}
