<div class="bootstrap-wrapper" *ngIf="notification?.message?.message">
  <div
    class="row align-items-center notification py-1"
    (click)="readInNewWindow(); downloadSIFile()"
    (keyup.enter)="readInNewWindow(); downloadSIFile()"
    tabindex="0">
    <div class="col">
      <ng-container
        *ngTemplateOutlet="
          notificationDetails;
          context: { $implicit: notification }
        "></ng-container>
    </div>
    <div class="col-1">
      <span
        *ngIf="!notification.read_at"
        class="blue-dot"
        (click)="readAndAcknowledge($event)"
        (keyup.enter)="readAndAcknowledge($event)"
        tabindex="0"
        matTooltip="Mark as read"
        matTooltipPosition="below"
        matTooltipClass="notification-tooltip">
      </span>
    </div>
  </div>
</div>

<ng-template #notificationDetails let-item>
  <div class="notification-details">
    <div class="image-container mr-1">
      <img
        alt="User profile image"
        *ngIf="item.user.profile_image_url"
        [src]="item.user.profile_image_url" />
    </div>
    <div class="description">
      <h4>{{ item.message.message }}</h4>
      <p class="mt-1 mb-0">
        {{ item.created_at | date: dateFormat }} |
        {{ item.created_at | date: timeFormat }}
      </p>
    </div>
  </div>
</ng-template>
