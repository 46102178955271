import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { enUS } from 'date-fns/locale';
import 'chartjs-adapter-date-fns';
import { CategorizedPriceTime } from 'src/app/models/dashboard-data';
import { format } from 'date-fns';
import { baseColors } from 'ng2-charts';

@Component({
  selector: 'app-price-time-chart',
  templateUrl: './price-time-chart.component.html',
  styleUrls: ['./price-time-chart.component.scss'],
})
export class PriceTimeChartComponent implements OnChanges {
  @Input() dataSource: CategorizedPriceTime;
  chartData: ChartDataset[];
  chartOptions: ChartOptions;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dataSource?.currentValue) {
      this.chartData = this.prepareChartData(changes?.dataSource?.currentValue);
      this.chartOptions = this.prepareChartOptions(
        changes?.dataSource?.currentValue,
      );
    }
  }

  prepareChartData(dataSource: CategorizedPriceTime): ChartDataset[] {
    const chartData: ChartDataset[] = this.extractDataSets(dataSource);
    return chartData;
  }

  prepareChartOptions(dataSource: CategorizedPriceTime): ChartOptions {
    return {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        timeScale: {
          axis: 'x',
          type: 'time',
          time: {
            unit: 'month',
            displayFormats: {
              month: 'MMM yyyy',
            },
            tooltipFormat: 'dd MMM yyyy',
          },
          adapters: {
            date: enUS,
          },
        },
      },
      plugins: {
        legend: {
          align: 'end',
          labels: {
            usePointStyle: true,
            boxWidth: 50,
          },
        },
        tooltip: {
          mode: 'x',
          callbacks: {
            title: function (context) {
              const date = new Date(context[0].parsed.x);
              return format(date, 'dd MMM yyyy');
            },
            label: function (context) {
              const value = context.parsed.y as number;
              return value.toFixed(2);
            },
          },
        },
      },
    };
  }

  extractDataSets(dataSource: CategorizedPriceTime): ChartDataset[] {
    const gradeKeys = Object.keys(dataSource).filter((key) => key !== 'sicom');
    // Remove the first color since it is being used by the line graph
    const colorPool = baseColors.slice(1);
    const gradeDataSets = gradeKeys.map((k, i) => {
      const color = colorPool[i].join(',');
      const gradeData = dataSource[k].map((point) => ({
        x: point.x,
        y: point.y,
      }));
      return {
        data: gradeData,
        label: k,
        type: 'scatter',
        xAxisID: 'timeScale',
        pointRadius: 5,
        backgroundColor: `rgb(${color})`,
        hoverBackgroundColor: '#ffffff',
      } as ChartDataset;
    });

    const sicomDataSet: ChartDataset = {
      data: dataSource.sicom.map((point) => ({
        x: point.x,
        y: point.y,
      })),
      label: 'TSR20',
      type: 'line',
      xAxisID: 'timeScale',
      fill: false,
      pointRadius: 2,
    } as ChartDataset;
    return [sicomDataSet, ...gradeDataSets];
  }
}
