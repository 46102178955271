import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatTimeline } from 'src/app/models/chatroom';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-chat-timeline-calendar',
  templateUrl: './chat-timeline-calendar.component.html',
  styleUrls: ['./chat-timeline-calendar.component.scss'],
})
export class ChatTimelineCalendarComponent implements OnInit {
  @Input() timeline: ChatTimeline;
  @Output() onapply: EventEmitter<any> = new EventEmitter();
  tmpTimeline: ChatTimeline;
  selected = false;
  currentDate: Date;
  endDateTemp: Date;
  clickTo = 'start';
  dateDisplay: Date;
  title: string;
  dayrange: any[];
  constructor(private chatService: ChatService) {}

  ngOnInit(): void {
    this.tmpTimeline = { ...this.timeline };
    this.setupDate();
    this.setCalendar();
    this.setTimline();
  }

  private setupDate(): void {
    this.currentDate = !this.currentDate ? new Date() : this.currentDate;
    this.dateDisplay = this.currentDate;
  }

  private setCalendar() {
    const dateString = this.dateDisplay.toString();
    const displayDay = this.dateDisplay.getDate();
    const lastDayOfMonth = this.getLastDay();
    const lastDayNumber = lastDayOfMonth.getDate();
    for (let i = 1; i <= lastDayNumber; i += 7) {
      if (i <= displayDay && i + 7 > displayDay) {
        this.setDateRange(i, i + 7, lastDayNumber);
      }
    }
    this.title = `${dateString.substr(4, 3)} ${dateString.substr(11, 4)}`;
  }

  private setDateRange(start: number, end: number, lastDay: number) {
    this.dayrange = [];
    for (; start < end; start++) {
      if (start <= lastDay) {
        this.dayrange.push({
          label: ('' + start).padStart(2, '0'),
          date: new Date(
            this.dateDisplay.getFullYear(),
            this.dateDisplay.getMonth(),
            start,
          ),
        });
      } else {
        this.dayrange.push({ label: '', date: undefined });
      }
    }
  }

  public prevMonth() {
    this.dateDisplay = this.addMonth(-1);
    this.setCalendar();
  }

  public nextMonth() {
    this.dateDisplay = this.addMonth(1);
    this.setCalendar();
  }

  public prevDaysSet() {
    const firstDay = this.addMonth(0);
    const prevDate = this.addDays(-7);
    this.dateDisplay = firstDay >= prevDate ? firstDay : prevDate;
    this.setCalendar();
  }

  public nextDaysSet() {
    const lastDay = this.getLastDay();
    const nextDate = this.addDays(7);
    this.dateDisplay = nextDate <= lastDay ? nextDate : lastDay;
    this.setCalendar();
  }

  public getLastDay() {
    return new Date(
      this.dateDisplay.getFullYear(),
      this.dateDisplay.getMonth() + 1,
      0,
    );
  }

  public addMonth(nums: number) {
    return new Date(
      this.dateDisplay.getFullYear(),
      this.dateDisplay.getMonth() + nums,
      1,
    );
  }

  public addDays(nums: number) {
    return new Date(
      this.dateDisplay.getFullYear(),
      this.dateDisplay.getMonth(),
      this.dateDisplay.getDate() + nums,
    );
  }

  public selectDate(dateObj: any) {
    if (dateObj.label !== '') {
      this.tmpTimeline.timeline = '';
      this.currentDate = dateObj.date;
      if (this.clickTo === 'start') {
        this.clickTo = 'end';
        this.tmpTimeline.start_dt = this.currentDate;
        this.tmpTimeline.end_dt = this.currentDate;
      } else {
        this.clickTo = 'start';
        this.tmpTimeline.end_dt = this.currentDate;
        if (this.tmpTimeline.end_dt < this.tmpTimeline.start_dt) {
          const start = this.tmpTimeline.end_dt;
          this.tmpTimeline.end_dt = this.tmpTimeline.start_dt;
          this.tmpTimeline.start_dt = start;
        }
      }
      this.setTimline();
    }
  }

  public selectedStyle(dateObj: any) {
    let styleClass = '';
    if (dateObj.date) {
      if (
        this.tmpTimeline.start_dt <= dateObj.date &&
        dateObj.date <= this.tmpTimeline.end_dt
      ) {
        if (this.selected) {
          styleClass = 'circle-active';
        } else {
          styleClass = 'circle-border';
        }
      }
      styleClass = this.loadForEnd(styleClass, dateObj);
    }
    return styleClass;
  }

  loadForEnd(styleClass, dateObj) {
    if (this.clickTo === 'end' && !!this.endDateTemp) {
      if (this.endDateTemp >= this.tmpTimeline.start_dt) {
        styleClass +=
          this.tmpTimeline.start_dt <= dateObj.date &&
          dateObj.date <= this.endDateTemp
            ? ' circle-hover'
            : '';
      } else {
        styleClass +=
          this.endDateTemp <= dateObj.date &&
          dateObj.date <= this.tmpTimeline.start_dt
            ? ' circle-hover'
            : '';
      }
    }
    return styleClass;
  }

  changeAllDay(event: any) {
    if (event.target.checked) {
      this.selected = false;
      this.tmpTimeline.timeline = 'Full Chat';
    } else {
      this.tmpTimeline.timeline = '';
    }
    this.setTimline();
  }

  setTimline() {
    if (
      this.tmpTimeline.timeline !== undefined &&
      this.tmpTimeline.timeline !== 'Full Chat'
    ) {
      this.selected = true;
      this.tmpTimeline.timeline =
        this.chatService.dateFormatShort(this.tmpTimeline.start_dt) +
        ' - ' +
        this.chatService.dateFormatShort(this.tmpTimeline.end_dt);
    }
  }

  overDate(dayObj: any) {
    if (this.clickTo === 'end') {
      this.endDateTemp = dayObj.date;
    }
  }

  leaveDate(dayObj: any) {
    this.endDateTemp = null;
  }

  apply() {
    this.onapply.emit(this.tmpTimeline);
  }
}
