<div class="form-setup-container">
  <div>{{ "PASSWORD_SETUP.ENSURE_NEW_PASSWORD_INCLUDES" | transloco }}:</div>
  <ul>
    <li>
      <mat-icon
        aria-hidden="false"
        class="material-icons-outlined"
        [class.valid]="isPasswordLengthEnough"
        >{{ isPasswordLengthEnough ? "check" : "close" }}</mat-icon
      ><span>8 {{ "PASSWORD_SETUP.CHARACTERS" | transloco }}</span>
    </li>
    <li>
      <mat-icon
        aria-hidden="false"
        class="material-icons-outlined"
        [class.valid]="isPasswordHasLetter"
        >{{ isPasswordHasLetter ? "check" : "close" }}</mat-icon
      ><span>1 {{ "PASSWORD_SETUP.LETTER" | transloco }}</span>
    </li>
    <li>
      <mat-icon
        aria-hidden="false"
        class="material-icons-outlined"
        [class.valid]="isPasswordHasNumber"
        >{{ isPasswordHasNumber ? "check" : "close" }}</mat-icon
      ><span>1 {{ "PASSWORD_SETUP.NUMBER" | transloco }}</span>
    </li>
    <li>
      <mat-icon
        aria-hidden="false"
        class="material-icons-outlined"
        [class.valid]="isPasswordMatch"
        >{{ isPasswordMatch ? "check" : "close" }}</mat-icon
      ><span>{{ "PASSWORD_SETUP.PASSWORD_MATCH" | transloco }}</span>
    </li>
  </ul>

  <div class="input-fields">
    <input
      type="{{ passwordMask ? 'password' : 'text' }}"
      name="password"
      id="password"
      [(ngModel)]="password"
      (keyup)="validatePassword()"
      class="hc-password-input"
      [placeholder]="'PASSWORD_SETUP.INSERT_PASSWORD' | transloco" />
    <mat-icon
      aria-hidden="false"
      class="material-icons-outlined"
      (click)="togglePasswordMask()"
      >{{ passwordMask ? "visibility_off" : "visibility" }}</mat-icon
    >
  </div>
  <div class="input-fields">
    <input
      type="{{ passwordConfirmMask ? 'password' : 'text' }}"
      name="password_confirmation"
      [(ngModel)]="passwordConfirmation"
      (keyup)="validatePasswordMatch()"
      class="hc-password-input"
      [placeholder]="'PASSWORD_SETUP.CONFIRM_PASSWORD' | transloco" />
    <mat-icon
      aria-hidden="false"
      class="material-icons-outlined"
      (click)="togglePasswordConfirmMask()"
      >{{ passwordConfirmMask ? "visibility_off" : "visibility" }}</mat-icon
    >
  </div>

  <button
    mat-button
    type="submit"
    class="hc-save-password-button"
    [disabled]="hasMissingField()"
    (click)="saveNewPasswordClick()">
    {{ "PASSWORD_SETUP.SAVE_PASSWORD" | transloco }}
  </button>
</div>
