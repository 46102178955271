function defaultAscendingSort(a: any[], b: any[]): number {
  return a[0] > b[0] ? 1 : a[0] < b[0] ? -1 : 0;
}

/**
 * Use this function to retrieve a sorted array of an Object's properties. This is useful when data
 * returned from an API is an object whose keys need to be in chronological order, for example.
 * This is needed because the default Object.entries() or Object.keys() will auto sort the object properties
 * in some arbitrary manner that does not comply with business logic.
 * @param object object to retrieve sorted key-value pairs from.
 * @param sortFn optional custom sortFn provided by user of this function
 * @returns a sorted array of key-value pairs of an object. Sort by the keys of the object in ascending order if sortFn is NOT provided,
 */
export function getSortedObjectProperties(
  object: any,
  sortFn: (a, b) => number = defaultAscendingSort,
): { key: string; value: any }[] {
  return Object.entries(object)
    .sort(sortFn)
    .map(([key, value]) => ({ key: key, value: value }));
}
