<div class="landing-page">
  <!-- header -->
  <div class="header">
    <div class="company-logo">
      <img src="./../../../assets/images/logo.svg" alt="" />
    </div>
    <div class="email-bubble">
      <!-- ask PO website link confirm -->
      <a href="https://www.agridence.com/" target="”_blank”"
        >Go to Agridence Website</a
      >
    </div>
  </div>
  <!-- Container -->
  <div class="container" *ngIf="displayStep <= 3">
    <div class="image-container">
      <img
        *ngIf="displayStep === 1"
        src="./../../../assets/images/email-landing-page.svg"
        alt="" />
      <img
        *ngIf="displayStep === 2"
        src="./../../../assets/images/email-landing-page-two.svg"
        alt="" />
      <img
        *ngIf="displayStep === 3"
        src="./../../../assets/images/email-landing-page-three.svg"
        alt="" />
    </div>
    <div class="email-container">
      <h1>Verify your e-mail address</h1>
      <p>
        Verify your email to make sure only you can access the contract
        information - in just two quick steps
      </p>
      <!-- Steps Container -->
      <div class="step-container">
        <!-- Step 1 -->
        <div *ngIf="displayStep === 1">
          <h2>
            Step <span class="highlight-text">1</span>
            <span class="gray-text"> / 2</span>
          </h2>
          <p>Enter the email address that you want to verify and hit send</p>
          <input
            type="email"
            class="hc-email-input"
            placeholder="name@domain.com"
            [(ngModel)]="email"
            name="email"
            id="email"
            (change)="validateEmail()" />
          <span class="error" *ngIf="errorMessage">{{ errorMessage }}</span>
          <button
            mat-button
            type="submit"
            class="send-button"
            [disabled]="!isEmailValid"
            (click)="sendOTPCode()">
            Send Code
          </button>
        </div>
        <!-- Step 2 -->
        <div *ngIf="displayStep === 2">
          <h2>
            Step <span class="highlight-text">2</span>
            <span class="gray-text"> / 2</span>
          </h2>
          <p>
            We want to make sure it’s really you. In order to further identify,
            enter the verification code that was sent to
            <span class="highlight-text">{{ email }}</span>
          </p>
          <input
            type="number"
            class="hc-number-input"
            [(ngModel)]="pincode"
            maxlength="6"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            (change)="validateCode()" />
          <span class="error" *ngIf="errorMessage">{{ errorMessage }}</span>
          <div>
            <button
              mat-button
              type="submit"
              class="send-button"
              [disabled]="!isCodeValid"
              (click)="getContract()">
              Confirm
            </button>
            <button
              mat-button
              type="button"
              id="hc-button-no-border"
              (click)="sendOTPCode()">
              Resend Code
            </button>
          </div>
        </div>
        <div *ngIf="displayStep === 3">
          <h2 class="title-green">Your Email was confirmed!</h2>
          <p>You will be transferred to the Contract page in a moment.</p>
          <div>
            <button
              mat-button
              type="submit"
              class="send-button"
              (click)="showContract()">
              See Contract
            </button>
            <button
              mat-button
              type="button"
              id="hc-button-no-border"
              (click)="goBack()">
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="contact-container" *ngIf="displayStep === 4">
    <div class="title-contatiner">
      <div>
        <h1>Contract</h1>
        {{ contract.contract_number }}
      </div>
      <div>
        <a href="{{ contract.contract_url }}"
          ><button mat-button id="hc-button-primary">Download</button></a
        >
      </div>
    </div>

    <div class="contract-detail-list">
      <div class="contract-detail-list-content">
        <h4 class="title">Consumer</h4>
        <h3 class="text">{{ contract.consumer.name }}</h3>
      </div>
      <div class="contract-detail-list-content">
        <h4 class="title">Buyer</h4>
        <h3 class="text">{{ contract.buyer.name }}</h3>
      </div>
      <div class="contract-detail-list-content">
        <h4 class="title">Producer</h4>
        <h3 class="text">{{ contract.producer.name }}</h3>
      </div>
    </div>
    <div class="contract-detail-list">
      <div class="contract-detail-list-content">
        <h4 class="title">Seller</h4>
        <h3 class="text">{{ contract.seller ? contract.seller.name : "-" }}</h3>
      </div>
      <div class="contract-detail-list-content">
        <h4 class="title">Buyer</h4>
        <h3 class="text">{{ contract.buyer ? contract.buyer.name : "-" }}</h3>
      </div>
      <div class="contract-detail-list-content">
        <h4 class="title">Type</h4>
        <h3 class="text">{{ contract.type }}</h3>
      </div>
      <div class="contract-detail-list-content">
        <h4 class="title">Destination</h4>
        <h3 class="text">
          {{ contract.destination ? contract.destination.name : "-" }}
        </h3>
      </div>
    </div>
    <div class="contract-detail-list">
      <div class="contract-detail-list-content">
        <h4 class="title">Delivery date</h4>
        <h3 class="text">{{ contract.delivery_date | date: monthFormat }}</h3>
      </div>
      <div class="contract-detail-list-content">
        <h4 class="title">Price</h4>
        <h3 class="text">{{ contract.price | price }} USC/Kg</h3>
      </div>
      <div class="contract-detail-list-content">
        <h4 class="title">Volume</h4>
        <h3 class="text">{{ contract.volume | number: "1.2-2" }} MT</h3>
      </div>
      <div class="contract-detail-list-content">
        <h4 class="title">Shipping</h4>
        <h3 class="text">{{ contract.shipping.name }}</h3>
      </div>
    </div>
    <div class="contract-detail-list">
      <div class="contract-detail-list-content">
        <h4 class="title">Factory</h4>
        <h3 class="text">{{ contract.factory.name }}</h3>
      </div>
      <div class="contract-detail-list-content">
        <h4 class="title">Grade</h4>
        <h3 class="text">{{ contract.grade.name }}</h3>
      </div>
      <div class="contract-detail-list-content">
        <h4 class="title">Packing</h4>
        <h3 class="text">{{ contract.packing.name }}</h3>
      </div>
      <div class="contract-detail-list-content">
        <h4 class="title">Payment term</h4>
        <h3 class="text">{{ contract.payment_term.name }}</h3>
      </div>
    </div>
    <div class="contract-detail-list">
      <div class="contract-detail-list-content">
        <h4 class="title">Port</h4>
        <h3 class="text">{{ contract.port.name }}</h3>
      </div>
      <div class="contract-detail-list-content">
        <h4 class="title">Factory</h4>
        <h3 class="text">{{ contract.factory.name }}</h3>
      </div>
      <div class="contract-detail-list-content">
        <h4 class="title">Grade</h4>
        <h3 class="text">{{ contract.grade.name }}</h3>
      </div>
      <div class="contract-detail-list-content">
        <h4 class="title">Packing</h4>
        <h3 class="text">{{ contract.packing.name }}</h3>
      </div>
    </div>
    <div class="contract-detail-list">
      <div class="contract-detail-list-content">
        <h4 class="title">Remarks</h4>
        <h3 class="text">{{ contract.remarks }}</h3>
      </div>
    </div>
    <div>
      <button
        mat-button
        type="button"
        id="hc-button-no-border"
        (click)="goBack()">
        Go Back
      </button>
    </div>
  </div>
</div>
