import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NonHcService } from '../../services/non-hc.service';

import errorMessage from '../../../assets/content/error/error-message.json';
import Format from 'src/assets/content/format/date-time-format.json';
@Component({
  selector: 'app-non-hc-user',
  templateUrl: './non-hc-user.component.html',
  styleUrls: ['./non-hc-user.component.scss'],
})
export class NonHcUserComponent implements OnInit {
  email: string;
  token: string;
  inlineError = errorMessage;
  errorMessage: string;
  isEmailValid: boolean;
  displayStep: number;
  isCodeValid: boolean;
  pincode: any;
  contract: any;

  monthFormat = Format.monthFormat;

  constructor(
    private route: ActivatedRoute,
    private httpService: NonHcService,
  ) {
    this.route.params.subscribe((params) => this.assignToken(params['token']));
  }

  assignToken(token: string) {
    this.token = token;
  }

  ngOnInit(): void {
    this.isEmailValid = false;
    this.isCodeValid = false;
    this.displayStep = 1; // display step one
    // this.pincode = new Array(6);
  }

  checkEmailValid(email: string): boolean {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email,
    );
  }

  checkEmailMatches(email: string): boolean {
    try {
      const data = JSON.parse(atob(this.token.split('-')[0]));
      if ('email' in data) {
        if (data['email'] === email) {
          this.errorMessage = '';
          return true;
        } else {
          this.errorMessage = errorMessage.inline.username;
          return false;
        }
      }
    } catch {
      this.errorMessage = errorMessage.inline.username;
    }
    return false;
  }

  validateEmail(): void {
    if (
      this.email &&
      this.checkEmailValid(this.email) &&
      this.checkEmailMatches(this.email)
    ) {
      this.isEmailValid = true;
    } else {
      this.isEmailValid = false;
    }
  }

  validateCode(): void {
    const count = String(this.pincode).length;
    if (count === 6) {
      this.errorMessage = '';
      this.isCodeValid = true;
    } else {
      this.errorMessage = this.inlineError.inline.InvalidOTP;
      this.isCodeValid = false;
    }
  }

  sendOTPCode(): void {
    this.httpService.requestOTP(this.token).subscribe((result) => {
      this.displayStep = 2;
    });
  }

  getContract(): void {
    this.httpService.getContract(this.token, this.pincode).subscribe({
      next: (result) => {
        this.displayStep = 3;
        this.contract = result;
        setTimeout(() => {
          this.showContract();
        }, 2000);
      },
      error: (error) => {
        this.pincode = null; // clear all pincode
      },
    });
  }

  goBack(): void {
    this.contract = undefined;
    this.email = '';
    this.ngOnInit();
  }

  showContract(): void {
    this.displayStep = 4;
  }
}
