import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { createChart, IChartApi, ChartOptions } from 'lightweight-charts';

@Directive({
  selector: '[arpLightweightChart]',
})
export class ArpLightweightChartDirective implements OnDestroy {
  /**
   * Chart instance attached to the HTML element on which
   * this directive is placed
   */
  private lightweightChart: IChartApi;

  constructor(private elRef: ElementRef) {}

  ngOnDestroy(): void {
    this.removeChart();
  }

  /**
   * Create a new chart instance and attach it to the HTML element on which
   * this directive is placed, only if an instance does not already exist
   * @param lightweightChartOptions the options to use when creating the chart
   * @returns an instance of the lightweight chart
   */
  createChart(lightweightChartOptions: ChartOptions): IChartApi {
    if (!this.lightweightChart) {
      this.lightweightChart = createChart(
        this.elRef.nativeElement,
        lightweightChartOptions,
      );
    }
    return this.lightweightChart;
  }

  /**
   * Get the HTML element on which this directive is placed, which
   * is basically the chart container
   * @returns the HTML element on which this directive is placed
   */
  getChartContainer(): HTMLElement {
    return this.elRef.nativeElement as HTMLElement;
  }

  /**
   * Remove the chart and dereference the instance
   */
  removeChart(): void {
    if (this.lightweightChart) {
      this.lightweightChart.remove();
      this.lightweightChart = null;
    }
  }
}
