<div class="hc-breadcrumb">
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let isLast = last">
    <span
      [ngClass]="{ 'sub-page': isLast === false }"
      [routerLink]="isLast ? null : breadcrumb.url">
      {{ "BREADCRUMB." + breadcrumb.label | transloco }}
    </span>
    <mat-icon *ngIf="isLast === false">chevron_right</mat-icon>
  </ng-container>
</div>
