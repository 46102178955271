<div class="panel-container">
  <div class="bootstrap-wrapper">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col">
          <div class="inline-container">
            <div class="selected-count">
              {{ selectedCount }} {{ "COMMON.SELECTED" | transloco }}
            </div>
            <div class="right-group">
              <button
                *ngIf="showButton('ok')"
                mat-button
                id="hc-button-accept"
                [disabled]="isButtonDisabled()"
                (click)="emitOk()">
                <mat-icon>done</mat-icon>
                {{ getText("ok") }} {{ "COMMON.SELECTED" | transloco }}
              </button>
              <button
                *ngIf="showButton('reject')"
                mat-button
                id="hc-button-decline"
                [disabled]="isButtonDisabled()"
                (click)="emitReject()">
                <mat-icon>cancel</mat-icon>
                {{ getText("reject") }} {{ "COMMON.SELECTED" | transloco }}
              </button>
              <button
                *ngIf="showCancelSelectedButton()"
                mat-button
                id="hc-button-decline"
                [disabled]="isButtonDisabled()"
                (click)="emitCancel()">
                <mat-icon>cancel</mat-icon>
                {{ getText("cancel") }} {{ "COMMON.SELECTED" | transloco }}
              </button>
              <button
                mat-button
                id="hc-button-primary"
                *ngIf="showButton('download')"
                [matMenuTriggerFor]="menu"
                [disabled]="selectedCount === 0">
                <mat-icon fontSet="material-icons-outlined"
                  >file_download</mat-icon
                >
                {{ getText("download") }} {{ "COMMON.SELECTED" | transloco }}
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="emitDownload('pdf')">PDF</button>
                <button mat-menu-item (click)="emitDownload('csv')">CSV</button>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
