import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Message } from '../message';

export class ServerExceptionMessage extends Message {
  getMessage(content: HttpErrorResponse | string): Message {
    this.icon = 'error';
    this.color = 'info';
    this.type = HttpStatusCode.InternalServerError;
    this.content = this.processMessage((content as HttpErrorResponse).url);
    return this;
  }
  processMessage(url: string) {
    const exceptionMessages = [
      {
        url: `${environment.BASE_URL}sicom/last-prices/tsr20`,
        message: `Unable to retrieve SICOM reference price.
        Please contact helpdesk@agridence.com.
        In the meantime, you may set the base price manually.`,
      },
      {
        url: `${environment.BASE_URL}sicom/last-prices/rss`,
        message: `Unable to retrieve SICOM reference price.
        Please contact helpdesk@agridence.com.
        In the meantime, you may set the base price manually.`,
      },
      {
        url: `${environment.BASE_URL}factory-emission-intensity`,
        message: `Unable to retrieve factory emission data`,
      },
      {
        url: `${environment.BASE_URL}factory-energy`,
        message: `Unable to retrieve factory energy data`,
      },
    ];
    const content = exceptionMessages.find((p) => url.includes(p.url));

    return content?.message !== undefined
      ? content.message
      : 'Unknow Server Exception';
  }

  close(): void {
    // Close method
  }

  action(): void {
    // Action method
  }
}
