<div class="tou-viewer">
  <h2>{{ "COMMON.PLATFORM_TERMS_OF_USE" | transloco }}</h2>
  <embed
    width="100%"
    height="75%"
    [src]="termsOfUseUrl"
    type="application/pdf" />
  <div class="button-container">
    <button
      mat-button
      class="hc-button"
      id="hc-button-accept"
      (click)="close()">
      {{ "COMMON.CLOSE" | transloco }}
    </button>
  </div>
</div>
