import { Inject, Injectable, Optional } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CUSTOM_ICONS, Icon } from '../injection-tokens/injection-tokens';

/**
 * This service is used to register custom icons to
 * the MatIconRegistry. It is injected in one of the
 * APP_INITIALIZERS to do the registration before
 * application starts.
 */
@Injectable({ providedIn: 'root' })
export class IconService {
  /**
   * Internal array to store the list of custom icons to be registered
   **/
  icons: Icon[];
  /**
   * Constructor for the IconService
   * @param domSanitizer required to mark icon's path safe for use
   * in html
   * @param matIconRegistry the icon registry that is used to register
   * the custom icons
   * @param customIcons an array of Icons, should be provided by the
   * default CUSTOM_ICONS injection token. If not provided, the
   * icons registration will be skipped.
   */
  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    @Inject(CUSTOM_ICONS) @Optional() customIcons: Icon[],
  ) {
    this.icons = customIcons;
  }

  /**
   * Register the custom icons stored in the internal icons array
   * to the MatIconRegistry. Bypassed if no custom icons are provided.
   */
  registerIcons() {
    if (this.icons) {
      this.icons.forEach((icon) => {
        this.matIconRegistry.addSvgIcon(
          icon.name,
          this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path), // nosonar
        );
      });
    }
  }
}
