<div class="arp-physical-index-display-container">
  <div class="bootstrap-wrapper">
    <div class="container-fluid">
      <div class="row row-gap">
        <div class="col-md-5 col-sm-12">
          <div class="row mb-2">
            <div class="col-xl-4 col-5">
              <ng-container
                *ngIf="marketSelectField$ | async as marketSelectField">
                <mat-form-field
                  class="arp-mat-form-field-outline full-width"
                  appearance="outline">
                  <mat-label>{{ marketSelectField.label }}</mat-label>
                  <mat-select
                    [placeholder]="marketSelectField.placeholder"
                    [value]="marketSelectField.selectedOptions"
                    (valueChange)="onSelectMarket($event)"
                    disabled>
                    <mat-option
                      *ngFor="let option of marketSelectField.options"
                      [value]="option"
                      >{{ option }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>
            </div>
          </div>
          <div class="row row-gap align-center">
            <ng-container *ngIf="physicalIndex$ | async as physicalIndex">
              <div class="col-12">
                <div class="expanded-display mb-1">
                  <span class="small-font mr-1"> SICOM Price: </span>
                  <span class="small-font text-blue">
                    {{ physicalIndex.sicom_price | number: "1.2-2" }}
                  </span>
                </div>
                <div class="expanded-display">
                  <span class="normal-font mr-1"> Physical Index: </span>
                  <span class="large-font emphasized text-blue">
                    {{ physicalIndex.physical_price_index | number: "1.2-2" }}
                  </span>
                </div>
                <div class="expanded-display">
                  <span>Last updated: {{ physicalIndex.week }}</span>
                </div>
                <mat-slide-toggle
                  [checked]="sumIndex$ | async"
                  (change)="onToggleSumIndex($event)">
                  Calculate SICOM + Physical Index
                </mat-slide-toggle>
                <ng-container *ngIf="(sumIndex$ | async) === true">
                  <div class="expanded-display">
                    <span class="normal-font mr-1">
                      SICOM + Physical Index:
                    </span>
                    <span class="normal-font text-blue">
                      {{
                        physicalIndex.sicom_price +
                          physicalIndex.physical_price_index | number: "1.2-2"
                      }}
                    </span>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-md-7 col-sm-12 chart-container">
          <ng-container *ngIf="chartLoading$ | async">
            <div class="local-loader">
              <app-arp-loader></app-arp-loader>
            </div>
          </ng-container>
          <div class="range-picker">
            <ng-container *ngIf="rangeToggleField$ | async as rangeToggleField">
              <mat-button-toggle-group
                class="arp-mat-button-toggle-group"
                [name]="rangeToggleField.name"
                aria-label="range"
                [appearance]="rangeToggleField.appearance"
                [value]="rangeToggleField.selectedOptions"
                (change)="onSelectRange($event)">
                <mat-button-toggle
                  *ngFor="let option of rangeToggleField.options"
                  [value]="option"
                  >{{ option }}
                </mat-button-toggle>
              </mat-button-toggle-group>

              <button
                mat-button
                class="hc-button download"
                id="hc-button-primary"
                (click)="onDownload()">
                Download
              </button>
            </ng-container>
          </div>
          <div class="chart-area">
            <div class="chart" arpLightweightChart></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
