import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// URL JSON
import UserSettingsURL from '../../assets/content/services/userSettings-service.json';
// SERVICES
import { CompanyAccount, CompanyAccounts } from '../models/company-account';
import { SecureToken } from '../models/secure-token';
import { SHOW_LOADER } from '../interceptors/interceptor-context';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  protected serviceURL = UserSettingsURL;
  protected readonly httpOptions = {
    headers: new HttpHeaders({}),
    body: {},
    params: new HttpParams(),
    context: new HttpContext(),
  };
  constructor(protected http: HttpClient) {}

  setGlobalLoadingContext(globalLoading = true) {
    this.httpOptions.context = new HttpContext().set(
      SHOW_LOADER,
      globalLoading,
    );
  }

  getUserInfo(): Observable<any> {
    return this.http.get<any>(this.serviceURL.userInfoURL, this.httpOptions);
  }

  patchUserInfo(data, secureToken): Observable<any> {
    const option = { ...this.httpOptions };
    const tokenHeader = new HttpHeaders().set('Secure-Token', secureToken);
    option.headers = tokenHeader;
    return this.http.patch<any>(this.serviceURL.userInfoURL, data, option);
  }

  getUserSettings(): Observable<any> {
    return this.http.get<any>(
      this.serviceURL.userSettingsURL,
      this.httpOptions,
    );
  }
  patchUserSettings(data): Observable<any> {
    return this.http.patch<any>(
      this.serviceURL.userSettingsURL,
      data,
      this.httpOptions,
    );
  }

  postChangePassword(data): Observable<any> {
    return this.http
      .post<any>(this.serviceURL.changePasswordURL, data, this.httpOptions)
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        }),
      );
  }

  getTermsOfUse(): Observable<any> {
    return this.http.get<any>(this.serviceURL.termsOfUse, this.httpOptions);
  }

  getTermsOfUseAcceptance(): Observable<any> {
    return this.http
      .get<any>(this.serviceURL.termsOfUseAcceptance, this.httpOptions)
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        }),
      );
  }

  postSecureActionToken(password: string): Observable<SecureToken> {
    return this.http.post<SecureToken>(
      this.serviceURL.secureActionTokenURL,
      { password },
      this.httpOptions,
    );
  }

  postTermsOfUseAcceptance(data): Observable<any> {
    return this.http.post<any>(
      this.serviceURL.termsOfUseAcceptance,
      data,
      this.httpOptions,
    );
  }

  updateProfileImage(imageName: string, data: FormData): Observable<any> {
    const option = { ...this.httpOptions };
    return this.http.put<any>(
      `${this.serviceURL.userImageUploadURL}${imageName}`,
      data,
      option,
    );
  }

  deleteProfileImage(imageName: string): Observable<any> {
    const option = { ...this.httpOptions };
    return this.http.delete<any>(
      `${this.serviceURL.userImageUploadURL}${imageName}`,
      option,
    );
  }

  getCompanyAccounts(): Observable<CompanyAccounts> {
    const option = { ...this.httpOptions };
    return this.http.get<any>(this.serviceURL.companyAccounts, option);
  }

  patchCompanyAccount(data: CompanyAccount): Observable<any> {
    const option = { ...this.httpOptions };
    return this.http.patch<any>(
      `${this.serviceURL.companyAccounts}${data.id}/`,
      data,
      option,
    );
  }
}
