<input
  class="hc-input {{ errorMessage ? 'invalid' : '' }}"
  [disabled]="disabled"
  type="number"
  [placeholder]="placeholder ? placeholder.toPrice() : ''"
  #hcInput
  (mousedown)="onInputMouseDown($event)"
  (change)="onInputChange($event)"
  (input)="onInputPressed()" />
<div *ngIf="errorMessage">
  <span class="input-error">{{ errorMessage }}</span>
</div>
