import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef,
  MAT_LEGACY_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ModalData } from 'src/app/models/modal-data';
import { SimpleModalUserChoices } from 'src/app/models/modal-user-choices';

@Component({
  selector: 'app-single-text-modal',
  templateUrl: './single-text-modal.component.html',
  styleUrls: ['./single-text-modal.component.scss'],
})
export class SingleTextModalComponent implements OnInit {
  modalRef: MatLegacyDialogRef<SingleTextModalComponent>;
  modalData: ModalData;
  simpleModalUserChoices = SimpleModalUserChoices;
  constructor(
    private singleTextModalRef: MatLegacyDialogRef<SingleTextModalComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) private data: ModalData,
  ) {}

  ngOnInit(): void {
    this.modalRef = this.singleTextModalRef;
    this.modalData = this.data;
  }

  onCloseClick() {
    this.modalRef.close();
  }
}
