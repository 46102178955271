<div class="panel-container">
  <div class="bootstrap-wrapper">
    <div class="container-fluid">
      <div class="row row-gap">
        <div class="col-lg-3 col-md-6" *ngFor="let inputField of inputFields">
          <mat-form-field
            class="arp-mat-form-field-outline full-width"
            appearance="outline">
            <mat-label>{{ inputField.label | transloco }}</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input
              #inputField
              matInput
              [placeholder]="inputField.placeholder | transloco"
              [type]="inputField.inputType"
              [(ngModel)]="inputField.inputString" />
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-6" *ngFor="let selectField of selectFields">
          <mat-form-field
            class="arp-mat-form-field-outline full-width"
            appearance="outline">
            <mat-label>{{ selectField.label | transloco }}</mat-label>
            <mat-select
              [multiple]="selectField.multiple"
              [placeholder]="selectField.placeholder | transloco"
              [(value)]="selectField.selectedOptions"
              (openedChange)="handleSelectOpenedChange($event)">
              <mat-select-trigger
                *ngIf="
                  selectField.multiple &&
                  selectField.selectedOptions.length >= 1
                ">
                {{ selectField.selectedOptions[0].displayName | transloco }}
                <span class="arp-mat-select-trigger-count">
                  {{ selectField.selectedOptions.length }}
                </span>
              </mat-select-trigger>
              <mat-option *ngIf="!selectField.multiple">{{
                "None" | transloco
              }}</mat-option>
              <mat-option
                [value]="option"
                *ngFor="let option of selectField.options">
                {{ option?.displayName | transloco }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
