<div
  *ngIf="manufacturer"
  [ngClass]="{
    'conti-form': manufacturer === 'CONTINENTAL',
    'pirelli-form': manufacturer === 'PIRELLI'
  }">
  <mat-toolbar>
    <app-breadcrumb></app-breadcrumb>
    <div class="hc-top-menu">
      <div
        class="profile"
        (click)="onProfileClick()"
        (keyup.enter)="onProfileClick()"
        tabindex="0">
        <img [src]="imageSource" class="icon-profile" alt="" />
        <div>
          <h3 class="profile-name">
            {{ profileName }}
            <span class="icon-x-small">
              <mat-icon
                [svgIcon]="showProfileDropDown ? 'arrow-up' : 'arrow-down'"
                inline></mat-icon>
            </span>
          </h3>
          <h4 class="company-name">{{ companyName }}</h4>
          <div class="drop-box dropbox-profile" *ngIf="showProfileDropDown">
            <div>
              <h4
                (click)="onLogoutClick()"
                (keyup.enter)="onLogoutClick()"
                tabindex="0"
                class="logout">
                <span class="icon-medium">
                  <mat-icon inline>logout</mat-icon> </span
                >{{ "COMMON.SIGN_OUT" | transloco }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar>

  <div class="main-content">
    <div class="form-outer-container">
      <h2>{{ title }}</h2>
      <ng-container *ngFor="let sentence of notices">
        <div class="warning">
          <mat-icon>error</mat-icon>
          <span>
            {{ sentence }}
          </span>
        </div>
      </ng-container>

      <div class="form-container">
        <div #paperForm *ngIf="showGPSNRForm" class="form"></div>

        <div *ngIf="showGPSNRForm === false" class="already-submitted">
          <mat-icon class="icon icon-x-large">check_circle</mat-icon>
          <h3>{{ "COMMON.THANKS_FOR_SUBMISSION" | transloco }}</h3>
          <span>
            {{ "COMMON.YOU_HAVE_ALREADY_SUBMITTED_REQUIREMENTS" | transloco }}
          </span>
          <button
            mat-raised-button
            id="hc-button-primary"
            class="download-button"
            (click)="downloadSubmittedData()">
            {{ "COMMON.DOWNLOAD_QUESTIONNAIRE_REPORT" | transloco }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
