import { BehaviorSubject } from 'rxjs';
import { Company } from './company';
import { Quotation } from './quotation';
import { User } from './user';

/**
 * @summary Chat Room Type
 * @example
 * group = 'group', user = 'user'
 */
export enum ChatRoomType {
  group = 'group',
  user = 'user',
}

/**
 * @summary Chat Room Model
 * @example
 * {
 *   "user_id": 2,
 *   "online": false
 * }
 */
export interface MemberOnline {
  user_id: number;
  online: boolean;
}

/**
 * @summary Chat Room Model
 * @example
 * {
 *  "id": 2244,
 *  "room_id": "d4c72199-f0b1-463a-8ef6-dcec816ecae7",
 *  "producer": {
 *    "id": 5,
 *    "name": "New Rubber Company",
 *    "legal_entity_name": "New Rubber Company",
 *    "type": "Producer",
 *    "company_logo_url": ""
 *   },
 *   "consumer": {
 *    "id": 2,
 *    "name": "Rodriguez Inc",
 *    "legal_entity_name": "Rodriguez Inc",
 *    "type": "Consumer",
 *    "company_logo_url": "https://i.imgur.com/RZrxsVG.jpg"
 *   },
 *   "producer_user": null,
 *   "consumer_user": null,
 *   "name": "New Rubber Company-Rodriguez Inc",
 *   "type": "group",
 *   "is_hidden": null,
 *   "members": [11, 2, 166]
 * }
 */
export interface ChatRoom {
  id: number;
  room_id: string;
  producer: Company;
  consumer: Company;
  producer_user: User | null;
  consumer_user: User | null;
  name: string;
  raw_name: string;
  type: ChatRoomType;
  is_hidden: boolean;
  favourite: boolean;
  members: MemberOnline[];
  unread_messages: number;
  error: string;
}

/**
 * @summary Chat Room Update
 * @example
 * {
 *   "name": "string",
 *   "is_hidden": true,
 *   "favourite": true
 * }
 */
export interface ChatRoomUpdate {
  id: number;
  name: string;
  is_hidden: boolean;
  favourite: boolean;
}

export interface ChatList {
  chatGroupList: ChatRoom[];
  chatUserList: ChatRoom[];
}
export interface AvailableStatus {
  name: string;
  label: string;
  icon: string;
}

/**
 * @summary Chat Room Type
 * @example
 * message = 'message', quotation = 'quotation'
 */
export enum ChatMessageType {
  message = 'message',
  quotation = 'quotation',
}

/**
 * @summary Chat Message Model
 * @example
 * {
 *   "id": 2987,
 *   "sent_at": "2021-03-11T09:24:57.546217Z",
 *   "type": "message",
 *   "message": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
 *   "room": 1,
 *   "user": {
 *     "id": 1,
 *     "created_at": "1996-10-09T15:52:26Z",
 *     "name": "Thomas Parker",
 *     "company": 1
 *   },
 *   "read_by": []
 * }
 */
export interface ChatMessage {
  id: number;
  sent_at: Date;
  type: ChatMessageType;
  quotation: Quotation;
  message: string;
  room: number;
  user: User | null;
  read_by: User[];
}

export interface ChatTimeline {
  room: ChatRoom;
  timeline: string;
  start_dt: Date;
  end_dt: Date;
}
export interface ChatDownloadSelected {
  [index: number]: ChatTimeline;
}
export interface ChatMessageStore {
  [index: number]: BehaviorSubject<ChatMessage[]>;
}
export interface ChatMessageObject {
  [index: number]: ChatMessage;
}
export interface ChatRoomSelection {
  [index: number]: ChatRoom;
}
export interface ChatRoomMessageRead {
  id: number;
  sent_at: string;
  room: number;
  user: number;
}
