import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { BehaviorSubject, Subject, takeUntil, tap } from 'rxjs';
import { HCPNotificationType } from 'src/app/models/hcp-notification-type';
import { NotificationMessageType } from 'src/app/models/notification-message-type';
import { ReloadService } from 'src/app/services/reload.service';
import { SoundService, SoundTypes } from 'src/app/services/sound.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserNotificationService } from 'src/app/services/user-notification.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationManagerService {
  /**
   * For terminating all subscriptions
   */
  destroy$ = new Subject<boolean>();
  /**
   * Store for list of notifications
   */
  private notificationList = new BehaviorSubject([]);
  /**
   * Expose notificationList Subject as read-only Observable
   */
  notificationList$ = this.notificationList.asObservable();
  /**
   * Look up table to prevent re-adding of existing notifications
   * to the list
   */
  private notificationLookUp = {};
  /**
   * Store for unread notifications count
   */
  private unreadNotifications = new BehaviorSubject<number>(0);
  /**
   * Expose unreadNotifications Subject as read-only Observable
   */
  unreadNotificationsCount$ = this.unreadNotifications.asObservable();

  constructor(
    private userNotificationService: UserNotificationService,
    private reloadService: ReloadService,
    private soundService: SoundService,
    private storageService: StorageService,
    private notifier: NotifierService,
  ) {}

  /**
   * Initialize the notification manager, should
   * be used by the NotificationDropdownComponent's
   * ngOnInit to properly initialize/re-initialize
   * on user refresh/logout-login flow.
   * 1. Reset all fields to empty/default
   * 2. Register and subscribe to a new
   * instance of the notification websocket
   */
  initialize() {
    this.reset();
    this.getUnackedNotifications();
    this.handleRealtimeNotifications();
  }

  /**
   * Clean up the notification manager, should
   * be used by the NotificationDropdownComponent's
   * ngOnDestroy to properly close the websocket when
   * user refresh/logout-login flow.
   */
  cleanUp() {
    this.destroy$.next(true);
  }

  /**
   * Used to register the notification websocket and
   * then subscribe to it.
   */
  handleRealtimeNotifications() {
    // Watch for new notification from the websocket
    this.userNotificationService
      .register()
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => this.newNotificationReceived(event));
  }

  /**
   * Get the unacknowledged count from the backend.
   * Only done once on init to get unacknowledged count
   */
  getUnackedNotifications() {
    const query = { page_size: 1, page: 1 };
    this.userNotificationService
      .getList(query)
      .subscribe((data) => this.setUnreadCount(data.unacknowledged_count));
  }

  /**
   * Read the previous unread count and update it
   * by adding the new count to the previous count
   * @param count number of new unread notifications
   */
  setUnreadCount(count: number) {
    const currentCount = this.unreadNotifications.getValue();
    this.unreadNotifications.next(currentCount + count);
  }

  /**
   * Mark a single notification as READ, i.e.
   * the user has seen the notification by clicking
   * the blue dot next to the notification
   * @param notification the notification to be marked as read
   */
  markAsRead(notification: any) {
    const list = this.notificationList.getValue();
    const updatedList = list.map((item) => {
      if (item.id === notification.id) {
        item.acknowledged_at = notification.acknowledged_at;
        item.read_at = notification.read_at;
      }

      return item;
    });

    this.notificationList.next(updatedList);
  }

  /**
   * Mark all Notifications as acknowledged, i.e.
   * the user has clicked notification icon and see the
   * list of notifications.
   * @returns an observable of the http request
   */
  markAllAsAcknowledged() {
    return this.userNotificationService.acknowledgeAll();
  }

  /**
   * Mark all Notifications as read, and then
   * reset the notification list
   * @returns an observable of the http request
   */
  markAllAsRead() {
    return this.userNotificationService.readAll().pipe(tap(() => this.reset()));
  }

  /**
   * If the clicked-on notification was never marked as READ,
   * triggers a Read API to mark it as read on the BE,
   * then refresh the current Notification List
   * @param notification the clicked on notification
   */
  readNotification(notification) {
    if (notification.read_at) return;
    const query = { id: notification['id'] };
    this.userNotificationService
      .read(query)
      .subscribe((response) => this.markAsRead(response));
  }

  /**
   * Used to reset all states in this manager service
   * i.e. the notification list, its unread count and
   * its look-up table
   */
  reset() {
    this.notificationList.next([]);
    this.unreadNotifications.next(0);
    this.notificationLookUp = {};
  }

  /**
   * Handle the receipt of a new notification from the websocket
   * 1. Check if its type and payload are valid
   * 2. Update unread count
   * 3. Add the notification to the list
   * 4. Play notification sound
   * @param message the payload of the websocket message
   */
  newNotificationReceived(message) {
    if (
      message['type'] === NotificationMessageType.NewNotification &&
      typeof message['message'] === 'object'
    ) {
      this.setUnreadCount(1);
      this.addNotification(message['message'], true);
      this.playSound(message['type']);
    }
  }

  /**
   * Get a list of notification from backend,
   * based on the given query params, the query
   * typically is an object containing page and page_size
   * @param query containing page and page_size
   * @returns observable of the notification list result
   */
  getNotificationList(query) {
    return this.userNotificationService.getList(query).pipe(
      tap((data) => {
        data['results'].forEach((item) => this.addNotification(item));
      }),
    );
  }

  /**
   * 1. Check that the notification is not in the lookup table, i.e. its NEW
   * 2. Specify how the notification should be added to the list (with/without unshift)
   * 3. Unshift Mode is to display New notification on top of the list, i.e. latest first
   * 4. Non-Unshift Mode is used to append Older notificationsto the bottom of the list,
   * i.e. When User scrolls down the notification list to retrieve older notifications
   * 5. Other side-effects when New Notification is added in Unshift Mode, which are
   * triggering ReloadService, displaying Notifier fly-in.
   * 6. Finally, put the notification id in the lookup table to prevent re-adding it
   * in the future, basically for the check in step 1.
   * @param notification the notification to be added
   * @param unshift whether this is adding a new notification or an older notification
   */
  addNotification(notification, unshift = false) {
    if (typeof this.notificationLookUp[notification['id']] === 'undefined') {
      // prevent notification from being readdedd if it already exists
      const list = this.notificationList.getValue();
      if (unshift) {
        // list.unshift(notification);
        this.notificationList.next([notification, ...list]);
        // If the notification relates to a quotation, emit the event to reload the trade panel.
        if (
          notification['message'] &&
          notification['message']['quotation_id']
        ) {
          this.reloadService.quotationIsUpdated();
          // trigger refresh when notification type is approval request
          switch (notification['type']) {
            case HCPNotificationType.RevisedApprovalRequest:
            case HCPNotificationType.NewApprovalRequest:
            case HCPNotificationType.ReviewedApprovalRequest:
              this.reloadService.quotationApprovalIsUpdated();
              break;
          }
        }
        // If the notification relates to a trade confirmation, emit the event to reload
        // Trade Confirmation panel
        if (notification?.message?.contract_id) {
          this.reloadService.tradeConfirmationIsUpdated();
          // trigger refresh when notification type is approval request
          switch (notification['type']) {
            case HCPNotificationType.RevisedApprovalRequest:
            case HCPNotificationType.NewApprovalRequest:
            case HCPNotificationType.ReviewedApprovalRequest:
              this.reloadService.tradeConfirmationApprovalIsUpdated();
              break;
          }
        }
        this.displayNotifier(notification);
      } else {
        this.notificationList.next([...list, notification]);
      }
      this.notificationLookUp[notification['id']] = true;
    }
  }

  /**
   * Play sound based on user settings and notification type
   * @param type either NewChatNotification or NewNotification
   */
  playSound(type: string) {
    const chatSoundEnabled =
      this.storageService.retrieve<boolean>('enableChatSound');
    const notificationSoundEnabled = this.storageService.retrieve<boolean>(
      'enableNotificationSound',
    );
    // Do not play sound if notification sound is disabled in user settings
    if (!notificationSoundEnabled) {
      return;
    }
    // if the chat sound is disabled and the message type is message
    if (
      !chatSoundEnabled &&
      type === NotificationMessageType.NewChatNotification
    ) {
      this.soundService.playSound(SoundTypes.notification);
    }
    if (type === NotificationMessageType.NewNotification) {
      this.soundService.playSound(SoundTypes.notification);
    }
  }

  /**
   * Check if user allow notification popup and
   * display it using the notifier service
   * @param notification
   */
  displayNotifier(notification) {
    const notificationPopupEnabled = this.storageService.retrieve<boolean>(
      'enableNotificationPopup',
    );
    if (notificationPopupEnabled && notification?.message?.message) {
      this.notifier.notify('primary', notification.message.message);
    }
  }
}
