import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BaseUrlService {
  baseUrl: string;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.baseUrl = this.getBaseUrl();
  }

  private getBaseUrl(): string {
    const baseElements = this.document.getElementsByTagName('base');
    return baseElements.length > 0
      ? baseElements[0].href
      : this.document.location.origin;
  }
}
