<div class="chat-timeline">
  <div class="header">
    <span
      class="left-icon"
      (click)="prevMonth()"
      (keyup.enter)="prevMonth()"
      tabindex="0"></span>
    <div class="title">{{ title }}</div>
    <span
      class="right-icon"
      (click)="nextMonth()"
      (keyup.enter)="nextMonth()"
      tabindex="0"></span>
  </div>
  <ul class="date-list">
    <li (click)="prevDaysSet()" (keyup.enter)="prevDaysSet()" tabindex="0">
      <span class="timeline-left-icon"></span>
    </li>
    <li
      (click)="selectDate(dayObj)"
      (keyup.enter)="selectDate(dayObj)"
      tabindex="0"
      (mouseover)="overDate(dayObj)"
      (focus)="overDate(dayObj)"
      (mouseout)="leaveDate(dayObj)"
      (blur)="leaveDate(dayObj)"
      class="timeline-click"
      [ngClass]="selectedStyle(dayObj)"
      *ngFor="let dayObj of dayrange">
      {{ dayObj.label }}
    </li>
    <li (click)="nextDaysSet()" (keyup.enter)="nextDaysSet()" tabindex="0">
      <span class="timeline-right-icon"></span>
    </li>
  </ul>
  <hr />
  <div class="selected-time">
    <div>
      {{ tmpTimeline.timeline }}
    </div>
    <div>
      <label class="switch">
        <input
          type="checkbox"
          [checked]="tmpTimeline.timeline === 'Full Chat'"
          (change)="changeAllDay($event)" />
        <span class="slider round"></span>
      </label>
      {{ "COMMON.FULL_CHAT" | transloco }}
    </div>
  </div>
  <div class="apply-block">
    <button mat-button (click)="apply()" class="apply-btn">
      {{ "COMMON.APPLY" | transloco }}
    </button>
  </div>
</div>
