<div class="search-bar">
  <input
    [(ngModel)]="search"
    (ngModelChange)="searchList($event)"
    type="search"
    class="hc-search"
    [placeholder]="'COMMON.SEARCH_CHAT' | transloco" />
</div>
<div class="maximize-body" #roomList (scroll)="onRoomScroll()">
  <div
    class="drop-box drop-box--chat-more-menu"
    tabindex="-1"
    (focusout)="closeMenu()"
    #menu
    *ngIf="openMenu"
    [ngStyle]="menuStyle">
    <div
      (click)="starChat()"
      (keyup.enter)="starChat()"
      tabindex="0"
      class="more-menu-item">
      {{
        (focusRoom.favourite ? "COMMON.UNSTAR_CHAT" : "COMMON.STAR_CHAT")
          | transloco
      }}
    </div>
    <div
      (click)="downloadChat()"
      (keyup.enter)="downloadChat()"
      tabindex="0"
      class="more-menu-item">
      {{ "COMMON.DOWNLOAD_CHAT" | transloco }}
    </div>
  </div>

  <div class="grouping">
    <div class="grouping-header">
      <div
        class="header-chat-title"
        (click)="chatRoomExpansion.chatGroup = !chatRoomExpansion.chatGroup"
        (keyup.enter)="
          chatRoomExpansion.chatGroup = !chatRoomExpansion.chatGroup
        "
        tabindex="0">
        <div *ngIf="chatRoomExpansion.chatGroup" class="expand-icon"></div>
        <div
          *ngIf="!chatRoomExpansion.chatGroup"
          class="close-right-icon"></div>
        <div class="second-label">{{ "COMMON.GROUP_CHAT" | transloco }}</div>
      </div>

      <!-- plus icon will be  here -->
    </div>
    <div class="grouping-body" *ngIf="chatRoomExpansion.chatGroup">
      <div
        (click)="openChat(room)"
        class="chat"
        *ngFor="let room of chatListSearched.chatGroupList"
        (keyup.enter)="openChat(room)"
        tabindex="0">
        <div *ngIf="room.unread_messages > 0" class="unread-dot-container">
          <div class="unread-dot-wrapper">
            <div class="icon-chat-active-dot"></div>
          </div>
        </div>
        <div class="icon-user-chat"></div>
        <div class="title-user-chat">
          {{ room.name }}
        </div>
        <div
          class="chat-more-menu-btn-container"
          (click)="onClickMoreMenu($event, room)"
          (keyup.enter)="onClickMoreMenu($event, room)"
          tabindex="0">
          <div class="chat-more-menu-btn-wrapper">
            <div class="icon-three-dots"></div>
          </div>
        </div>
        <div *ngIf="room.unread_messages > 0" class="unread-badge-container">
          <div class="unread-badge-wrapper">
            <div class="unread-badge">
              {{ room.unread_messages < 10 ? room.unread_messages : "9+" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grouping">
    <div class="grouping-header">
      <div
        class="header-chat-title"
        (click)="chatRoomExpansion.chatUser = !chatRoomExpansion.chatUser"
        (keyup.enter)="chatRoomExpansion.chatUser = !chatRoomExpansion.chatUser"
        tabindex="0">
        <div *ngIf="chatRoomExpansion.chatUser" class="expand-icon"></div>
        <div *ngIf="!chatRoomExpansion.chatUser" class="close-right-icon"></div>
        <div class="second-label">
          {{ "COMMON.INDIVIDUAL_CHAT" | transloco }}
        </div>
      </div>
      <!-- plus icon will be  here -->
    </div>
    <div class="grouping-body" *ngIf="chatRoomExpansion.chatUser">
      <div
        (click)="openChat(room)"
        class="chat"
        *ngFor="let room of chatListSearched.chatUserList"
        (keyup.enter)="openChat(room)"
        tabindex="0">
        <div *ngIf="room.unread_messages > 0" class="unread-dot-container">
          <div class="unread-dot-wrapper">
            <div class="icon-chat-active-dot"></div>
          </div>
        </div>
        <div class="icon-user-chat"></div>
        <div class="title-user-chat">
          {{ room.name }}
        </div>
        <div
          class="chat-more-menu-btn-container"
          (click)="onClickMoreMenu($event, room)"
          (keyup.enter)="onClickMoreMenu($event, room)"
          tabindex="0">
          <div class="chat-more-menu-btn-wrapper">
            <div class="icon-three-dots"></div>
          </div>
        </div>
        <div *ngIf="room.unread_messages > 0" class="unread-badge-container">
          <div class="unread-badge-wrapper">
            <div class="unread-badge">{{ room.unread_messages }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
