import { Injectable } from '@angular/core';
import { Firestore, doc, docData } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { PhysicalIndexStream, SixStream } from '../models/sicom-latest-price';

/**
 * This service is used to create Firestore
 * collections, documents queries relevant to the application.
 */
@Injectable({
  providedIn: 'root',
})
export class FirestoreFactoryService {
  constructor(private firestore: Firestore) {}
  /**
   * @returns an observable streaming the sixdelayedclient Firestore
   * document
   */
  createDelayedPricesQuery() {
    const docRef = doc(this.firestore, '/streams/sicom/v1/sixdelayedclient/');
    return docData(docRef, { idField: 'id' }) as Observable<SixStream<string>>;
  }
  /**
   * @returns an observable streaming the sixrealtimeclient Firestore
   * document
   */
  createRealtimePricesQuery() {
    const docRef = doc(this.firestore, '/streams/sicom/v1/sixrealtimeclient/');
    return docData(docRef, { idField: 'id' }) as Observable<SixStream<string>>;
  }
  /**
   * @returns an observable streaming the sgxphysicalpriceindexv1 Firestore
   * document
   */
  createPhysicalIndexQuery() {
    const docRef = doc(
      this.firestore,
      '/streams/sicom/v1/sgxphysicalpriceindexv1/',
    );
    return docData(docRef, {
      idField: 'id',
    }) as Observable<PhysicalIndexStream>;
  }
}
