import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import Format from '@date-format';
import { format, parse, sub } from 'date-fns';
import {
  CandlestickData,
  ChartOptions,
  HistogramData,
  ISeriesApi,
} from 'lightweight-charts';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  filter,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { ArpLightweightChartDirective } from '../directives/arp-lightweight-chart/arp-lightweight-chart.directive';
import { ButtonToggleGroupModel } from '../models/form-field-models';
import { Instrument, SicomPrice } from '../models/sicom-latest-price';
import {
  ChartRange,
  ChartRefs,
  createTooltipElement,
  getOHCLColor,
  sicomTooltipHandler,
} from '../utils/lightweight-chart-utils';
import { isObjectValuesTruthy } from '../utils/object';
import { SixStreamService } from './six-stream.service';

/**
 * Interface for the chart references
 * specific to this Sicom Display Component
 */
interface SicomChartRefs extends ChartRefs {
  candleStickSeries: ISeriesApi<'Candlestick'>;
  histogramSeries: ISeriesApi<'Histogram'>;
}

@Injectable()
export class SicomDisplayLogicService {
  /**
   * Subject for terminating component's subscription
   */
  private destroy$ = new Subject<boolean>();
  /**
   * Subject for storing/updating reference to the latest chartContainer, IChartApi
   * candlestickSeries, histogramSeries objects retrieved from View
   * Query in the component
   */
  private chartRefs$ = new ReplaySubject<SicomChartRefs>(1);
  /**
   * Subject for emitting loading state of the chart
   */
  private chartLoadingSubject = new BehaviorSubject<boolean>(true);
  /**
   * Retrieve the sicom$ stream from the SixStreamService
   */
  private sicom$ = this.sixStreamService.sicom$;
  /**
   * Retrieve the sicomInstrumentMap$ stream from the SixStreamService
   */
  private sicomInstrumentMap$ = this.sixStreamService.sicomInstrumentMap$;
  /**
   * Subject for emitting selected market
   */
  private selectedMarket$ = new ReplaySubject<string>(1);
  /**
   * Subject for emitting selected month
   */
  private selectedMonth$ = new ReplaySubject<string>(1);
  /**
   * Subject for emitting selected graph range
   */
  private selectedRange$ = new BehaviorSubject<ChartRange>('Year');
  /**
   * Compute the chart data for selected market and month by:
   * 1. Combining the selectedMarket$, selectedMonth$, and sicomInstrumentMap$ streams
   * 2. Map the combined data streams to the actual instrument code and query range
   * 3. Tap to emit chartLoadingSubject with true
   * 4. switchMap the instrument code and query range to an API call
   * to retrieve the Sicom Instrument Data
   * 5. Map the Sicom Instrument to candlestick and histogram data
   * 6. Tap again to emit chartLoadingSubject with false
   */
  private sicomChartData$ = combineLatest(
    [
      this.selectedMarket$,
      this.selectedMonth$,
      this.selectedRange$,
      this.sicomInstrumentMap$,
    ],
    (selectedMarket, selectedMonth, selectedRange, sicomInstrumentMap) => {
      const instrumentKey = `${selectedMarket} ${this.datePipe
        .transform(selectedMonth, Format.yearMonthFormat)
        .toUpperCase()}`;
      return {
        instrumentCode: sicomInstrumentMap[instrumentKey],
        range: selectedRange,
      };
    },
  ).pipe(
    tap((_) => this.chartLoadingSubject.next(true)),
    switchMap(({ instrumentCode, range }) =>
      this.queryInstrumentData(instrumentCode, range),
    ),
    map((instrumentData) => {
      return {
        candleStick: this.mapInstrumentToCandlestick(instrumentData),
        histogram: this.mapInstrumentToHistogram(instrumentData),
      };
    }),
    tap((_) => this.chartLoadingSubject.next(false)),
  );
  /**
   * The chart renderer engine. Work by:
   * 1. Combining the sicomChartData$ and chartRefs$ streams
   * 2. Stream is only active until the component is destroyed
   * 3. Ignore the stream if the chartRefs are not available (can't render anything
   * without chartRefs)
   * 4. Tap into chart data and chartRefs. Imperatively update the chart series
   * with chart data using setData
   */
  private chartRenderer$ = combineLatest([
    this.sicomChartData$,
    this.chartRefs$,
  ]).pipe(
    takeUntil(this.destroy$),
    filter(([, chartRefs]) => isObjectValuesTruthy(chartRefs)),
    tap(([chartData, chartRefs]) => {
      const { candleStick, histogram } = chartData;
      const { candleStickSeries, histogramSeries } = chartRefs;
      candleStickSeries.setData(candleStick);
      histogramSeries.setData(histogram);
      chartRefs.chart.timeScale().fitContent();
    }),
  );
  /**
   * Retrieve the sixStreamStatus$ stream from the SixStreamService
   */
  get sixStreamStatus$() {
    return this.sixStreamService.sixStreamStatus$;
  }
  /**
   * Retrieve the lastUpdatedTime stream from the SixStreamService, formatted
   * in dd/MM/yyyy | hh:mm:ss a
   */
  get lastUpdatedTimeString$() {
    return this.sixStreamService.lastUpdatedTime$.pipe(
      map((time) => format(time, 'dd/MM/yyyy | hh:mm:ss a')),
    );
  }
  /**
   * Expose the chartLoadingSubject as a read-only observable
   */
  get chartLoading$() {
    return this.chartLoadingSubject.asObservable();
  }
  /**
   * Retrieve the marketSelectField$ stream from the SixStreamService
   */
  get marketSelectField$() {
    return this.sixStreamService.marketSelectField$;
  }
  /**
   * Retrieve the monthSelectField$ stream from the SixStreamService
   */
  get monthSelectField$() {
    return this.sixStreamService.monthSelectField$;
  }
  /**
   * The range toggle field stream for Sicom Display Component
   */
  get rangeToggleField$() {
    return of({
      appearance: 'standard',
      selectedOptions: 'Year',
      options: ['Week', 'Month', 'Year'],
      name: 'range',
      for: 'range',
    } as ButtonToggleGroupModel<ChartRange>);
  }
  /**
   * Compute the selected sicom object by combining
   * the selectedMarket$, selectedMonth$, and sicom$ streams
   */
  get selectedSicom$() {
    return combineLatest(
      [this.selectedMarket$, this.selectedMonth$, this.sicom$],
      (selectedMarket, selectedMonth, sicomStream) => {
        return sicomStream[selectedMarket][selectedMonth] as SicomPrice<number>;
      },
    ).pipe(map((sicom) => this.createSelectedSicom(sicom)));
  }

  constructor(
    private sixStreamService: SixStreamService,
    private datePipe: DatePipe,
  ) {}
  /**
   * Initialize the states of the SicomDisplayLogicService
   * by:
   * 1. Fetching the selected market and month from upstream
   * and re-emit the selected market and month to the internal selectedMarket$
   * and selectedMonth$ streams respectively. Basically copy global state to local state.
   * 2. Subscribe to chartRenderer$ to render/update chart
   */
  initializeStates() {
    this.marketSelectField$
      .pipe(
        take(1),
        tap((marketSelectField) =>
          this.selectMarket(marketSelectField.selectedOptions as string),
        ),
      )
      .subscribe();
    this.monthSelectField$
      .pipe(
        take(1),
        tap((monthSelectField) =>
          this.selectMonth(monthSelectField.selectedOptions as string),
        ),
      )
      .subscribe();
    this.chartRenderer$.subscribe();
  }
  /**
   * Clean up internal subscriptions
   * by emitting a value to the destroy$ subject
   */
  cleanUp() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  /**
   * Update the selected market
   * @param market market name
   */
  selectMarket(market: string) {
    this.selectedMarket$.next(market);
  }
  /**
   * Update the selected month
   * @param month month string
   */
  selectMonth(month: string) {
    this.selectedMonth$.next(month);
  }
  /**
   * Update the selected range
   * @param range range string
   */
  selectRange(range: ChartRange) {
    this.selectedRange$.next(range);
  }
  /**
   * Update the chartRefs
   * @param chartDirective the chart directive retrieved from template reference
   */
  updateChartRefs(chartDirective: ArpLightweightChartDirective) {
    const chartRefs = this.createChartRefs(chartDirective);
    this.chartRefs$.next(chartRefs);
  }

  /**
   * Map SicomPrice object to displayable format for
   * template rendering
   * @param sicom the SicomPrice object
   * @returns the displayable object
   */
  createSelectedSicom(sicom: SicomPrice<number>) {
    const change = sicom.last_trade - sicom.most_recent_settlement_price;
    const changePercent = (change / sicom.most_recent_settlement_price) * 100;
    return {
      currentSymbol: sicom.symbol,
      currentPrice: sicom.last_trade,
      currentHighPrice: sicom.high_price,
      currentLowPrice: sicom.low_price,
      currentOpenPrice: sicom.open_price,
      currentBidPrice: sicom.bid_price,
      currentOfferPrice: sicom.ask_price,
      currentBidSize: sicom.bid_quantity,
      currentOfferSize: sicom.ask_quantity,
      currentVolume: sicom.cumulative_instrument_volume,
      currentOpenInterest: sicom.open_interest,
      prevDaySettlementPrice: sicom.most_recent_settlement_price,
      change,
      changePercent,
      changeDirection:
        change > 0 ? 'positive' : change < 0 ? 'negative' : 'flat',
      changeIcon:
        change > 0
          ? 'trending_up'
          : change < 0
          ? 'trending_down'
          : 'trending_flat',
    };
  }

  /**
   * Map the Sicom Instrument to candlestick data for charting price
   * @param sicomInstrument the Sicom Instrument object
   * @returns array of candlestick data
   */
  mapInstrumentToCandlestick(sicomInstrument: Instrument): CandlestickData[] {
    return sicomInstrument.Values.map((value) => {
      return {
        time: format(
          parse(value.Date, 'yyyyMMdd', new Date()),
          Format.apiDateFormat,
        ),
        open: parseFloat(value.Data.open_price),
        close: parseFloat(value.Data.most_recent_settlement_price),
        high: parseFloat(value.Data.high_price),
        low: parseFloat(value.Data.low_price),
      } as CandlestickData;
    });
  }

  /**
   * Map the Sicom Instrument to histogram data for charting volume
   * @param sicomInstrument the Sicom Instrument object
   * @returns array of histogram data
   */
  mapInstrumentToHistogram(sicomInstrument: Instrument): HistogramData[] {
    return sicomInstrument.Values.map((value) => {
      return {
        time: format(
          parse(value.Date, 'yyyyMMdd', new Date()),
          Format.apiDateFormat,
        ),
        value: parseFloat(value.Data.cumulative_vol),
        color: getOHCLColor(
          value.Data.open_price,
          value.Data.most_recent_settlement_price,
        ),
      } as HistogramData;
    });
  }

  /**
   * Query the instrument data for selected instrument code,
   * default to 7 days of data for now
   * @param instrumentCode the instrument code of the sicom commodity
   * @param range the range of the query
   * @returns an observable wrapping the Instrument Data
   */
  queryInstrumentData(instrumentCode: string, range: ChartRange) {
    const today = new Date();
    const dateto = format(today, Format.apiDateFormat);
    const datefrom = format(
      sub(today, { [`${range.toLowerCase()}s`]: 1 }),
      Format.apiDateFormat,
    );
    return this.sixStreamService.queryInstrumentData(
      instrumentCode,
      datefrom,
      dateto,
    );
  }

  /**
   * 1. Create the chart object.
   * 2. Add the candlestick series and volume series to the chart,
   * and apply necessary options.
   * 3. Return the chart container, chart object and the series objects.
   * @param chartDirective the chart directive retrieved from template reference
   * @returns the chart container, chart object and the series objects
   */
  createChartRefs(chartDirective: ArpLightweightChartDirective) {
    const chart = chartDirective.createChart({
      autoSize: true,
      timeScale: {
        fixLeftEdge: true,
        fixRightEdge: true,
        ticksVisible: true,
      },
      rightPriceScale: {
        ticksVisible: true,
      },
    } as ChartOptions);
    const candleStickSeries = chart.addCandlestickSeries();
    candleStickSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.1,
        bottom: 0.325,
      },
    });
    const histogramSeries = chart.addHistogramSeries({
      priceFormat: {
        type: 'volume',
      },
      priceScaleId: '',
    });
    histogramSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.7,
        bottom: 0,
      },
    });
    const chartContainer = chartDirective.getChartContainer();
    const toolTip = createTooltipElement();
    chartContainer.appendChild(toolTip);
    chart.subscribeCrosshairMove((param) =>
      sicomTooltipHandler(
        param,
        toolTip,
        chartContainer,
        candleStickSeries,
        histogramSeries,
      ),
    );
    return { chartContainer, chart, candleStickSeries, histogramSeries };
  }
}
