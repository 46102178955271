import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  private readyToTrack = false;

  /**
   * Initialize mixpanel
   * Call this once app-login component is loaded
   * @returns true when in production mode and mixpanel token is available,
   * false when not in production mode and mixpanel token is unavailable
   */
  init() {
    if (environment.MIXPANEL_TOKEN) {
      try {
        mixpanel.init(environment.MIXPANEL_TOKEN);
        this.readyToTrack = true;
      } catch (error) {
        console.error(`Mixpanel error: ${error}`);
        this.readyToTrack = false;
      }
    } else {
      this.readyToTrack = false;
    }
  }

  /**
   * Clear properties and generate new distinct_id for
   * this mixpanel instance. Use to clear data when
   * user logs out.
   */
  reset() {
    if (this.readyToTrack) {
      try {
        mixpanel.reset();
      } catch (error) {
        console.error(`Mixpanel error: ${error}`);
      }
    }
  }

  /**
   * Opt in to tracking users'actions. By default, when initiated, a user is
   * already opted in and typically should not need to call this method
   */
  optInTracking() {
    if (this.readyToTrack) {
      mixpanel.opt_in_tracking({ track_event_name: 'Opt In Tracking' });
    }
  }

  /**
   * Opt out of tracking users'actions. To be called when user do not want
   * their actions tracked. After opted out, any track() calls will not send
   * users'actions to mixpanel.
   */
  optOutTracking() {
    if (this.readyToTrack) {
      mixpanel.opt_out_tracking();
    }
  }

  /**
   * Identify a user with a unique userToken,
   * possibly a userToken received after user successfully login
   * @param userToken unique ID associated with the user accessing the app
   */
  identify(userToken: string) {
    if (this.readyToTrack) {
      mixpanel.identify(userToken);
    }
  }

  /**
   * Map an aliasName to mixpanel's own uniqueId for an anonymous user,
   * so that anonymous user can later be identified using the alias name.
   * To be used to associate anonymous events, when user has not had an account,
   * with the user when they successfully registered for an account
   * @param aliasName to be mapped to mixpanel's own UniqueId
   */
  alias(aliasName: string) {
    if (this.readyToTrack) {
      mixpanel.alias(aliasName);
    }
  }

  /**
   * Method for tracking user actions. Will only track users'actions if
   * the service is ready to track AND allowed to track
   * @param eventName name of event
   * @param properties properties to include together with event
   * @param options optional configuration
   */
  track(
    eventName: string,
    properties: any = { default: 'no property' },
    options: any = {},
  ) {
    if (this.readyToTrack) {
      try {
        mixpanel.track(eventName, properties, options);
      } catch (error) {
        console.error(`Mixpanel error: ${error}`);
      }
    }
  }
}
