import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductionEnvironmentGuard {
  /**
   * Check if protected route can be activate
   * @returns true if storage contains Authorization, else false and redirect to '/'
   */

  canActivate(): boolean {
    return !environment.production;
  }
}
