import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UserSettingsService } from 'src/app/services/user-settings.service';

@Component({
  selector: 'app-tou-viewer',
  templateUrl: './tou-viewer.component.html',
  styleUrls: ['./tou-viewer.component.scss'],
})
export class TouViewerComponent implements OnInit {
  termsOfUseUrl: SafeResourceUrl = '';
  constructor(
    private userSettingsService: UserSettingsService,
    private sanitizer: DomSanitizer,
    private dialogRef: MatLegacyDialogRef<TouViewerComponent>,
  ) {}

  ngOnInit(): void {
    this.userSettingsService.getTermsOfUse().subscribe((dataResult) => {
      if (dataResult['url']) {
        this.termsOfUseUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          dataResult['url'] + '#toolbar=0&navpanes=0&scrollbar=0',
        ); // nosonar
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
