import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import dateTimeFormat from 'src/assets/content/format/date-time-format.json';
const monthFormat = dateTimeFormat.monthFormat;
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() showXButton = true;
  @Input() modalData;
  @Input() halfModal;
  @Input() headerData;
  @Input() topButton;
  /** Data to show on for Full Modal */
  @Input() modalDisplayData;
  @Input() previewDisplay;
  @Input() selectCustomer;
  @Input() textAreaValue: string;
  @Input() rejectCommentValue: string;
  @Input() showPrivateRemark = false;

  @Output() nextClick = new EventEmitter<any>();
  @Output() rejectClick = new EventEmitter<any>();
  @Output() goClick = new EventEmitter<any>();
  @Output() commentClick = new EventEmitter<any>();
  @Output() forgotClick = new EventEmitter<any>();
  @Output() previewClick = new EventEmitter<any>();

  forgotValue: string;
  errorEmailStatus: boolean;
  monthFormat = monthFormat;

  ngOnInit(): void {
    this.errorEmailStatus = false;
  }
  onGoButtonClick(e) {
    // cancel event
    this.goClick.emit();
  }
  onNextButtonClick(e) {
    this.nextClick.emit();
  }
  onRejectButtonClick(e) {
    this.rejectClick.emit({ comment: this.rejectCommentValue });
  }

  onCommentButtonClick() {
    this.commentClick.emit({
      value: this.textAreaValue,
      pageName: this.modalData.pageName,
      id: this.modalData.itemId,
    });
  }
  onPreviewClick() {
    this.previewClick.emit();
  }
  onCloseClick(e) {
    this.goClick.emit();
  }
  forgotPasswordFill(e) {
    this.forgotValue = e.target.value;
    this.errorEmailStatus = false;
    this.errorEmailStatus = this.validateEmail(this.forgotValue);
  }
  validateEmail(mail) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail,
      )
    ) {
      return false;
    } else {
      return true;
    }
  }
  onForgotButtonClick() {
    this.forgotClick.emit({
      value: this.forgotValue,
      pageName: this.modalData.pageName,
      id: this.modalData.itemId,
    });
  }
}
