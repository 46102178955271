/**
 * @summary Company Type
 * @example
 * Consumer = 'Consumer', Producer = 'Producer'
 */
export enum CompanyType {
  Consumer = 'Consumer',
  Producer = 'Producer',
}

// WK: same as Quotation Model Producer
/**
 * @summary Base Company (Consumer or Producer)
 * @example {
 *   "id": 62,
 *   "name": "BRIDGESTONE",
 *   "legal_entity_name": "Bridgestone Singapore Pte Ltd",
 *   "type": "Consumer",
 *   "company_logo_url": "https://example.com/logo.png",
 *   "billing_address": {
 *     "address_line_1": "123 Main St",
 *     "address_line_2": "Apt 4B",
 *     "address_line_3": "",
 *     "city": "New York",
 *     "country": "USA",
 *     "postal_code": 10001,
 *     "state_or_province": "NY"
 *   }
 * }
 */
export interface Company {
  id: number;
  name: string;
  type?: CompanyType;
  legal_entity_name?: string;
  company_logo_url?: string;
  billing_address?: BillingAddress;
  tenant_id?: string;
}

/**
 * @summary Company Billing Address interface
 * @example {
 *   "address_line_1": "123 Main St",
 *   "address_line_2": "Apt 4B",
 *   "address_line_3": "",
 *   "city": "New York",
 *   "country": "USA",
 *   "postal_code": 10001,
 *   "state_or_province": "NY"
 * }
 */
export interface BillingAddress {
  address_line_1: string;
  address_line_2: string;
  address_line_3: string;
  city: string;
  country: string;
  postal_code: number;
  state_or_province: string;
}
