import { HttpErrorResponse } from '@angular/common/http';
import { Message } from '../message';

export class ErrorMessage extends Message {
  getMessage(content: string | HttpErrorResponse): Message {
    this.icon = 'error';
    this.color = 'warn';
    this.content = content as string;
    return this;
  }

  close(): void {
    // Close method
  }

  action(): void {
    // Action method
  }
}
