<div class="hc-saved-item-container">
  <div class="bootstrap-wrapper">
    <div class="container-fluid">
      <div class="row">
        <mat-form-field id="hc-search-input-container" appearance="outline">
          <mat-label
            >{{ "COMMON.SEARCH" | transloco }} {{ filterNameText }}</mat-label
          >
          <input
            matInput
            [(ngModel)]="searchInputText"
            (ngModelChange)="onFilterChange($event)"
            type="search"
            [matTooltip]="tooltipText"
            matTooltipClass="custom-mat-tooltip" />
          <mat-icon matPrefix>search</mat-icon>
          <mat-icon matSuffix (click)="clearSearchAction()">close</mat-icon>
        </mat-form-field>
        <ng-container *ngFor="let filter of filters">
          <mat-form-field id="hc-dropdown" floatLabel="never">
            <mat-select
              (selectionChange)="handleFilterChange($event.value, filter)"
              [multiple]="filter.multiple"
              [(value)]="filter.selectedOption"
              [placeholder]="filter.placeHolderText | transloco | titlecase">
              <mat-select-trigger
                *ngIf="filter.multiple && filter?.selectedOption?.length === 1">
                {{
                  filter.type === "date"
                    ? (getSelectedText(filter) | date: monthFormat)
                    : (getSelectedText(filter) | transloco)
                }}
                <span
                  class="select-count"
                  *ngIf="filter?.selectedOption?.length > 1">
                  {{ filter?.selectedOption?.length }}
                </span>
              </mat-select-trigger>
              <mat-option
                *ngFor="let option of filter.options"
                [value]="option">
                {{
                  filter.type === "date" ? (option | date: monthFormat) : option
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <a
          id="clear-all-button"
          (click)="handleClearFilters()"
          (keyup.enter)="handleClearFilters()"
          tabindex="0">
          {{ "COMMON.CLEAR_ALL" | transloco }}
        </a>
      </div>
    </div>
  </div>
</div>
