import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { finalize, take } from 'rxjs';
import {
  ChatTimeline,
  ChatDownloadSelected,
  ChatRoom,
  ChatRoomType,
} from 'src/app/models/chatroom';
import { ChatService } from 'src/app/services/chat.service';
import { SvgLoaderService } from 'src/app/services/svg-loader.service';

@Component({
  selector: 'app-chat-download',
  templateUrl: './chat-download.component.html',
  styleUrls: ['./chat-download.component.scss'],
})
export class ChatDownloadComponent implements OnInit {
  @ViewChild('roomList', { static: false }) roombody: ElementRef;

  @ViewChild('timeline', { static: false }) set menuElement(
    content: ElementRef,
  ) {
    if (content?.nativeElement) {
      content.nativeElement.focus();
      if (content?.nativeElement) {
        this.timelineBody = content.nativeElement as HTMLElement;
      }
    }
  }

  chatGroupExpansion = true;
  chatUserExpansion = true;

  disableDownload = false;
  showDownloadChatBox = false;
  roomList: ChatRoom[];
  timelineBody: HTMLElement;
  listGroupSearched: ChatRoom[];
  listUserSearched: ChatRoom[];
  search: string;
  numSelected: number;
  openTimeline = false;
  timelineStyle: any;
  currentTimeline: ChatTimeline;
  selected: ChatDownloadSelected;

  svgContents: { [key: string]: string } = {};

  constructor(
    private chatService: ChatService,
    private svgLoaderService: SvgLoaderService,
  ) {}

  ngOnInit(): void {
    this.setRoomList();
    this.setSelectedChatRoom();
    this.loadSvgIcons();
  }

  loadSvgIcons() {
    const svgUrls = [{ name: 'calendar', url: `assets/images/calendar.svg` }];

    svgUrls.forEach((svg) => {
      this.svgLoaderService
        .loadSvg(svg.url)
        .pipe(take(1))
        .subscribe(
          (content) => (this.svgContents[svg.name] = content),
          (error) => console.error('Error loading SVG:', error),
        );
    });
  }

  public searchList(): void {
    const listSearched = this.roomList.filter((item: ChatRoom) =>
      !this.search ? true : this.checkSearch(item.name),
    );
    this.listGroupSearched = listSearched.filter(
      (item: ChatRoom) => item.type === ChatRoomType.group,
    );
    this.listUserSearched = listSearched.filter(
      (item: ChatRoom) => item.type === ChatRoomType.user,
    );
  }

  private checkSearch(value: string) {
    return !value
      ? false
      : value.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1;
  }

  private setRoomList(): void {
    this.roomList = [];
    this.chatService.roomList.subscribe((rooms: ChatRoom[]) => {
      this.roomList = rooms;
      this.searchList();
    });
  }

  private setSelectedChatRoom(): void {
    this.chatService.downloadChat.subscribe(
      (selected: ChatDownloadSelected) => {
        if (typeof selected !== 'undefined') {
          this.selected = selected;
          this.numSelected = Object.keys(selected).length;
          this.showDownloadChatBox = true;
        } else {
          this.showDownloadChatBox = false;
        }
      },
    );
  }

  close() {
    this.chatService.downloadChat.next(undefined);
  }

  selectedRoom(room: ChatRoom) {
    const selectedList = this.chatService.downloadChat.value;
    if (!selectedList[room.id]) {
      this.chatService.addToChatDownload(room);
    } else {
      delete selectedList[room.id];
      this.chatService.downloadChat.next(selectedList);
    }
  }

  openChatTimeLine(event: Event, chatDownload: ChatTimeline) {
    this.openTimeline = true;
    this.currentTimeline = chatDownload;
    const timeLinePosition = (
      event.target as HTMLElement
    ).getBoundingClientRect();
    const boxPosition = this.roombody.nativeElement.getBoundingClientRect();
    // Adjust position for the padding
    const left = boxPosition.width - 200;
    this.timelineStyle = {
      'left.px': left,
      'top.px': timeLinePosition.top,
    };
  }

  onRoomScroll(event: any): void {
    this.closeTimeline(event);
  }

  closeTimeline(event: any): void {
    if (
      !(
        event.relatedTarget &&
        event.relatedTarget.closest('.drop-box--chat-timeline')
      )
    ) {
      this.openTimeline = false;
      this.currentTimeline = undefined;
    }
  }

  downloadChatAction(): void {
    this.disableDownload = true;
    this.chatService
      .downloadChatHistory()
      .pipe(finalize(() => (this.disableDownload = false)))
      .subscribe();
  }

  changeTimeline(timeline: ChatTimeline) {
    this.currentTimeline.end_dt = timeline.end_dt;
    this.currentTimeline.start_dt = timeline.start_dt;
    this.currentTimeline.timeline = timeline.timeline;
    this.openTimeline = false;
    this.currentTimeline = undefined;
  }
}
