import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SIPageRequest } from 'src/app/models/si/request/request';
import { Chip, IFilter } from './si-filter-chip';
import { ThemePalette } from '@angular/material/core';
export interface ChipColor {
  name: string;
  color: ThemePalette;
}

@Component({
  selector: 'app-si-filter',
  templateUrl: './si-filter.component.html',
  styleUrls: ['./si-filter.component.scss'],
})
export class SiFilterComponent {
  filterCount = 0;
  searchText = '';
  isExpanded = true;
  previousSelectedOptions: string[] | string;
  selectedProducer: string;

  @Input() initialSelectedProducer: string;
  @Input() filters: IFilter[] = [];
  @Output() getSIList = new EventEmitter<SIPageRequest>();
  @Output() getFilters = new EventEmitter<string>();

  handleChipRemove(filter: IFilter, removedItem: string) {
    const filterType = filter.multiple ? 'multiple' : 'single';
    let selectedOption = [];

    if (filterType === 'multiple') {
      selectedOption = filter.selectedOption.filter(
        (item) => item !== removedItem,
      );
    } else {
      selectedOption = [];
    }

    const { key } = filter;
    const index = this.filters.findIndex((p) => p.key === key);
    this.filters[index].selectedOption = selectedOption;
    this.filterCount = this.filters.flatMap((p) => p.selectedOption).length;

    if (key === 'producer') {
      this.selectedProducer = '';
      this.getFilters.emit(this.producerToUse);
      this.getSIList.emit({ producer: this.producerToUse });
    } else {
      this.getSIList.emit(this.pageParam);
    }
  }

  // check if can simplify this
  get producerToUse() {
    return this.selectedProducer || this.initialSelectedProducer;
  }

  handleResetFilter() {
    this.filterCount = 0;
    this.searchText = '';
    this.filters.forEach((filter: IFilter) => {
      if (filter.key !== 'producer') {
        filter.selectedOption = [];
      }
    });
    // this.getFilters.emit(this.producerToUse);
    this.getSIList.emit({ producer: this.producerToUse });
  }

  trackBySelectedOptions(index, item) {
    return item.filter.selectedOption;
  }

  get chips(): Chip[] {
    const chip: Chip[] = [];
    this.filters.forEach((filter: IFilter) => {
      const options = [filter].flatMap((option) => option.selectedOption);
      chip.push({ filter, options });
    });
    return chip;
  }

  getSelectedText(options: string[] | string): string {
    return Array.isArray(options) ? options[0] : options;
  }

  // save selectionOptions on open to a temp variable, on close compare the two variables
  // only update the page if they are different
  handleToggleChange(isOpen: boolean, filter: IFilter) {
    const { key } = filter;

    if (filter.multiple) {
      if (isOpen) {
        this.previousSelectedOptions = [...filter.selectedOption];
      } else {
        const isListSame =
          this.previousSelectedOptions.length ===
            filter.selectedOption.length &&
          filter.selectedOption.every(
            (item, index) => item === this.previousSelectedOptions[index],
          );
        if (!isListSame) {
          this.getSIList.emit(this.pageParam);
        }
        this.previousSelectedOptions = null;
      }
    } else {
      if (isOpen) {
        this.previousSelectedOptions = filter.selectedOption;
      } else {
        if (this.previousSelectedOptions !== filter.selectedOption) {
          // filters need to be updated if user selected a different producer
          if (key === 'producer') {
            this.selectedProducer = filter.selectedOption as string;
            this.getFilters.emit(this.selectedProducer);
          }
          this.getSIList.emit(this.pageParam);
        }
        this.previousSelectedOptions = null;
      }
    }
  }

  public handleFilterChange(
    selected?: string[] | string,
    selectedFilter?: IFilter,
  ) {
    this.filterCount = this.filters.flatMap((p) => p.selectedOption).length;
  }

  public handleSearch() {
    this.getSIList.emit(this.pageParam);
  }

  private addFilteringParam(
    key: string,
    value: IFilter,
    siPageRequest: SIPageRequest,
  ) {
    const urlparams = this.getFilterValue(value.multiple, value.selectedOption);
    if (urlparams && urlparams !== undefined && urlparams !== '') {
      siPageRequest[key] = urlparams;
    }
  }

  private getFilterValue(multiSelect, value) {
    return multiSelect ? value.join(',') : value;
  }

  get pageParam(): SIPageRequest {
    const siPageRequest: SIPageRequest = {};
    siPageRequest.producer = this.producerToUse;
    if (this.searchText.length > 0) {
      siPageRequest.si_number = this.searchText;
      siPageRequest.buyer_ref_numb = this.searchText;
    }

    this.filters.forEach((filter) => {
      this.addFilteringParam(filter.key, filter, siPageRequest);
    });

    return siPageRequest;
  }
}
