<div class="close-button-container">
  <img
    class="close-button"
    [matDialogClose]="simpleModalUserChoices.CANCEL"
    src="/assets/images/close.svg"
    alt="" />
</div>
<ng-container *ngIf="modalData?.image">
  <img class="modal-img" [src]="modalData.image" alt="" />
</ng-container>

<ng-container *ngIf="modalData?.iconName">
  <mat-icon
    class="modal-svg"
    svgIcon="{{ modalData?.iconName }}"
    [ngClass]="modalData?.iconSize"></mat-icon>
</ng-container>
<h2 class="mat-dialog-title">{{ modalData?.title | transloco }}</h2>
<div mat-dialog-content>
  <p *ngFor="let item of modalData?.content">{{ item | transloco }}</p>
</div>
<ng-container *ngIf="modalData?.actions !== undefined">
  <div class="button-container">
    <button
      mat-button
      id="hc-button-basic"
      *ngIf="modalData.actions.optional !== undefined"
      (click)="modalData.actions.optional.action(modalRef)">
      {{ modalData.actions.optional.buttonName | transloco }}
    </button>
    <button
      mat-button
      id="hc-button-primary"
      *ngIf="modalData.actions.positive !== undefined"
      (click)="modalData.actions.positive.action(modalRef)">
      {{ modalData.actions.positive.buttonName | transloco }}
    </button>
    <button
      mat-button
      id="hc-button-decline"
      *ngIf="modalData.actions.negative !== undefined"
      (click)="modalData.actions.negative.action(modalRef)">
      {{ modalData.actions.negative.buttonName | transloco }}
    </button>
    <button
      mat-button
      id="hc-button-no-border"
      *ngIf="modalData.actions.neutral !== undefined"
      (click)="modalData.actions.neutral.action(modalRef)">
      {{ modalData.actions.neutral.buttonName | transloco }}
    </button>
  </div>
</ng-container>
