import { Inject, Injectable } from '@angular/core';

import { WINDOW_TOKEN } from 'src/app/injection-tokens/injection-tokens';
import { checkFormBaseURL } from '../utils/checkFormBaseURL';

@Injectable({
  providedIn: 'root',
})
export class GpsnrPasswordSetupGuard {
  constructor(@Inject(WINDOW_TOKEN) private window: Window) {}

  canActivate(): boolean {
    if (checkFormBaseURL(this.window.location.href)) {
      return true;
    }
    return false;
  }
}
