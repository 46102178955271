<div class="si-list">
  <ng-container *ngIf="producers?.length > 1">
    <mat-form-field id="hc-dropdown" floatLabel="never" class="dropdown">
      <mat-select
        id="mat-select"
        (selectionChange)="onSwitchSIList($event.value)"
        [(value)]="selectedProducer"
        [placeholder]="'SELECT_PLACEHOLDER' | transloco">
        <mat-select-trigger class="hc-select-trigger">
          <span>{{ "COMMON.PRODUCER" | transloco }}:</span>
          <span> {{ selectedProducer }}</span>
        </mat-select-trigger>
        <mat-option *ngFor="let producer of producers" [value]="producer">
          {{ producer }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <div class="search-bar">
    <img class="search-icon" src="/assets/images/search.svg" alt="" />
    <input
      class="search-input"
      [(ngModel)]="search"
      (ngModelChange)="searchList()"
      type="search"
      [placeholder]="'SEARCH_SI_NUMBER' | transloco" />
  </div>

  <div class="si-list-body">
    <ng-container *ngFor="let siData of siListSearched">
      <button
        class="link"
        [ngClass]="{ active: showHighlight(siData) }"
        (click)="onSelectSIData(siData)">
        <div class="si-row">{{ showSINumber(siData) || "-" }}</div>
      </button>
    </ng-container>
    <div class="no-records" *ngIf="siListSearched?.length === 0">
      {{ "COMMON.NO_RECORDS_FOUND" | transloco }}
    </div>
  </div>
</div>
