<div class="chat-room">
  <header class="chat-header">
    <div class="chat-header-label">
      <div *ngIf="room.unread_messages > 0" class="icon-chat-active-dot"></div>
      <div class="icon-user-chat chat-user-icon"></div>
      <div class="chat-label">{{ room?.name }}</div>
      <div
        class="online-status"
        [ngClass]="{ online: isonline, offline: !isonline }"></div>
    </div>
    <div class="chat-header-action">
      <div
        class="action-icon"
        (click)="togglePopup()"
        (keyup.enter)="togglePopup()"
        tabindex="0">
        <img
          src="{{
            openPopup ? '/assets/images/down.svg' : '/assets/images/up.svg'
          }}"
          alt="" />
      </div>
      <div
        class="action-icon"
        (click)="close()"
        (keyup.enter)="close()"
        tabindex="0">
        <img src="/assets/images/close.svg" alt="" />
      </div>
    </div>
  </header>
  <div class="error-message" *ngIf="room?.error !== ''">
    <div class="error-content">{{ room?.error }}</div>
    <div
      class="action-icon"
      (click)="closeError()"
      (keyup.enter)="closeError()"
      tabindex="0">
      <img src="/assets/images/close.svg" alt="" />
    </div>
  </div>
  <div class="chat-body" *ngIf="openPopup">
    <div class="chat-content" #chatContent (scroll)="onChatScroll()">
      <ul class="chat-list">
        <li *ngFor="let chat of chatMessages">
          <app-chat-message
            (openmenu)="openDialogTool($event, chat)"
            [chat]="chat"></app-chat-message>
        </li>
      </ul>
      <ng-container *ngIf="focusMessage?.type !== 'quotation'">
        <div
          class="drop-box drop-box-chat-message-menu"
          tabindex="-1"
          (focusout)="closeMenu()"
          #menu
          *ngIf="openMenu"
          [ngStyle]="menuStyle">
          <div
            class="more-menu-item"
            (click)="reply()"
            (keyup.enter)="reply()"
            tabindex="0">
            {{ "COMMON.REPLY" | transloco }}
          </div>
          <div
            class="more-menu-item"
            (click)="forwardMessage()"
            (keyup.enter)="forwardMessage()"
            tabindex="0">
            {{ "COMMON.FORWARD_MESSAGE" | transloco }}
          </div>
          <div
            class="more-menu-item"
            (click)="saveMessage()"
            (keyup.enter)="saveMessage()"
            tabindex="0">
            {{ "COMMON.SAVE_MESSAGE" | transloco }}
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="focusMessage?.type === 'quotation'">
        <div
          class="drop-box drop-box-chat-message-menu"
          tabindex="-1"
          (focusout)="closeMenu()"
          #menu
          *ngIf="openMenu"
          [ngStyle]="menuStyle">
          <div
            class="more-menu-item"
            (click)="saveMessage()"
            (keyup.enter)="saveMessage()"
            tabindex="0">
            {{ "COMMON.SAVE_MESAGE" | transloco }}
          </div>
        </div>
      </ng-container>
    </div>

    <div class="chat-action">
      <div class="reply-message" *ngIf="replyMessage">
        <div class="chat-header">
          <div class="chat-header-label">
            {{ "COMMON.REPLY" | transloco }}
          </div>
          <div class="chat-header-action">
            <div
              class="action-icon"
              (click)="cancelReply()"
              (keyup.enter)="cancelReply()"
              tabindex="0">
              <img src="/assets/images/close.svg" alt="" />
            </div>
          </div>
        </div>
        <app-chat-message
          [canaction]="false"
          [chat]="replyMessage"></app-chat-message>
      </div>
      <div class="chat-input">
        <textarea
          type="text"
          class="chatbox"
          (focus)="activeRoom()"
          (focusout)="inactiveRoom()"
          (keyup)="checkLine($event)"
          (keypress)="enterSend($event)"
          #chatbox
          [placeholder]="
            'COMMON.TYPE_YOUR_MESSAGE_HERE' | transloco
          "></textarea>
        <div class="action-icon">
          <div
            class="svg-icon"
            *ngIf="svgContents['chat-sender']"
            [innerHTML]="svgContents['chat-sender'] | safeHtml"></div>
        </div>
      </div>
    </div>
  </div>
</div>
