import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { WINDOW_TOKEN } from 'src/app/injection-tokens/injection-tokens';
import { Manufacturer } from '../helpers/enum-manufacturer';
import { GPSNRForm } from '../models/api/responses/user-info';
import { StorageService } from '../services/storage.service';
import { checkFormBaseURL } from '../utils/checkFormBaseURL';

@Injectable({
  providedIn: 'root',
})
export class GPSNRFormGuard {
  constructor(
    private storage: StorageService,
    private router: Router,
    @Inject(WINDOW_TOKEN) private window: Window,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree {
    const gpsnrForms = this.storage.retrieve<GPSNRForm[]>('gpsnrForm') ?? [];
    const allowedForms = gpsnrForms.map((item) => item.manufacturer);
    let formToDisplay = '';
    if (state.url === '/continental-gpsnr-form') {
      formToDisplay = Manufacturer.CONTINENTAL;
    } else if (state.url === '/pirelli-gpsnr-form') {
      formToDisplay = Manufacturer.PIRELLI;
    }

    if (
      checkFormBaseURL(this.window.location.href) &&
      this.storage.check('Authorization') &&
      allowedForms.includes(formToDisplay)
    ) {
      return true;
    }

    if (state.url === '/continental-gpsnr-form') {
      return this.router.parseUrl('/continental');
    }

    if (state.url === '/pirelli-gpsnr-form') {
      return this.router.parseUrl('/pirelli');
    }

    console.warn('Path not found', state.url);
    return false;
  }
}
