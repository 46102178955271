<div>
  <div class="search-container">
    <mat-form-field appearance="none">
      <input
        matInput
        (keydown.enter)="handleSearch()"
        [(ngModel)]="searchText"
        type="text"
        [placeholder]="'COMMON.WHAT_SI_YOU_ARE_LOOKING' | transloco" />
    </mat-form-field>

    <div class="right-side-buttons">
      <mat-icon class="search-icon" color="primary" (click)="handleSearch()"
        >search</mat-icon
      >
      <div class="vertical-divider"></div>
      <button
        mat-stroked-button
        color="primary"
        (click)="isExpanded = !isExpanded">
        <mat-icon *ngIf="!isExpanded" color="primary">expand_more</mat-icon>
        <mat-icon *ngIf="isExpanded" color="primary">expand_less</mat-icon>
        {{ "COMMON.FILTERS" | transloco }}
      </button>
    </div>
  </div>
  <div *ngIf="isExpanded">
    <div>
      <div class="bootstrap-wrapper">
        <div class="container-fluid">
          <div class="row">
            <ng-container *ngFor="let filter of filters">
              <div class="col-lg-3 col-md-6">
                <mat-form-field id="hc-dropdown" floatLabel="never">
                  <mat-select
                    (selectionChange)="handleFilterChange($event.value, filter)"
                    [multiple]="filter.multiple"
                    (openedChange)="handleToggleChange($event, filter)"
                    [(value)]="filter.selectedOption"
                    [placeholder]="
                      filter.placeHolderText | transloco | titlecase
                    ">
                    <mat-select-trigger
                      *ngIf="
                        filter.multiple && filter?.selectedOption?.length >= 1
                      ">
                      {{ getSelectedText(filter?.selectedOption) | transloco }}
                      <span
                        class="select-count"
                        *ngIf="filter?.selectedOption?.length > 1">
                        {{ filter?.selectedOption?.length }}
                      </span>
                    </mat-select-trigger>
                    <mat-option
                      *ngFor="let option of filter.options"
                      [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="filterCount > 0">
      <div class="chips-spacing"><hr /></div>
    </ng-container>

    <div class="chips">
      <mat-chip-list>
        <ng-container
          *ngFor="let chip of chips; trackBy: trackBySelectedOptions">
          <mat-chip
            *ngFor="let option of chip.options"
            (removed)="handleChipRemove(chip.filter, option)">
            {{ option }}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </ng-container>
      </mat-chip-list>
      <div class="clear-filter-button" *ngIf="filterCount > 0">
        <button
          mat-stroked-button
          color="primary"
          (click)="handleResetFilter()">
          {{ "COMMON.CLEAR_FILTERS" | transloco }}
        </button>
      </div>
    </div>
  </div>
</div>
