import { Component, Input } from '@angular/core';
import { ChartConfiguration, ChartType } from 'chart.js';
import { ChartConfig } from 'src/app/models/chart-config';

import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styles: [
    `
      h3 {
        margin-top: unset;
      }

      div {
        position: relative;
        height: 40vh;
      }
    `,
  ],
})
export class BarChartComponent {
  @Input() dataSource: ChartConfig;

  barChartType: ChartType = 'bar';
  barChartLegend = false;
  barChartOptions: ChartConfiguration<'bar'>['options'] = {
    maintainAspectRatio: false,
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback(value: number, index, values) {
            if (value >= 1000) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            } else {
              return value;
            }
          },
        },
      },
    },
    backgroundColor: '#95ace9',
    borderColor: '#95ace9',
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.parsed.y as number;
            if (value >= 1000) {
              return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            } else {
              return value.toFixed(2);
            }
          },
        },
      },
    },
  };

  barChartPlugins = [DataLabelsPlugin];
}
