<div id="hc-modal">
  <div class="hc-navigation-modal-container">
    <img
      src="../../../assets/images/close.svg"
      class="modal-close"
      alt=""
      (click)="onCloseClick()"
      (keyup.enter)="onCloseClick()"
      tabindex="0" />
    <div *ngIf="modalData.image">
      <img
        src="../../../assets/images/{{ modalData.image }}"
        alt=""
        width="300" />
    </div>

    <h3 class="modal-title">{{ modalData.title | transloco }}</h3>
    <p class="modal-text">{{ modalData.content | transloco }}</p>

    <div class="button-container">
      <ng-container
        *ngFor="let button of modalData.buttons; first as isFirst; index as i">
        <button
          mat-flat-button
          id="{{
            isFirst ? 'hc-modal-no-border-button' : 'hc-modal-primary-button'
          }}"
          (click)="navigate(button.path)">
          {{ button.name | transloco }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
