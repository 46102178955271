<form #f="ngForm" [formGroup]="phoneForm">
  <ngx-intl-tel-input
    [cssClass]="'tel-input'"
    [enableAutoCountrySelect]="false"
    [enablePlaceholder]="false"
    [searchCountryFlag]="true"
    [searchCountryField]="[SearchCountryField.Name]"
    [selectedCountryISO]="
      savedCountryISO === '' ? defaultCountryISO : savedCountryISO
    "
    [maxLength]="15"
    [phoneValidation]="false"
    [separateDialCode]="true"
    [numberFormat]="PhoneNumberFormat.National"
    name="phone"
    formControlName="phone">
  </ngx-intl-tel-input>
</form>
