import { Pipe, PipeTransform } from '@angular/core';
import tradeTime from 'src/assets/content/tradeTime.json';
/*
 * Returns the trade time name
 */

@Pipe({ name: 'tradeTime' })
export class TradeTimePipe implements PipeTransform {
  transform(value: number): string | null {
    const time = tradeTime.find((t) => t.value === value);
    if (time) {
      return time.name;
    }
    return null;
  }
}
