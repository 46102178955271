import { Component, OnInit } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { FeaturePermissionService } from 'src/app/services/feature-permission.service';
import { NavItem } from './main-menu';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  mainMenuItems: NavItem[];
  defaultMatchOptions: IsActiveMatchOptions = {
    paths: 'subset',
    queryParams: 'ignored',
    fragment: 'ignored',
    matrixParams: 'ignored',
  };

  constructor(
    private router: Router,
    private featurePermissionService: FeaturePermissionService,
  ) {}

  ngOnInit(): void {
    this.mainMenuItems = this.featurePermissionService.getMainMenuItems();
    this.expandActiveParentMenu(this.mainMenuItems);
  }

  /**
   * Check whether the menu item is a parent menu
   * @param menuItem
   * @returns true if menu item is a parent (i.e. has children), false otherwise.
   */
  hasChildMenu(menuItem: NavItem) {
    return menuItem.children.length > 0;
  }

  /**
   * Find the active parent menu and expands it children
   * @param menuItems list of menu items
   */
  expandActiveParentMenu(menuItems: NavItem[]) {
    const activeParentMenu = menuItems.find(
      (menuItem) =>
        this.isMenuItemActive(menuItem.route, this.defaultMatchOptions) &&
        this.hasChildMenu(menuItem),
    );
    if (activeParentMenu) {
      activeParentMenu.expanded = true;
    }
  }

  /**
   * Determine whether the provided route string is currently activated based on the
   * provided route matching options.
   * @param route the route string of the menu item
   * @param exact route matching options for determining whether the route string
   * is activated. Specifying whether route string/query params/fragments
   * should be matched as subset/exact.
   * @returns true if route given is active, false otherwise
   */
  isMenuItemActive(route: string, isActiveMatchOptions: IsActiveMatchOptions) {
    return this.router.isActive(route, isActiveMatchOptions);
  }

  /**
   * Toggle the expanded state of the menu item. It will also close any previously expanded
   * menu item, i.e, ensure that only 1 menu item is ever expanded at any moment.
   * @param toggledItem the menu item being toggled by the user
   */
  toggleMenuExpansion(toggledItem: NavItem) {
    this.mainMenuItems
      .filter((menuItem) => menuItem !== toggledItem)
      .forEach((menuItem) => {
        menuItem.expanded = false;
      });
    toggledItem.expanded = !toggledItem.expanded;
  }
}
