import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ArpLightweightChartDirective } from 'src/app/directives/arp-lightweight-chart/arp-lightweight-chart.directive';
import { FxDisplayLogicService } from 'src/app/services/fx-display-logic.service';

@Component({
  selector: 'arp-fx-display',
  templateUrl: './arp-fx-display.component.html',
  styleUrls: ['./arp-fx-display.component.scss'],
  providers: [FxDisplayLogicService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArpFxDisplayComponent implements OnInit, OnDestroy {
  /**
   * Private reference to lightweight chart directive
   */
  private _chartDirective: ArpLightweightChartDirective;
  /**
   * View Query for the lightweight chart directive,
   * combined with a setter to run assignment operations
   * when the view changes. Assignment operations are guarded
   * by a diff check to prevent unnecessary operations.
   */
  @ViewChild(ArpLightweightChartDirective) set chartDirective(
    chartDirective: ArpLightweightChartDirective,
  ) {
    if (
      this._chartDirective !== chartDirective &&
      chartDirective !== undefined
    ) {
      this._chartDirective = chartDirective;
      this.onChartDirectiveChange(chartDirective);
    }
  }
  /**
   * Retrieve the sixStreamStatus$ stream from the FxDisplayLogicService
   */
  readonly sixStreamStatus$ = this.logicService.sixStreamStatus$;
  /**
   * Retrieve the lastUpdatedTimeString$ stream from the FxDisplayLogicService
   */
  readonly lastUpdatedTimeString$ = this.logicService.lastUpdatedTimeString$;
  /**
   * Retrieve the chartLoading$ stream from the FxDisplayLogicService
   */
  readonly chartLoading$ = this.logicService.chartLoading$;
  /**
   * Retrieve the currencyPairSelectField$ stream from the FxDisplayLogicService
   */
  readonly currencyPairSelectField$ =
    this.logicService.currencyPairSelectField$;
  /**
   * Retrieve the rangeToggleField$ stream from the FxDisplayLogicService
   */
  readonly rangeToggleField$ = this.logicService.rangeToggleField$;
  /**
   * Retrieve the selectedFx$ stream from the FxDisplayLogicService
   */
  readonly selectedFx$ = this.logicService.selectedFx$;
  /**
   * Retrieve the fx$ stream from the FxDisplayLogicService
   */
  readonly fx$ = this.logicService.fx$;

  constructor(private logicService: FxDisplayLogicService) {}
  /**
   * Initialize the states of the service
   */
  ngOnInit(): void {
    this.logicService.initializeStates();
  }

  /**
   * Cleanup the logic service
   */
  ngOnDestroy(): void {
    this.logicService.cleanUp();
  }
  /**
   * Handle the currency pair selected by user
   * @param currencyPair currency pair string
   */
  onSelectCurrencyPair(currencyPair: string) {
    this.logicService.selectCurrencyPair(currencyPair);
  }
  /**
   * Handle the range selected by user
   * @param event MatButtonToggleChange event
   */
  onSelectRange(event: MatButtonToggleChange) {
    this.logicService.selectRange(event.value);
  }
  /**
   * Push new chart references that are queried by ViewChild,
   * only if the refs actually change, to the logic service
   * @param chartDirective the lightweight chart directive
   */
  onChartDirectiveChange(chartDirective: ArpLightweightChartDirective) {
    this.logicService.updateChartRefs(chartDirective);
  }
}
