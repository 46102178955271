import { Injectable } from '@angular/core';
import { Observable, combineLatest, map, scan, zip } from 'rxjs';
import { ArpFxDisplayComponent } from '../modules/price-widget/arp-fx-display/arp-fx-display.component';
import { ArpPhysicalIndexComponent } from '../modules/price-widget/arp-physical-index/arp-physical-index.component';
import { PriceWidget } from '../modules/price-widget/arp-price-widget/arp-price-widget';
import { ArpSicomDisplayComponent } from '../modules/price-widget/arp-sicom-display/arp-sicom-display.component';
import {
  FxStream,
  PhysicalIndexStream,
  SicomPrice,
  SicomStream,
  SixStreamStatus,
  StreamState,
} from '../models/sicom-latest-price';
import { SixStreamService } from './six-stream.service';

@Injectable()
export class PriceWidgetService {
  /**
   * Compute the selected market stream
   */
  private selectedMarket$: Observable<string | string[]>;
  /**
   * Compute the selected month stream
   */
  private selectedMonth$: Observable<string | string[]>;
  /**
   * Compute the selected currency pair stream
   */
  private selectedCurrencyPair$: Observable<string | string[]>;
  /**
   * The sicom$ stream from the SixStreamService
   */
  private sicom$: Observable<SicomStream>;
  /**
   * The fx$ stream from the SixStreamService
   */
  private fx$: Observable<FxStream>;
  /**
   * The physicalIndex$ stream from the SixStreamService
   */
  private physicalIndex$: Observable<PhysicalIndexStream>;
  /**
   * Retrieve the sixStreamStatus$ stream from the SixStreamService
   */
  private sixStreamStatus$: Observable<SixStreamStatus>;
  /**
   * The css class for the width of the price widget component
   */
  get widgetWidthClass$() {
    return this.sixStreamStatus$.pipe(
      map((streamStatus) => this.getWidgetWidthClass(streamStatus.state)),
    );
  }
  /**
   * Observable streaming the price widgets data. The widget data are
   * computed from various sources: the sicom/fx/physical-index streams, the
   * selectedMarket$ stream, the selectedMonth$ stream and the
   * selectedCurrencyPair$ stream.
   * Because the NguCarousel is not able to handle dynamic PriceWidget[] in
   * immutable manner (i.e. it will keep adding new NguItem to the DOM when
   * receiving immutable PriceWidget[], causing increasing memory usage),
   * we need to use scan operator to accumulate the price widgets data,
   * mutate the necessary data and return
   * the mutated array instead of a new, immutable array.
   */
  get priceWidgets$() {
    return combineLatest([
      zip([this.sicom$, this.fx$]),
      this.physicalIndex$,
      this.selectedMarket$,
      this.selectedMonth$,
      this.selectedCurrencyPair$,
      this.sixStreamStatus$,
    ]).pipe(
      scan((accumulator, widgetData, index) => {
        const [
          [sicomStream, fxStream],
          physicalIndexStream,
          selectedMarket,
          selectedMonth,
          selectedCurrencyPair,
          sixStreamStatus,
        ] = widgetData;
        if (index === 0) {
          const priceWidgets = this.createPriceWidget(
            sicomStream,
            fxStream,
            physicalIndexStream,
            selectedMarket as string,
            selectedMonth as string,
            selectedCurrencyPair as string,
            sixStreamStatus,
          );
          accumulator.push(...priceWidgets);
          return accumulator;
        } else {
          this.updateSicomWidgetData(
            accumulator[0],
            sicomStream,
            selectedMarket as string,
            selectedMonth as string,
            sixStreamStatus,
          );
          this.updateFxWidgetData(
            accumulator[1],
            fxStream,
            selectedCurrencyPair as string,
            sixStreamStatus,
          );
          this.updatePhysicalIndexWidgetData(
            accumulator[2],
            physicalIndexStream,
          );
          return accumulator;
        }
      }, [] as PriceWidget[]),
    );
  }

  constructor(private sixStreamService: SixStreamService) {
    this.initilizeStates();
  }

  /**
   * Initialize the internal states of PriceWidgetService
   */
  initilizeStates() {
    this.selectedMarket$ = this.sixStreamService.marketSelectField$.pipe(
      map((marketSelect) => marketSelect.selectedOptions),
    );
    this.selectedMonth$ = this.sixStreamService.monthSelectField$.pipe(
      map((monthSelect) => monthSelect.selectedOptions),
    );
    this.selectedCurrencyPair$ =
      this.sixStreamService.currencyPairSelectField$.pipe(
        map((currencyPairSelect) => currencyPairSelect.selectedOptions),
      );
    this.sicom$ = this.sixStreamService.sicom$;
    this.fx$ = this.sixStreamService.fx$;
    this.physicalIndex$ = this.sixStreamService.physicalIndex$;
    this.sixStreamStatus$ = this.sixStreamService.sixStreamStatus$;
  }

  /**
   * Combine the given sources to compute the price widgets data
   * for displaying in the price widget component
   * @param sicomStream sicom stream
   * @param fxStream fx stream
   * @param physicalIndexStream physical index stream
   * @param selectedMarket selected market, e.g. RSS, TSR20
   * @param selectedMonth selected month, e.g. 2021-06-01
   * @param selectedCurrencyPair selected currency pair, e.g. USD/SGD
   * @param sixStreamStatus the stream status of the six stream
   * @returns an array of price widgets data to be displayed by the
   * price widget component
   */
  createPriceWidget(
    sicomStream: SicomStream,
    fxStream: FxStream,
    physicalIndexStream: PhysicalIndexStream,
    selectedMarket: string,
    selectedMonth: string,
    selectedCurrencyPair: string,
    sixStreamStatus: SixStreamStatus,
  ): PriceWidget[] {
    const selectedSicomPrice: SicomPrice<number> =
      sicomStream[selectedMarket][selectedMonth];
    const change = this.calculateSicomChange(selectedSicomPrice);
    return [
      {
        streamState: sixStreamStatus.state,
        title: 'SICOM Prices',
        price: selectedSicomPrice?.last_trade,
        priceFormat: '1.2-2',
        change: change.change,
        changePercent: change.changePercent,
        changeDirection: change.changeDirection,
        toolTip: sixStreamStatus.toolTip,
        displayComponent: ArpSicomDisplayComponent,
      },
      {
        streamState: sixStreamStatus.state,
        title: 'FX Mid Market',
        price: fxStream?.[selectedCurrencyPair],
        priceFormat: '1.5-5',
        toolTip: sixStreamStatus.toolTip,
        currencyPair: selectedCurrencyPair,
        displayComponent: ArpFxDisplayComponent,
      },
      {
        title: 'Physical Index',
        price: physicalIndexStream.physical_price_index,
        priceFormat: '1.2-2',
        displayComponent: ArpPhysicalIndexComponent,
      },
    ];
  }
  /**
   * Calculate the change and change percentage of the given sicom price
   * @param sicomPrice
   * @returns the change and change percentage of the given sicom price
   */
  calculateSicomChange(sicomPrice: SicomPrice<number>) {
    const change =
      sicomPrice?.last_trade - sicomPrice?.most_recent_settlement_price;
    const changePercent =
      (change / sicomPrice?.most_recent_settlement_price) * 100;
    return {
      change,
      changePercent,
      changeDirection:
        change > 0 ? 'positive' : change < 0 ? 'negative' : 'flat',
    };
  }

  /**
   * Mutate the given sicomWidgetData with the latest sicom price
   * @param sicomWidgetData the sicom widget data to be mutated
   * @param sicomStream the sicom stream
   * @param selectedMarket the selected market
   * @param selectedMonth the selected month
   * @param sixStreamStatus the status of the six stream
   */
  updateSicomWidgetData(
    sicomWidgetData: PriceWidget,
    sicomStream: SicomStream,
    selectedMarket: string,
    selectedMonth: string,
    sixStreamStatus: SixStreamStatus,
  ) {
    const selectedSicomPrice: SicomPrice<number> =
      sicomStream[selectedMarket][selectedMonth];
    const change = this.calculateSicomChange(selectedSicomPrice);
    sicomWidgetData.streamState = sixStreamStatus.state;
    sicomWidgetData.price = selectedSicomPrice?.last_trade;
    sicomWidgetData.change = change.change;
    sicomWidgetData.changePercent = change.changePercent;
    sicomWidgetData.changeDirection = change.changeDirection;
    sicomWidgetData.toolTip = sixStreamStatus.toolTip;
  }

  /**
   * Mutate the given fxWidgetData with the latest fx price
   * @param fxWidgetData the fx widget data to be mutated
   * @param fxStream the fx stream
   * @param selectedCurrencyPair the selected currency pair
   * @param sixStreamStatus the status of the six stream
   */
  updateFxWidgetData(
    fxWidgetData: PriceWidget,
    fxStream: FxStream,
    selectedCurrencyPair: string,
    sixStreamStatus: SixStreamStatus,
  ) {
    fxWidgetData.streamState = sixStreamStatus.state;
    fxWidgetData.price = fxStream?.[selectedCurrencyPair];
    fxWidgetData.toolTip = sixStreamStatus.toolTip;
    fxWidgetData.currencyPair = selectedCurrencyPair;
  }

  /**
   * Mutate the given physicalIndexWidgetData with the latest physical index price
   * @param physicalIndexWidgetData the physical index widget data to be mutated
   * @param physicalIndexStream the physical index stream
   */
  updatePhysicalIndexWidgetData(
    physicalIndexWidgetData: PriceWidget,
    physicalIndexStream: PhysicalIndexStream,
  ) {
    physicalIndexWidgetData.price = physicalIndexStream.physical_price_index;
  }

  /**
   * Return the css class for the width of the price widget component
   * based on the status of the six stream, provide less/more width
   * to hide/show the stream status icon
   * @param streamStatus the stream status of the six stream
   * @returns the width css class of the price widget component
   */
  getWidgetWidthClass(streamStatus: StreamState) {
    return streamStatus === StreamState.LIVE ? 'shorter' : 'longer';
  }
}
