import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MatLegacyDialog,
  MatLegacyDialogConfig,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { ProfileName } from 'src/app/models/profile-name';
import { LoginService } from 'src/app/services/login.service';
import { StorageService } from 'src/app/services/storage.service';
import { Subscription } from 'rxjs';
import { MixpanelService } from 'src/app/services/mixpanel.service';
import { ProfileImageService } from 'src/app/services/profile-image.service';
import { TouViewerComponent } from '../../../components/modal/tou-viewer/tou-viewer.component';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent implements OnInit, OnDestroy {
  /**
   * Name of the company this use belongs to
   */
  companyName: string;
  /**
   * Name of the user
   */
  profileName: string;
  /**
   * For managing states of the dropdown menu
   */
  showProfileDropDown: boolean;
  /**
   * Default image source for user profile image,
   * if none is provided
   */
  defaultImageSource = '/assets/images/user.svg';
  /**
   * The image src that is actually bound to template
   */
  imageSource = this.defaultImageSource;
  /**
   * To manage subscription to profile image service
   */
  subscription: Subscription;

  constructor(
    private storage: StorageService,
    private loginService: LoginService,
    private profileImageService: ProfileImageService,
    private router: Router,
    private dialog: MatLegacyDialog,
    private eRef: ElementRef,
    private mixpanelService: MixpanelService,
  ) {}

  /**
   * Initialize the component
   */
  ngOnInit(): void {
    const profile = this.storage.retrieve<ProfileName>('profileName');
    this.profileName = profile.name;
    this.companyName = profile.companyName;
    this.showProfileDropDown = false;
    this.subscription = this.profileImageService.currentData.subscribe(
      (data) => {
        if (data === null) {
          this.imageSource = this.defaultImageSource;
        } else if (data !== undefined) {
          this.imageSource = data;
        }
      },
    );
  }

  /**
   * Unsubscribe from the profile image service
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * HostListener for detecting when user clicks outside of
   * the top menu component
   * @param event event dispatched by the click action
   */
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      // outside click
      this.showProfileDropDown = false;
    }
  }

  /**
   * Toggle the dropdown menu when user clicks on the profile
   * icon
   */
  onProfileClick() {
    this.showProfileDropDown = !this.showProfileDropDown;
  }

  /**
   * When the user clicks on logout button,
   * 1. Send logout request to backend to invalidate the session
   * 2. Store the lastLogout time in local storage
   * 3. Clear the local storage
   * 4. Terminate the mixpanel user tracking
   * 5. Navigate to logout page
   */
  onLogoutClick() {
    this.loginService.postLogout().subscribe((_) => {
      this.storage.store('lastLogout', Date.now());
      this.storage.clear();
      this.mixpanelService.reset();
      this.router.navigate(['transit/logout']);
    });
  }

  /**
   * When the user clicks on "My settings", navigate to
   * "profile/settings" page.
   */
  onSettingClick() {
    this.router.navigate(['profile/settings']);
  }

  /**
   * When the user clicks on the "Edit profile", navigate to
   * "profile/info" page.
   */
  onProfileURLClick() {
    this.router.navigate(['profile/info']);
  }

  /**
   * When the user clicks on the "Support", navigate to
   * "support/customer-care" page.
   * @todo VU: refactor support page routing module to remove queryParams
   */
  onSupportURLClick() {
    this.router.navigate(['support/customer-care'], {
      queryParams: { state: 'support' },
    });
  }

  /**
   * When the user clicks on the "Terms of use", open a dialog
   * to display the terms of use.
   */
  onToUClick(): void {
    const dialogConfig: MatLegacyDialogConfig = {
      disableClose: true,
      panelClass: 'simple-mat-modal',
    };
    this.dialog.open(TouViewerComponent, dialogConfig);
  }
}
