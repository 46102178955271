import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'arp-step-control-panel',
  templateUrl: './step-control-panel.component.html',
  styleUrls: ['./step-control-panel.component.scss'],
})
export class StepControlPanelComponent {
  @Output() nextEvent = new EventEmitter<void>();
  @Output() backEvent = new EventEmitter<void>();
  @Output() saveEvent = new EventEmitter<void>();

  @Input() nextButtonText = 'Next';
  @Input() nextButtonDisabled = false;
}
