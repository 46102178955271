<div class="title-bar">
  <h2 class="hc-title-text">{{ "COMMON.STARRED_CHATS" | transloco }}</h2>
</div>

<div class="search-bar">
  <input
    [(ngModel)]="search"
    (ngModelChange)="searchList($event)"
    type="text"
    class="hc-search"
    [placeholder]="'COMMON.SEARCH_CHAT' | transloco" />
</div>
<div class="maximize-body">
  <div class="grouping">
    <div class="grouping-header">
      <div
        class="header-chat-title"
        (click)="chatRoomExpansion.chatGroup = !chatRoomExpansion.chatGroup"
        (keyup.enter)="
          chatRoomExpansion.chatGroup = !chatRoomExpansion.chatGroup
        "
        tabindex="0">
        <div *ngIf="chatRoomExpansion.chatGroup" class="expand-icon"></div>
        <div
          *ngIf="!chatRoomExpansion.chatGroup"
          class="close-right-icon"></div>
        <div class="second-label">{{ "COMMON.GROUP_CHAT" | transloco }}</div>
      </div>

      <!-- plus icon will be  here -->
    </div>
    <div class="grouping-body" *ngIf="chatRoomExpansion.chatGroup">
      <div
        (click)="openChat(room)"
        (keyup.enter)="openChat(room)"
        tabindex="0"
        class="chat"
        *ngFor="let room of chatListSearched.chatGroupList">
        <div class="icon-user-chat"></div>
        <div class="title-user-chat">
          {{ room.name }}
        </div>
      </div>
    </div>
  </div>
  <div class="grouping">
    <div class="grouping-header">
      <div
        class="header-chat-title"
        (click)="chatRoomExpansion.chatUser = !chatRoomExpansion.chatUser"
        (keyup.enter)="chatRoomExpansion.chatUser = !chatRoomExpansion.chatUser"
        tabindex="0">
        <div *ngIf="chatRoomExpansion.chatUser" class="expand-icon"></div>
        <div *ngIf="!chatRoomExpansion.chatUser" class="close-right-icon"></div>
        <div class="second-label">
          {{ "COMMON.INDIVIDUAL_CHAT" | transloco }}
        </div>
      </div>
      <!-- plus icon will be  here -->
    </div>
    <div class="grouping-body" *ngIf="chatRoomExpansion.chatUser">
      <div
        (click)="openChat(room)"
        (keyup.enter)="openChat(room)"
        tabindex="0"
        class="chat"
        *ngFor="let room of chatListSearched.chatUserList">
        <div class="icon-user-chat"></div>
        <div class="title-user-chat">
          {{ room.name }}
        </div>
      </div>
    </div>
  </div>
</div>
