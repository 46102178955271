import { Component, Input, Output, EventEmitter } from '@angular/core';

import { PasswordSetupService } from 'src/app/services/password-setup.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-gpsnr-password-setup-form',
  templateUrl: './gpsnr-password-setup-form.component.html',
  styleUrls: ['./gpsnr-password-setup-form.component.scss'],
})
export class GpsnrPasswordSetupFormComponent {
  @Input() token: string;
  @Input() uid: string;
  @Output() passwordReset = new EventEmitter();
  password: string;
  passwordConfirmation: string;
  passwordMask = true;
  passwordConfirmMask = true;
  isPasswordHasLetter = false;
  isPasswordHasNumber = false;
  isPasswordLengthEnough = false;
  isPasswordMatch = false;

  constructor(
    private passwordSetupService: PasswordSetupService,
    public store: StorageService,
  ) {}

  validatePassword() {
    this.isPasswordHasLetter = this.password && /[a-zA-Z]/.test(this.password);
    this.isPasswordHasNumber = this.password && /[0-9]/.test(this.password);
    this.isPasswordLengthEnough = this.password && this.password.length > 7;
    this.isPasswordMatch =
      this.password &&
      this.passwordConfirmation &&
      this.password === this.passwordConfirmation;
  }

  validatePasswordMatch() {
    this.isPasswordMatch =
      this.password &&
      this.passwordConfirmation &&
      this.password === this.passwordConfirmation;
  }

  togglePasswordMask() {
    this.passwordMask = !this.passwordMask;
  }

  togglePasswordConfirmMask() {
    this.passwordConfirmMask = !this.passwordConfirmMask;
  }

  hasMissingField() {
    const validPassword =
      this.isPasswordMatch &&
      this.isPasswordHasLetter &&
      this.isPasswordLengthEnough &&
      this.isPasswordHasNumber;
    return !(
      validPassword &&
      this.password &&
      this.passwordConfirmation &&
      this.password === this.passwordConfirmation
    );
  }

  saveNewPasswordClick() {
    const data = {
      new_password1: this.password,
      new_password2: this.passwordConfirmation,
    };
    this.passwordSetupService
      .postToPasswordReset(this.uid, this.token, data)
      .subscribe(() => {
        this.store.clear();
        this.passwordReset.emit();
      });
  }
}
