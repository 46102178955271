import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { FirebaseAuthService } from 'src/app/services/firebase-auth.service';
import { SixStreamService } from 'src/app/services/six-stream.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements AfterViewInit, OnDestroy {
  /**
   * For subscription management
   */
  destroy$ = new Subject<boolean>();
  /**
   * Boolean to toggle the expansion of the price display
   */
  isPriceDisplayExpanded = false;
  /**
   * The instance of the currently expanded Price Display Component,
   * used to prevent unnecessarily clearing and re-rendering
   * of the same component
   */
  currentlyExpandedComponent: any;
  /**
   * Get template reference to MatSidenav component
   */
  @ViewChild('sidenav') sideNav: MatSidenav;
  /**
   * Get template reference to priceDisplayContainer
   * to dynamically load different types of
   * price display components
   */
  @ViewChild('displayContainer', {
    read: ViewContainerRef,
  })
  priceDisplayContainer: ViewContainerRef;

  theme$ = this.themeService.theme$;

  constructor(
    private bpObserver: BreakpointObserver,
    private sixStreamService: SixStreamService,
    private firebaseAuthService: FirebaseAuthService,
    private router: Router,
    private themeService: ThemeService,
  ) {
    this.sixStreamService.initialize();
  }

  /**
   * 1. Subscribe to breakpoint observer to toggle states of
   * sidenav automatically.
   * 2. Subscribe to router navigation events to automatically
   * close sidenav when in 'over' mode.
   */
  ngAfterViewInit(): void {
    // Observe screensize to change sideNav.mode and
    // open/close sideNav accordingly
    this.bpObserver
      .observe(['(max-width: 768px)'])
      .pipe(takeUntil(this.destroy$), delay(0))
      .subscribe((res: BreakpointState) => {
        if (res.matches) {
          this.sideNav.mode = 'over';
          this.sideNav.close();
        } else {
          this.sideNav.mode = 'side';
          this.sideNav.open();
        }
      });
    // Basically to close the sideNav
    // upon NavigationStart if it is in 'over' mode.
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationStart),
      )
      .subscribe((_) => {
        if (this.sideNav.mode === 'over') {
          this.sideNav.close();
        }
      });
  }

  /**
   * Unsubscribe from all subscriptions
   * Clean up SixStreamService
   * Sign out from Firebase Auth
   */
  ngOnDestroy(): void {
    this.sixStreamService.cleanUp();
    this.firebaseAuthService.signOut();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /**
   * Renders the Price Display Component, then
   * expands the Price Display Container
   */
  expandPriceDisplay(vcr: ViewContainerRef, displayComponent: any): void {
    this.renderPriceDisplay(vcr, displayComponent);
  }

  /**
   * Minimizes the Price Display
   */
  minimizePriceDisplay(): void {
    this.isPriceDisplayExpanded = false;
  }

  /**
   * Clears the view container ref, then renders the
   * given display component, only if the component
   * to be rendered is not the same as the currently rendered component
   * @param vcr the view container ref to clear and render component
   * @param displayComponent the relevant Price Display component to render
   * in the given view container ref
   */
  renderPriceDisplay(vcr: ViewContainerRef, displayComponent: any) {
    if (
      this.currentlyExpandedComponent === undefined ||
      !(this.currentlyExpandedComponent instanceof displayComponent)
    ) {
      vcr.clear();
      const componentRef = vcr.createComponent(displayComponent);
      this.currentlyExpandedComponent = componentRef.instance;
    }
    this.isPriceDisplayExpanded = true;
  }
}
