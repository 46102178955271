import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { map, distinct } from 'rxjs/operators';
import {
  SearchCountryField,
  PhoneNumberFormat,
  CountryISO,
  NgxIntlTelInputComponent,
} from '@justin-s/ngx-intl-tel-input';
import { PhoneNumberData } from 'src/app/models/phone-number-data';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent implements OnInit, OnChanges {
  @Input() initialPhoneNumberData: PhoneNumberData;
  @Output() phoneNumberData = new EventEmitter<PhoneNumberData>();

  @ViewChild(NgxIntlTelInputComponent) telComponent: NgxIntlTelInputComponent;

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pastedInput = event.clipboardData
      .getData('text/plain')
      .replace(/\D+/g, '');
    this.phoneForm.get('phone').patchValue({ number: pastedInput });

    event.preventDefault();
    event.stopPropagation();
  }

  SearchCountryField = SearchCountryField;
  PhoneNumberFormat = PhoneNumberFormat;
  defaultCountryISO = CountryISO.Singapore;
  savedCountryISO = '';
  phoneForm = new FormGroup({
    phone: new FormControl<PhoneNumberData | null>(null),
  });

  ngOnInit(): void {
    this.phoneForm
      .get('phone')
      .valueChanges.pipe(
        distinct((data) => JSON.stringify(data)),
        map((data) => this.mapInput(data)),
      )
      .subscribe((data) => {
        this.phoneNumberData.emit(data);
      });
  }

  ngOnChanges() {
    this.phoneForm
      .get('phone')
      .patchValue({ number: this.initialPhoneNumberData.number });
    // patching countryCode does not update the country flag/dial code, need to update it this way.
    this.savedCountryISO = this.initialPhoneNumberData.countryCode;
  }

  validatePhone(phone: string) {
    return phone.length >= 5;
  }

  private mapInput(data: PhoneNumberData) {
    const telNumber = data?.number || '';
    const countryCode = data?.countryCode || this.defaultCountryISO;
    const dialCode =
      data?.dialCode || `+${this.telComponent.selectedCountry.dialCode}`;
    const isValidPhoneNumber = this.validatePhone(telNumber);

    return {
      dialCode,
      isValidPhoneNumber,
      countryCode: countryCode.toUpperCase(),
      number: telNumber.replace(/\D+/g, ''),
    };
  }
}
