import { ForbiddenMessage } from './types/forbidden-message';
import { LoginService } from './../../../services/login.service';
import { ServerExceptionMessage } from './types/server-exception-message';
import { BadRequestMessage } from './types/bad-request-message';
import { UnAuthorizedMessage } from './types/unauthorized-message';

import { Message } from './message';
import { ErrorMessage } from './types/error-message';
import { InfoMessage } from './types/info-message';
import { WarnMessage } from './types/warn-message';
import { Injectable } from '@angular/core';
import { DefaultMessage } from './types/default-message';
import { HttpStatusCode } from '@angular/common/http';
import { OfflineMessage } from './types/offline-message';
@Injectable({
  providedIn: 'root',
})
export class MessageContainer {
  constructor(private loginService: LoginService) {}
  get<T>(messageType: number): Message {
    const messages = [
      {
        key: 0,
        value: new OfflineMessage(),
      },
      {
        key: 1,
        value: new InfoMessage(),
      },
      {
        key: 2,
        value: new WarnMessage(),
      },
      {
        key: 3,
        value: new ErrorMessage(),
      },
      {
        key: HttpStatusCode.Unauthorized,
        value: new UnAuthorizedMessage(),
      },
      {
        key: HttpStatusCode.BadRequest,
        value: new BadRequestMessage(),
      },
      {
        key: HttpStatusCode.Forbidden,
        value: new ForbiddenMessage(),
      },
      {
        key: HttpStatusCode.InternalServerError,
        value: new ServerExceptionMessage(),
      },
    ];
    const message = messages.find((p) => p.key === messageType);

    // check if exception type not known return the default message..
    if (message) {
      return message.value;
    } else {
      return new DefaultMessage();
    }
  }
}
