import { HttpErrorResponse } from '@angular/common/http';
import { Message } from '../message';

export class OfflineMessage extends Message {
  getMessage(content: string | HttpErrorResponse): Message {
    this.icon = 'info';
    this.color = 'info';
    this.content = 'You have no internet connection!';
    return this;
  }
  close(): void {
    // Close method
  }

  action(): void {
    // Action method
  }
}
