export {};
declare global {
  interface Array<T> {
    firstOrDefault(this): T;
    take(this, n: number): T[];
  }
}
Array.prototype.firstOrDefault = firstOrDefault;
Array.prototype.take = take;
function firstOrDefault() {
  return this.length > 0 ? this[0] : {};
}
function take(n: number) {
  return this.length > 0 ? this.slice(0, n) : {};
}
