import { Component, Inject, Injector, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef,
  MAT_LEGACY_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { TranslocoService } from '@ngneat/transloco';
import { Message } from './message';

@Component({
  selector: 'app-error-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit {
  constructor(
    private injector: Injector,
    private translocoService: TranslocoService,
    private dialogRef: MatLegacyDialogRef<MessageDialogComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public message: Message,
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    const { content } = this.message;
    if (typeof content === 'string') {
      this.message.content = this.translocoService.translate(content);
    }
  }

  handleClose() {
    this.dialogRef.close();
    this.message.close(this.injector);
  }

  handleAction() {
    this.dialogRef.close();
    this.message.action(this.injector);
  }
}
