import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import Format from '@date-format';
import { ArpLightweightChartDirective } from 'src/app/directives/arp-lightweight-chart/arp-lightweight-chart.directive';
import { SicomDisplayLogicService } from 'src/app/services/sicom-display-logic.service';
@Component({
  selector: 'arp-sicom-display',
  templateUrl: './arp-sicom-display.component.html',
  styleUrls: ['./arp-sicom-display.component.scss'],
  providers: [SicomDisplayLogicService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArpSicomDisplayComponent implements OnInit, OnDestroy {
  /**
   * Private reference to lightweight chart directive
   */
  private _chartDirective: ArpLightweightChartDirective;
  /**
   * View Query for the lightweight chart directive,
   * combined with a setter to run assignment operations
   * when the view changes. Assignment operations are guarded
   * by a diff check to prevent unnecessary operations.
   */
  @ViewChild(ArpLightweightChartDirective) set chartDirective(
    chartDirective: ArpLightweightChartDirective,
  ) {
    if (
      this._chartDirective !== chartDirective &&
      chartDirective !== undefined
    ) {
      this._chartDirective = chartDirective;
      this.onChartDirectiveChange(chartDirective);
    }
  }
  /**
   * Retrieve the sixStreamStatus$ stream from the SicomDisplayLogicService
   */
  readonly sixStreamStatus$ = this.logicService.sixStreamStatus$;
  /**
   * Retrieve the lastUpdatedTimeString$ stream from the SicomDisplayLogicService
   */
  readonly lastUpdatedTimeString$ = this.logicService.lastUpdatedTimeString$;
  /**
   * Retrieve the chartLoading$ stream from the SicomDisplayLogicService
   */
  readonly chartLoading$ = this.logicService.chartLoading$;
  /**
   * Retrieve the marketSelectedField stream from the SicomDisplayLogicService
   */
  readonly marketSelectField$ = this.logicService.marketSelectField$;
  /**
   * Retrieve the monthSelectField stream from the SicomDisplayLogicService
   */
  readonly monthSelectField$ = this.logicService.monthSelectField$;
  /**
   * Retrieve the rangeToggleField stream from the SicomDisplayLogicService
   */
  readonly rangeToggleField$ = this.logicService.rangeToggleField$;
  /**
   * Retrieve the selectedSicom stream from the SicomDisplayLogicService
   */
  readonly selectedSicom$ = this.logicService.selectedSicom$;
  /**
   * Month format string for date pipe
   */
  monthFormat = Format.monthFormat;

  constructor(private logicService: SicomDisplayLogicService) {}

  /**
   * Initialize component's states.
   */
  ngOnInit(): void {
    this.logicService.initializeStates();
  }

  /**
   * Emit signal to terminate component's subscription
   */
  ngOnDestroy(): void {
    this.logicService.cleanUp();
  }

  /**
   * Handle the market selected by user
   * @param market market name
   */
  onSelectMarket(market: string) {
    this.logicService.selectMarket(market);
  }
  /**
   * Handle the market selected by user
   * @param month month string
   */
  onSelectMonth(month: string) {
    this.logicService.selectMonth(month);
  }
  /**
   * Handle the range selected by user
   * @param event MatButtonToggleChange event
   */
  onSelectRange(event: MatButtonToggleChange) {
    this.logicService.selectRange(event.value);
  }
  /**
   * Push new chart references that are queried by ViewChild,
   * only if the refs actually change, to the logic service
   * @param chartDirective the lightweight chart directive
   */
  onChartDirectiveChange(chartDirective: ArpLightweightChartDirective) {
    this.logicService.updateChartRefs(chartDirective);
  }
}
