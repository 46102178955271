import { Component, Input, OnInit } from '@angular/core';
import { ChatMessage } from 'src/app/models/chatroom';
import { Quotation } from 'src/app/models/quotation';

@Component({
  selector: 'app-chat-message-quotation',
  templateUrl: './chat-message-quotation.component.html',
  styleUrls: ['./chat-message-quotation.component.scss'],
})
export class ChatMessageQuotationComponent implements OnInit {
  @Input() chat: ChatMessage;
  @Input() type: string;
  quote: Quotation;
  get quotationLink(): any[] {
    return ['/spot/trade'];
  }

  ngOnInit(): void {
    this.quote = this.chat?.quotation;
  }
}
