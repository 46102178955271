export const AppStorage = Object.freeze({
  lastLogin: 'lastLogin',
  lastLogout: 'lastLogout',
  RememberMe: 'RememberMe',
  UserName: 'UserName',
  profileName: 'profileName',
  enableNotificationPopup: 'enableNotificationPopup',
  company_default_settings: 'company_default_settings',
  dynamicSettings: 'dynamicSettings',
  TermsOfUse: 'TermsOfUse',
  require_2fa: 'require_2fa',
  enable_2fa: 'enable_2fa',
  Authorization: 'Authorization',
  Remark: 'Remark',
  enableNotificationSound: 'enableNotificationSound',
  enableChatSound: 'enableChatSound',
  enableErrorSound: 'enableErrorSound',
  price_discovery_sicom_refresh: 'price_discovery_sicom_refresh',
  tracy: 'tracy',
  approval: 'approval',
  email: 'email',
  language: 'translocoLang',
  tenantId: 'tenantId',
  theme: 'theme',
});
