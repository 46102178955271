import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileImageService {
  private dataSource = new BehaviorSubject(undefined);
  currentData = this.dataSource.asObservable();

  updateProfileUrl(url: string) {
    this.dataSource.next(url);
  }
}
