import {
  AfterViewInit,
  Directive,
  DoCheck,
  Host,
  Input,
  Optional,
  Renderer2,
  Self,
  ViewContainerRef,
} from '@angular/core';
import { MatLegacyPaginator } from '@angular/material/legacy-paginator';
import { TranslocoService } from '@ngneat/transloco';

/**
 * Custom Material Paginator with number nav
 * Taken from https://stackoverflow.com/questions/53646259/how-to-customize-mat-paginator-in-angular-material
 */
@Directive({
  selector: '[appPaginator]',
})
export class PaginatorDirective implements DoCheck, AfterViewInit {
  @Input() showOverallResults = true;

  private currentPage: number;
  private pageGapTxt: string[];
  private rangeStart: number;
  private rangeEnd: number;
  private buttons: HTMLButtonElement[] = [];
  private showTotalPages: number;
  private checkPage: (number | boolean)[];
  private prevButtonCount: number;
  constructor(
    @Host() @Self() @Optional() private readonly matPag: MatLegacyPaginator,
    private readonly ViewContainer: ViewContainerRef,
    private readonly renderer: Renderer2,
    private translocoService: TranslocoService,
  ) {
    this.currentPage = 1;
    this.pageGapTxt = ['•••', '---'];
    this.showTotalPages = 3;
    this.checkPage = [0, 0, 0];
    this.matPag._intl.itemsPerPageLabel =
      this.translocoService.translate('Showing: ');

    // Display custom range label text
    this.matPag._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number,
    ): string => {
      return '';
    };
  }

  ngDoCheck(): void {
    // Reset paginator if the pageSize, pageIndex, length changes
    if (
      this.matPag?.length !== this.checkPage[0] ||
      this.matPag?.pageSize !== this.checkPage[1] ||
      this.matPag?.pageIndex !== this.checkPage[2] ||
      this.matPag?.disabled !== this.checkPage[3]
    ) {
      const pageCount = this.matPag.getNumberOfPages();
      if (this.currentPage > pageCount && pageCount !== 0) {
        this.currentPage = 1;
        this.matPag.pageIndex = 0;
      }
      this.currentPage = this.matPag.pageIndex;
      this.initPageRange();
      this.checkPage = [
        this.matPag.length,
        this.matPag.pageSize,
        this.matPag.pageIndex,
        this.matPag.disabled,
      ];
      this.updateTotalLabelDiv();
    }
  }

  ngAfterViewInit(): void {
    this.rangeStart = 0;
    this.rangeEnd = this.showTotalPages - 1;
    this.createTotalLabelDiv();
    this.updateTotalLabelDiv();
  }

  private buildPageNumbers = () => {
    const totalPages = this.matPag.getNumberOfPages();
    // Container div with paginator elements
    const actionContainer =
      this.ViewContainer.element.nativeElement.querySelector(
        'div.mat-paginator-range-actions',
      );
    // Button that triggers the next page action
    const nextPageNode = this.ViewContainer.element.nativeElement.querySelector(
      'button.mat-paginator-navigation-next',
    );
    // Label showing the page range
    const pageRange = this.ViewContainer.element.nativeElement.querySelector(
      'div.mat-paginator-range-label',
    );

    this.prevButtonCount = this.buttons.length;

    this.removeButtons(actionContainer);

    this.renderer.addClass(pageRange, 'custom-paginator-counter');
    this.initNextandLastPageButtons(actionContainer);

    const dots = [false, false];

    if (totalPages > 0) {
      this.renderer.insertBefore(
        actionContainer,
        this.createButton('0', this.matPag.pageIndex),
        nextPageNode,
      );
    }

    const page = this.showTotalPages + 2;
    const pageDifference = totalPages - page;
    const startIndex = Math.max(this.currentPage - this.showTotalPages - 2, 1);
    this.insertElement(
      dots,
      actionContainer,
      startIndex,
      totalPages,
      page,
      pageDifference,
      nextPageNode,
    );
    if (totalPages > 1) {
      this.renderer.insertBefore(
        actionContainer,
        this.createButton(`${totalPages - 1}`, this.matPag.pageIndex),
        nextPageNode,
      );
    }
  };
  displayasNumber(index, page, pageDifference, totalPages): boolean {
    if (
      (index < page && this.currentPage <= this.showTotalPages) ||
      (index >= this.rangeStart && index <= this.rangeEnd) ||
      (this.currentPage > pageDifference && index >= pageDifference) ||
      totalPages < this.showTotalPages + page
    ) {
      return true;
    }
    return false;
  }
  insertElement(
    dots,
    actionContainer,
    startIndex,
    totalPages,
    page,
    pageDifference,
    nextPageNode,
  ) {
    for (let index = startIndex; index < totalPages - 1; index = index + 1) {
      if (this.displayasNumber(index, page, pageDifference, totalPages)) {
        this.renderer.insertBefore(
          actionContainer,
          this.createButton(`${index}`, this.matPag.pageIndex),
          nextPageNode,
        );
      } else {
        if (index > this.rangeEnd && !dots[0]) {
          this.insertPrevPageGapTxt(actionContainer, nextPageNode, dots);
          break;
        }
        if (index < this.rangeEnd && !dots[1]) {
          this.insertNextPageGapTxt(actionContainer, nextPageNode, dots);
        }
      }
    }
  }

  insertPrevPageGapTxt(actionContainer, nextPageNode, dots) {
    this.renderer.insertBefore(
      actionContainer,
      this.createButton(this.pageGapTxt[0], this.matPag.pageIndex),
      nextPageNode,
    );
    dots[0] = true;
  }

  insertNextPageGapTxt(actionContainer, nextPageNode, dots) {
    this.renderer.insertBefore(
      actionContainer,
      this.createButton(this.pageGapTxt[1], this.matPag.pageIndex),
      nextPageNode,
    );
    dots[1] = true;
  }

  removeButtons(actionContainer) {
    // Remove buttons before creating new ones
    if (this.prevButtonCount > 0) {
      this.buttons.forEach((button) => {
        this.renderer.removeChild(actionContainer, button);
      });
      // Empty state array
      this.prevButtonCount = 0;
    }
  }
  initNextandLastPageButtons(actionContainer) {
    // Initialize next page and last page buttons
    if (this.prevButtonCount === 0) {
      const nodeArray = actionContainer.childNodes;
      setTimeout(() => {
        this.loadRendererClass(nodeArray);
      });
    }
  }

  loadRendererClass(nodeArray) {
    for (const node of nodeArray) {
      if (node.nodeName === 'BUTTON') {
        // Next Button styles
        if (node.innerHTML.length > 100 && node.disabled) {
          this.renderer.addClass(node, 'custom-paginator-arrow-disabled');
          this.renderer.removeClass(node, 'custom-paginator-arrow-enabled');
        } else if (node.innerHTML.length > 100 && !node.disabled) {
          this.renderer.addClass(node, 'custom-paginator-arrow-enabled');
          this.renderer.removeClass(node, 'custom-paginator-arrow-disabled');
        }
      }
    }
  }
  private createButton(index: string, pageIndex: number): HTMLButtonElement {
    const linkBtn: HTMLButtonElement = this.renderer.createElement('button');
    this.renderer.setAttribute(linkBtn, 'class', 'custom-paginator-page');
    this.renderer.addClass(linkBtn, 'custom-paginator-page-enabled');
    if (index === this.pageGapTxt[0] || index === this.pageGapTxt[1]) {
      this.renderer.addClass(linkBtn, 'custom-paginator-arrow-enabled');
    }
    const pagingTxt = isNaN(+index) ? this.pageGapTxt[0] : +index + 1;
    const text = this.renderer.createText(pagingTxt + '');
    this.renderer.addClass(linkBtn, 'mat-custom-page');
    switch (index) {
      case `${pageIndex}`:
        this.renderer.removeClass(linkBtn, 'custom-paginator-page-enabled');
        this.renderer.addClass(linkBtn, 'custom-paginator-page-disabled');
        this.renderer.addClass(linkBtn, 'custom-paginator-current-page');
        break;
      case this.pageGapTxt[0]:
        this.renderer.listen(linkBtn, 'click', () => {
          this.switchPage(
            this.currentPage < this.showTotalPages + 1
              ? this.showTotalPages + 2
              : this.currentPage + this.showTotalPages - 1,
          );
        });
        break;
      case this.pageGapTxt[1]:
        this.renderer.listen(linkBtn, 'click', () => {
          this.switchPage(
            this.currentPage >
              this.matPag.getNumberOfPages() - this.showTotalPages - 2
              ? this.matPag.getNumberOfPages() - this.showTotalPages - 3
              : this.currentPage - this.showTotalPages + 1,
          );
        });
        break;
      default:
        this.renderer.listen(linkBtn, 'click', () => {
          this.switchPage(+index);
        });
        break;
    }
    if (this.matPag.disabled) {
      this.renderer.removeClass(linkBtn, 'custom-paginator-page-enabled');
      this.renderer.addClass(linkBtn, 'custom-paginator-page-disabled');
    }
    this.renderer.appendChild(linkBtn, text);
    // Add button to private array for state
    this.buttons.push(linkBtn);
    return linkBtn;
  }

  /**
   * @description calculates the button range based on class input parameters and based on current page index value.
   */
  private initPageRange(): void {
    this.rangeStart = this.currentPage - this.showTotalPages / 2;
    this.rangeEnd = this.currentPage + this.showTotalPages / 2;
    this.buildPageNumbers();
  }

  private switchPage(index: number): void {
    this.matPag.pageIndex = index;
    this.matPag.page.emit({
      previousPageIndex: this.currentPage,
      pageIndex: index,
      pageSize: this.matPag.pageSize,
      length: this.matPag.length,
    });
    this.currentPage = index;
  }

  private createTotalLabelDiv(): void {
    if (!this.showOverallResults) return;

    const labelTotalDiv = this.renderer.createElement('div');
    const labelValueDiv = this.renderer.createElement('div');
    const labelContainerDiv = this.renderer.createElement('div');
    labelTotalDiv.innerHTML =
      this.translocoService.translate('Overall results: ');
    const paginatorContainer =
      this.ViewContainer.element.nativeElement.querySelector(
        'div.mat-paginator-container',
      );

    this.renderer.addClass(
      labelContainerDiv,
      'custom-paginator-total-label-container',
    );
    this.renderer.addClass(labelTotalDiv, 'custom-paginator-total-label');
    this.renderer.addClass(labelValueDiv, 'custom-paginator-total-label-value');

    this.renderer.appendChild(paginatorContainer, labelContainerDiv);
    this.renderer.appendChild(labelContainerDiv, labelTotalDiv);
    this.renderer.appendChild(labelContainerDiv, labelValueDiv);
  }

  private updateTotalLabelDiv(): void {
    if (!this.showOverallResults) return;

    const labelDiv = this.ViewContainer.element.nativeElement.querySelector(
      'div.custom-paginator-total-label-value',
    );
    if (labelDiv !== null) {
      labelDiv.innerHTML = ` ${this.matPag?.length.toString().bold()}`;
    }
  }
}
