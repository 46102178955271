import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LanguageMenuItem } from 'src/app/models/language-menu-item';
import langMap from './lang-map.json';

/**
 * This component is responsible for showing user the
 * current language and the list of languages available
 * to switch to.
 */
@Component({
  selector: 'arp-language-picker',
  templateUrl: './arp-language-picker.component.html',
  styleUrls: ['./arp-language-picker.component.scss'],
})
export class ArpLanguagePickerComponent implements OnInit {
  /**
   * Current chosen language, to be initialized
   * in ngOnInit through Transloco Service
   */
  currentLang: LanguageMenuItem;
  /**
   * List of available languages, to be initialized
   * in ngOnInit through Transloco Service
   */
  languages: LanguageMenuItem[];
  /**
   * Boolean indicating whether menu is opened or closed
   */
  isMenuOpened = false;

  constructor(private translocoService: TranslocoService) {}

  /**
   * Initializing this component by retrieving the
   * current language and list of available languages
   * from the Transloco Service
   */
  ngOnInit(): void {
    this.currentLang = langMap[this.translocoService.getActiveLang()];
    this.languages = this.translocoService
      .getAvailableLangs()
      .map((langCode) => langMap[langCode]);
  }

  /**
   * Set the currentLang to the chosen language for display,
   * and also set the active language app-wide using Transloco
   * Service.
   * @param language the LanguageMenuItem clicked by the user
   */
  chooseLanguage(language: LanguageMenuItem) {
    this.currentLang = language;
    this.translocoService.setActiveLang(language.langCode);
  }
}
