import { Injectable } from '@angular/core';
import { differenceInSeconds, format } from 'date-fns';
import {
  BehaviorSubject,
  Observable,
  distinctUntilChanged,
  map,
  shareReplay,
  take,
  timer,
  withLatestFrom,
} from 'rxjs';
import { SelectFieldModel } from '../models/form-field-models';
import {
  FxStream,
  PhysicalIndexStream,
  SicomMarketData,
  SicomMarkets,
  SicomPrice,
  SicomStream,
  SicomTrendByDeliveryMonth,
  SixStream,
  SixStreamStatus,
  StreamState,
} from '../models/sicom-latest-price';
import { getSortedObjectProperties } from '../utils/getSortedObjectProperties';
import { getTooltipByStreamStatus } from '../utils/getTooltipByStreamStatus';
import { FirestoreFactoryService } from './firestore-factory.service';
import { SgxInstrumentApiService } from './sgx-instrument-api.service';
import { StorageService } from './storage.service';

const FX_INSTRUMENT_MAP = {
  'USD/IDR': '274944,148,500',
  'USD/MYR': '275130,148,594',
  'USD/CNY': '275157,148,220',
  'USD/JPY': '275023,148,534',
  'USD/THB': '275154,148,904',
  'USD/SGD': '275129,148,846',
  'EUR/USD': '946681,148,333',
  'JPY/USD': '275032,149,333',
};
/**
 * This service is the single point of subscription to incoming Six Stream data
 * from Firestore. Six Stream data is then broken down into various streams and
 * broadcasted to other components using ReplaySubjects.
 */
@Injectable({
  providedIn: 'root',
})
export class SixStreamService {
  /**
   * The user's six subscription plan
   */
  private _sixSubscriptionPlan: StreamState;
  /**
   * Observable streaming the Six Stream Data
   * from Firestore. Six Stream composes of
   * Sicom markets data and FX price data.
   */
  private _sixClient: Observable<SixStream<string>>;
  /**
   * Observable streaming the Physical Index Data
   * from Firestore.
   */
  private _physicalIndexClient: Observable<PhysicalIndexStream>;
  /**
   * Observable streaming the Sicom Instrument Map
   */
  private _sicomInstrumentMap$: Observable<any>;
  /**
   * BehaviorSubject streaming the FX Instrument Map
   */
  private _fxInstrumentMap$: BehaviorSubject<any>;
  /**
   * A timer that emits a current Date every 2 minute,
   * to be used to poll the status of the six stream
   */
  private _pollingTimer$ = timer(500, 5000).pipe(map((_) => new Date()));
  /**
   * Compute the last updated time of the six stream
   */
  private _lastUpdatedTime$: Observable<number>;
  /**
   * The status of the six stream
   */
  private _sixStreamStatus$: Observable<SixStreamStatus>;
  /**
   * Compute the sicom$ stream from the sixClient
   */
  get sicom$() {
    return this._sixClient.pipe(
      map((sixStream) => this.createSicomStream(sixStream)),
    );
  }
  /**
   * Compute the sicomTrendByDeliveryMonth$ stream from the sicom$ stream
   */
  get sicomTrendByDeliveryMonth$() {
    return this.sicom$.pipe(
      map((sicomStream) => this.createSicomTrendByDeliveryMonth(sicomStream)),
    );
  }
  /**
   * Compute the fx$ stream from the sixClient
   */
  get fx$() {
    return this._sixClient.pipe(
      map((sixStream) => this.createFxStream(sixStream)),
    );
  }
  /**
   * Compute the physicalIndex$ stream from the physicalIndexClient
   */
  get physicalIndex$() {
    return this._physicalIndexClient.pipe(
      map((physicalIndexStream) =>
        this.createPhysicalIndexStream(physicalIndexStream),
      ),
    );
  }
  /**
   * Expose the _lastUpdatedTime$ stream
   */
  get lastUpdatedTime$() {
    return this._lastUpdatedTime$;
  }
  /**
   * Expose the _sixStreamStatus$ stream
   */
  get sixStreamStatus$(): Observable<SixStreamStatus> {
    return this._sixStreamStatus$;
  }
  /**
   * Expose the _sicomInstrumentMap$ stream
   */
  get sicomInstrumentMap$() {
    return this._sicomInstrumentMap$;
  }
  /**
   * Expose the _fxInstrumentMap$ stream
   */
  get fxInstrumentMap$() {
    return this._fxInstrumentMap$.asObservable();
  }
  /**
   * Extract the markets selectable from the sicom stream
   * into a SelectFieldModel so it can be easily consumed by
   * the MatLegacySelect component.
   */
  get marketSelectField$() {
    return this.sicom$.pipe(
      take(1),
      map((sicomStream: SicomStream) => {
        const markets = getSortedObjectProperties(sicomStream).map(
          (property) => property.key,
        );
        return this.createMarketSelectField(markets);
      }),
    );
  }
  /**
   * Extract the months selectable from the sicom stream
   * into a SelectFieldModel so it can be easily consumed by
   * the MatLegacySelect component.
   */
  get monthSelectField$() {
    return this.sicom$.pipe(
      take(1),
      map((sicomStream: SicomStream) => {
        return getSortedObjectProperties(sicomStream.TSR20).map(
          (property) => property.key,
        );
      }),
      map((months: string[]) => {
        return this.createMonthSelectField(months);
      }),
    );
  }
  /**
   * Extract the currency pairs selectable from the fx stream
   * into a SelectFieldModel so it can be easily consumed by
   * the MatLegacySelect component.
   */
  get currencyPairSelectField$() {
    return this.fx$.pipe(
      take(1),
      map((fxStream: FxStream) => {
        const currencyPairs = getSortedObjectProperties(fxStream).map(
          (property) => property.key,
        );
        return this.createCurrencyPairSelectField(currencyPairs);
      }),
    );
  }

  constructor(
    private firestoreFactoryService: FirestoreFactoryService,
    private sgxInstrumentApiService: SgxInstrumentApiService,
    private storageService: StorageService,
  ) {}

  /**
   * Initialize the sicom stream service by
   * 1. Retrieve Subscription Plan and
   * 2. Create either realtime or delayed sixClient and share it downstream
   * using shareReplay(1)
   * 3. Create the physicalIndexClient and share it downstream using shareReplay(1)
   * 4. Create the sicomInstrumentMap and fxInstrumentMap
   */
  initialize() {
    this._sixSubscriptionPlan = this.storageService.retrieve(
      'sgxtsr_subscription',
    );
    const sixQuery =
      this._sixSubscriptionPlan === StreamState.LIVE
        ? this.firestoreFactoryService.createRealtimePricesQuery()
        : this.firestoreFactoryService.createDelayedPricesQuery();
    this._sixClient = sixQuery.pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this._physicalIndexClient = this.firestoreFactoryService
      .createPhysicalIndexQuery()
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    this._lastUpdatedTime$ = this._sixClient.pipe(
      map((sixStream) => sixStream.time),
    );
    this._sixStreamStatus$ = this._pollingTimer$.pipe(
      withLatestFrom(this.lastUpdatedTime$),
      map(([pollingTimer, lastUpdated]) => {
        const lastUpdatedDateTime = new Date(lastUpdated);
        const difference = differenceInSeconds(
          pollingTimer,
          lastUpdatedDateTime,
        );
        return difference > 90
          ? {
              state: StreamState.STALE,
              toolTip: getTooltipByStreamStatus(StreamState.STALE),
            }
          : {
              state: this._sixSubscriptionPlan as StreamState,
              toolTip: getTooltipByStreamStatus(
                this._sixSubscriptionPlan as StreamState,
              ),
            };
      }),
      distinctUntilChanged((prev, curr) => prev.state === curr.state),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this._sicomInstrumentMap$ = this.sgxInstrumentApiService
      .getSicomInstrumentMap()
      .pipe(shareReplay(1));
    this._fxInstrumentMap$ = new BehaviorSubject<any>(FX_INSTRUMENT_MAP);
  }

  /**
   * Clean up all subscriptions
   * and destroy the sixClient and physicalIndexClient
   */
  cleanUp() {
    this._sixClient = null;
    this._physicalIndexClient = null;
  }

  /**
   * Create Sicom Stream from given Six Stream data
   * @param sixStream the six stream data
   * @returns the sicom stream
   */
  createSicomStream(sixStream: SixStream<string>): SicomStream {
    return {
      TSR20: this.parseMktDataToFloat(sixStream?.data.TSR20),
      RSS: this.parseMktDataToFloat(sixStream?.data.RSS),
    };
  }

  /**
   * Create FX Stream from given Six Stream data
   * @param sixStream the six stream data
   * @returns the fx stream
   */
  createFxStream(sixStream: SixStream<string>): FxStream {
    return {
      ...sixStream?.data?.FX,
    };
  }
  /**
   * Create the Physical Index Stream from given Physical Index Stream data
   * simply reformat the 'week' field into dd/MM/yyyy format
   * @param physicalIndexStream the physical index stream data
   * @returns physical index stream data, with formatted week field
   */
  createPhysicalIndexStream(
    physicalIndexStream: PhysicalIndexStream,
  ): PhysicalIndexStream {
    return {
      ...physicalIndexStream,
      week: format(new Date(physicalIndexStream.week), 'dd/MM/yyyy'),
    };
  }

  /**
   * Compute the Sicom Trend for each delivery month in the TSR20 market
   * from the given Sicom Stream
   * @param sicomStream the Sicom Stream Data
   * @returns an object containing the Sicom Trend for each delivery month
   */
  createSicomTrendByDeliveryMonth(sicomStream: SicomStream) {
    const tsr20MarketData = sicomStream[SicomMarkets.TSR20];
    return Object.fromEntries(
      Object.entries(tsr20MarketData).map(([date, sicomPrice]) => {
        const price = sicomPrice.last_trade;
        const change =
          sicomPrice.last_trade - sicomPrice.most_recent_settlement_price;
        const changePercent =
          (change / sicomPrice.most_recent_settlement_price) * 100;
        const changeClass = change > 0 ? 'up' : change < 0 ? 'down' : 'flat';
        const changeIcon =
          change > 0
            ? 'trending_up'
            : change < 0
            ? 'trending_down'
            : change === 0
            ? 'trending_flat'
            : '';
        const trend = {
          price,
          change,
          changePercent,
          changeClass,
          changeIcon,
        };
        return [date, trend];
      }),
    ) as SicomTrendByDeliveryMonth;
  }

  /**
   * Create the currency pair select field, given the list of currency pairs
   * @param fxStream
   * @returns the currency pair select field
   */
  createCurrencyPairSelectField(currencyPairs: string[]) {
    return {
      placeholder: 'Currency Pair',
      label: 'Currency Pair',
      multiple: false,
      options: currencyPairs,
      selectedOptions: 'USD/SGD',
      for: 'currencyPair',
    } as SelectFieldModel<string>;
  }

  /**
   * Create the market select field, given the list of markets
   * @param markets list of markets, e.g ['TSR20', 'RSS']
   * @returns the market select field
   */
  createMarketSelectField(markets: string[]) {
    return {
      placeholder: 'Market',
      label: 'Market',
      multiple: false,
      options: markets,
      selectedOptions: SicomMarkets.TSR20,
      for: 'market',
    } as SelectFieldModel<string>;
  }

  /**
   * Create the month select field, given the list of months
   * @param monthOptions list of months, e.g ['2021-01', '2021-02']
   * @returns the month select field
   */
  createMonthSelectField(months: string[]) {
    return {
      placeholder: 'Month',
      label: 'Month',
      multiple: false,
      options: months,
      selectedOptions: months[1],
      for: 'month',
    } as SelectFieldModel<string>;
  }
  /**
   * Query for instrument data, given the instrument code, date from and date to
   * @param instrumentCode the instrument code of the sicom commodity
   * @param dateFrom the start date of the query
   * @param dateTo the end date of the query
   * @returns the instrument data
   */
  queryInstrumentData(
    instrumentCode: string,
    dateFrom: string,
    dateTo: string,
  ) {
    const params = {
      isc: instrumentCode,
      datefrom: dateFrom,
      dateTo: dateTo,
      pk: 'all',
    };
    return this.sgxInstrumentApiService.getSGXInstrumentData(params);
  }
  /**
   * Query for the historical Physical Index data, given the date from and date to
   * @param dateFrom the start date of the query
   * @param dateTo the end date of the query
   * @returns the historical physical index data
   */
  queryPhysicalIndexData(dateFrom: string, dateTo: string) {
    const params = {
      datefrom: dateFrom,
      dateTo: dateTo,
    };
    return this.sgxInstrumentApiService.getPhysicalIndexData(params);
  }
  /**
   * Parse the SicomMarketData from string to float format. Necessary because
   * SicomMarketData is stored as string in Firestore.
   * @param mktData the sicom market data in string format, received from Firestore.
   * @returns the sicom market data in number format for usage in application.
   */
  parseMktDataToFloat(
    mktData: SicomMarketData<string>,
  ): SicomMarketData<number> {
    return Object.fromEntries(
      Object.entries(mktData).map(
        ([date, sicomPriceInString]: [string, SicomPrice<string>]) => {
          const sicomPriceInNumber: SicomPrice<number> = {
            symbol: sicomPriceInString.symbol,
            open_price: parseFloat(sicomPriceInString.open_price),
            last_trade: parseFloat(sicomPriceInString.last_trade),
            high_price: parseFloat(sicomPriceInString.high_price),
            low_price: parseFloat(sicomPriceInString.low_price),
            most_recent_settlement_price: parseFloat(
              sicomPriceInString.most_recent_settlement_price,
            ),
            bid_price: parseFloat(sicomPriceInString.bid_price),
            ask_price: parseFloat(sicomPriceInString.ask_price),
            open_interest: parseFloat(sicomPriceInString.open_interest),
            cumulative_instrument_volume: parseFloat(
              sicomPriceInString.cumulative_instrument_volume,
            ),
            bid_quantity: parseFloat(sicomPriceInString.bid_quantity),
            ask_quantity: parseFloat(sicomPriceInString.ask_quantity),
          };
          return [date, sicomPriceInNumber];
        },
      ),
    );
  }
}
