import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import {
  ChatMessage,
  ChatRoom,
  ChatRoomSelection,
} from 'src/app/models/chatroom';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-chat-forward-message',
  templateUrl: './chat-forward-message.component.html',
  styleUrls: ['./chat-forward-message.component.scss'],
})
export class ChatForwardMessageComponent implements OnInit {
  message: string;
  search: string;
  roomList: ChatRoom[];
  listSearched: ChatRoom[];
  forwardMessage: ChatMessage;
  selected: ChatRoomSelection;
  constructor(private chatService: ChatService) {}

  ngOnInit(): void {
    this.selected = {};
    this.setRoomList();
    this.chatService.forwardMessage.subscribe((message) => {
      if (message) {
        this.forwardMessage = message;
        this.message = 'Forwarded:' + '\n' + message.message;
      }
    });
  }

  public searchList(): void {
    this.listSearched = this.roomList.filter((item: any) =>
      !this.search ? true : this.checkSearch(item.name),
    );
  }

  private checkSearch(value: string) {
    return !value
      ? false
      : value.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1;
  }

  private setRoomList(): void {
    this.roomList = [];
    this.chatService.roomList.subscribe((rooms: ChatRoom[]) => {
      this.roomList = rooms;
      this.searchList();
    });
  }

  selectedRoom(room: ChatRoom) {
    if (!this.selected[room.id]) {
      this.selected[room.id] = room;
    } else {
      delete this.selected[room.id];
    }
  }

  close(): void {
    this.chatService.forwardMessage.next(undefined);
  }

  forward(): void {
    const forwardMessages: Observable<ChatMessage>[] = [];
    for (const room of Object.values(this.selected)) {
      forwardMessages.push(
        this.chatService.sendChatMessage(room.id, {
          message: this.message,
          user: this.chatService.user.value,
        } as ChatMessage),
      );
    }
    forkJoin(forwardMessages).subscribe((messages: any[]) => {
      const readMessages = messages.map((message: any) =>
        this.chatService.readMessage(this.selected[message.room]),
      );
      forkJoin(readMessages).subscribe();
      this.close();
    });
  }
}
