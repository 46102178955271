/**
 * @description Websocket notification message types
 */
export enum NotificationMessageType {
  NewNotification = 'new.notification',
  NewChatNotification = 'new.chat.notification',
  NewMessage = 'new.message',
  Online = 'online',
  Offline = 'offline',
}
