import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef,
  MAT_LEGACY_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { NavigationModalData } from 'src/app/models/modal-data';

@Component({
  selector: 'app-navigation-modal',
  templateUrl: './navigation-modal.component.html',
  styleUrls: ['./navigation-modal.component.scss'],
})
export class NavigationModalComponent {
  modalData: NavigationModalData;

  constructor(
    private dialogRef: MatLegacyDialogRef<NavigationModalComponent>,
    private router: Router,
    @Inject(MAT_LEGACY_DIALOG_DATA) data: NavigationModalData,
  ) {
    this.modalData = data;
  }

  navigate(path: string) {
    this.dialogRef.close();
    this.router.navigate([path]);
  }

  onCloseClick() {
    this.dialogRef.close();
  }
}
