import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { LegacyPageEvent } from '@angular/material/legacy-paginator';
import { Subject } from 'rxjs';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { IDisplayableObject } from 'src/app/models/display-models';
import {
  MatTableActionsColumnConfig,
  MatTableColumnConfig,
} from 'src/app/models/mat-table-models';
import { PagedResponse } from 'src/app/models/paged-response';

@Component({
  selector: 'arp-data-table',
  templateUrl: './arp-data-table.component.html',
  styleUrls: ['./arp-data-table.component.scss'],
})
export class ArpDataTableComponent implements OnInit, OnDestroy {
  /**
   * Boolean value controlling whether table allows for multiple row selection.
   * Default is true, to allow multi row selection,
   */
  @Input() isMultiSelectable = true;
  /**
   * Boolean value controlling whether user sees hover effect when hovering on
   * a row.
   */
  @Input() isRowHoverable = false;
  /**
   * Boolean value controlling whether user is able to click anywhere on a row
   * to select it.
   */
  @Input() isRowClickable = false;
  /**
   * Data for showing in table, should be a paginated, displayable data.
   */
  @Input() tableData: PagedResponse<IDisplayableObject>;
  /**
   * Array of configurations for all table columns.
   */
  @Input() tableConfig: MatTableColumnConfig[];
  /**
   * The configuration for the action column in the table.
   */
  @Input() actionsColumnConfig: MatTableActionsColumnConfig;
  /**
   * List of columns to be displayed by the table, because the table does not necessarily
   * need to display all the column it is configured for, such as the checkbox column/actions
   * column.
   */
  @Input() displayedColumns: string[];
  /**
   * Event emitter for the action column. Triggered when user perform any action
   * in the actions column.
   */
  @Output() actionEvent = new EventEmitter<DataTableActionEvent>();
  /**
   * Event emitter for pageChangedEvent from paginator.
   */
  @Output() pageChangedEvent = new EventEmitter<LegacyPageEvent>();
  /**
   * Event emitter for table selection change event. Triggered when user select/deselect row
   * by clicking on row checkbox, or by clicking on row (if isRowClickable is true).
   */
  @Output() selectionChange = new EventEmitter<IDisplayableObject[]>();
  /**
   * SelectionModel object for internally manage row selection.
   */
  selectedRows: SelectionModel<IDisplayableObject>;
  /**
   * Default page size options for table.
   */
  pageSizeOptions = [10, 20, 30];
  /**
   * Subject used to stop all subscriptions
   * on component destruction.
   */
  destroy$ = new Subject<boolean>();

  /**
   * Initializing the table by:
   * 1. Create a new Selection Model based on whether the table isMultiSelectable or not.
   * 2. Subscribe to the selectedRows.changed observable to automatically emit selectionChange
   * event.
   */
  ngOnInit(): void {
    this.selectedRows = new SelectionModel(this.isMultiSelectable, []);
    this.selectedRows.changed
      .pipe(
        takeUntil(this.destroy$),
        // delay this changed pipeline by 1 VM turn
        // allowing view to fully render first before resetting
        // selectedCount
        delay(0),
        tap((event) => {
          this.selectionChange.emit(event.source.selected);
        }),
      )
      .subscribe();
  }

  /**
   * Emit stop signal to existing subscription
   * in this component.
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  /**
   * Re-emit the LegacyPageEvent emitted by the table paginator.
   * @param event LegacyPageEvent emitted by the table paginator.
   */
  pageChangeEvent(event: LegacyPageEvent) {
    this.pageChangedEvent.emit(event);
  }

  /**
   * Purely used to stopPropagation of checkbox click event, so that it does not also
   * trigger rowClicked function.
   * @param event
   */
  rowCheckboxClicked(event: Event) {
    event.stopPropagation();
  }

  /**
   * Handle actual row selection when a row checkbox is clicked.
   * @param event checkbox change event, only for sanity check.
   * @param row the row to be selected.
   */
  rowCheckboxChanged(event, row: IDisplayableObject) {
    if (event) {
      this.selectedRows.toggle(row);
    }
  }

  /**
   * Emit event whenever user click any action specified in the actions column.
   * 1. First, stopPropagation so the event does not also trigger the rowClicked function.
   * 2. Emit the row and eventName of the action.
   * @param event purely passed in to call stopPropagation.
   * @param row row data to be emitted.
   * @param eventName event name associated with the action clicked to be emitted.
   */
  actionClicked(event: Event, row: IDisplayableObject, eventName: string) {
    event.stopPropagation();
    this.actionEvent.emit({
      row,
      eventName,
    });
  }

  /**
   * Handle row selection when clicking on a row (if isRowClickable is true).
   * @param row row to be selected.
   */
  rowClicked(row: IDisplayableObject) {
    if (this.isRowClickable) {
      this.selectedRows.toggle(row);
    }
  }

  /**
   * Deselect all rows if all rows were previously selected. Select all rows if not
   * all rows were previously selected.
   * @param event used for sanity check.
   */
  masterToggle(event) {
    if (event) {
      this.isAllSelected()
        ? this.selectedRows.clear()
        : this.selectedRows.select(...this.tableData.results);
    }
  }

  /**
   * Determine if all table rows are selected
   * @returns true if all rows are selected, false otherwise.
   */
  isAllSelected(): boolean {
    return this.selectedRows.selected.length === this.tableData.results.length;
  }

  /**
   * Determine if the master checkbox should be in checked state.
   * @returns true if all rows are selected, false otherwise.
   */
  isMasterCheckboxChecked() {
    return this.selectedRows.hasValue() && this.isAllSelected();
  }

  /**
   * Determine if the master checkbox should be in indeterminate state.
   * @returns true if some but not all rows are selected, false if none or all rows are selected.
   */
  isMasterCheckboxIndeterminate() {
    return this.selectedRows.hasValue() && !this.isAllSelected();
  }

  /**
   * Determine if the row checkbox should be in checked state.
   * @param row to check whether the row is selected.
   * @returns true if row is already selected, false otherwise.
   */
  isRowCheckboxChecked(row: IDisplayableObject) {
    return this.selectedRows.isSelected(row);
  }
}

/**
 * Interface for the event emitted by the action column.
 */
export interface DataTableActionEvent {
  row: IDisplayableObject;
  eventName: string;
}
