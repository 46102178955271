import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
  imports: [],
  exports: [
    MatLegacyCheckboxModule,
    MatLegacyButtonModule,
    MatBadgeModule,
    MatLegacyInputModule,
    MatLegacyAutocompleteModule,
    MatDatepickerModule,
    MatLegacyFormFieldModule,
    MatLegacyRadioModule,
    MatLegacySelectModule,
    MatLegacySliderModule,
    MatLegacySlideToggleModule,
    MatLegacyMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatLegacyListModule,
    MatGridListModule,
    MatLegacyCardModule,
    MatStepperModule,
    MatLegacyTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatLegacyChipsModule,
    MatIconModule,
    MatLegacyProgressSpinnerModule,
    MatLegacyProgressBarModule,
    MatLegacyDialogModule,
    MatLegacyTooltipModule,
    MatLegacySnackBarModule,
    MatLegacyTableModule,
    MatSortModule,
    MatLegacyPaginatorModule,
    MatNativeDateModule,
    MatRippleModule,
    MatBottomSheetModule,
    MatChipsModule,
  ],
})
export class CoreModule {}
