<div class="gpsnr-password-setup-form">
  <div>
    {{ "LOGIN.PLEASE_ENSURE_NEW_PASSWORD_MEETS_REQUIREMENTS" | transloco }}:
  </div>
  <ul>
    <li>
      <mat-icon
        aria-hidden="false"
        class="material-icons-outlined"
        [class.valid]="isPasswordLengthEnough"
        >{{ isPasswordLengthEnough ? "check" : "close" }}</mat-icon
      ><span>8 {{ "LOGIN.CHARACTERS" | transloco }}</span>
    </li>
    <li>
      <mat-icon
        aria-hidden="false"
        class="material-icons-outlined"
        [class.valid]="isPasswordHasLetter"
        >{{ isPasswordHasLetter ? "check" : "close" }}</mat-icon
      ><span>1 {{ "LOGIN.LETTER" | transloco }}</span>
    </li>
    <li>
      <mat-icon
        aria-hidden="false"
        class="material-icons-outlined"
        [class.valid]="isPasswordHasNumber"
        >{{ isPasswordHasNumber ? "check" : "close" }}</mat-icon
      ><span>1 {{ "LOGIN.NUMBER" | transloco }}</span>
    </li>
    <li>
      <mat-icon
        aria-hidden="false"
        class="material-icons-outlined"
        [class.valid]="isPasswordMatch"
        >{{ isPasswordMatch ? "check" : "close" }}</mat-icon
      ><span>{{ "MESSAGES.PASSWORD_MATCH" | transloco }}</span>
    </li>
  </ul>

  <mat-form-field class="password-input" appearance="outline">
    <mat-label>{{ "LOGIN.INSERT_PASSWORD" | transloco }}</mat-label>
    <input
      name="password"
      matInput
      [(ngModel)]="password"
      (keyup)="validatePassword()"
      [type]="passwordMask ? 'password' : 'text'" />
    <mat-icon matSuffix (click)="togglePasswordMask()">{{
      passwordMask ? "visibility_off" : "visibility"
    }}</mat-icon>
  </mat-form-field>

  <mat-form-field class="password-input" appearance="outline">
    <mat-label>{{ "LOGIN.CONFIRM_PASSWORD" | transloco }}</mat-label>
    <input
      name="password_confirmation"
      matInput
      [(ngModel)]="passwordConfirmation"
      (keyup)="validatePasswordMatch()"
      [type]="passwordConfirmMask ? 'password' : 'text'" />
    <mat-icon matSuffix (click)="togglePasswordConfirmMask()">{{
      passwordConfirmMask ? "visibility_off" : "visibility"
    }}</mat-icon>
  </mat-form-field>

  <button
    mat-button
    class="save-password-button"
    [disabled]="hasMissingField()"
    (click)="saveNewPasswordClick()">
    {{ "LOGIN.SAVE_PASSWORD" | transloco }}
  </button>
</div>
