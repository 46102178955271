import { Pipe, PipeTransform } from '@angular/core';

/*
 * Returns the price as string with 3 places
 */
const OPENTEXT = 'OPEN';
@Pipe({ name: 'openOfferBid' })
export class OpenOfferBidPipe implements PipeTransform {
  transform(
    value?: number | string,
    displayOpenText?: boolean,
  ): number | string {
    if (!value || parseInt(value.toString(), 10) === 0) {
      if (displayOpenText) {
        return OPENTEXT;
      }
      return '-';
    }
    return value;
  }
}
