import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AppStorage } from '../utils/storage-constants';
/**
 * Storage Service for application, using local storage to store data
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  /**
   * Stores data object as JSON into key
   * @param key key value to store
   * @param data data object to store (internally converted to JSON String)
   */
  store(key: string, data: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  /**
   * Checks if there exists a key
   * @param key key value to check
   */
  check(key: string): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(key) !== null ? true : false;
    }
    return false;
  }

  /**
   * Retrieve data object of T from store
   * @param key key value to retrieve
   * @template T Data Model
   */
  retrieve<T>(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      const data = localStorage.getItem(key);
      return JSON.parse(data) as T;
    }
    return null;
  }

  /**
   * remove key from store from store
   * @param key key value to retrieve
   */
  remove(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(key);
    }
  }

  /**
   * Clear local store
   */
  clear() {
    const rememberMe = this.retrieve<boolean>(AppStorage.RememberMe);
    const userName = this.retrieve<string>(AppStorage.UserName);
    const lastLogin = this.retrieve<string>(AppStorage.lastLogin);
    const lastLogout = this.retrieve<string>(AppStorage.lastLogout);
    const tenantId = this.retrieve<string>(AppStorage.tenantId);
    const theme = this.retrieve<string>(AppStorage.theme);
    const lang = localStorage.getItem(AppStorage.language);

    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    }
    if (rememberMe) {
      this.store(AppStorage.RememberMe, rememberMe);
      this.store(AppStorage.UserName, userName);
    }
    this.store('lastLogin', lastLogin);
    this.store('lastLogout', lastLogout);

    if (tenantId) {
      this.store('tenantId', tenantId);
    }

    if (theme) {
      this.store('theme', theme);
    }

    localStorage.setItem(AppStorage.language, lang);
  }
}
