import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { NguCarousel } from '@ngu/carousel';
import { NguCarouselConfig } from '@ngu/carousel/lib/ngu-carousel/ngu-carousel';
import { Observable } from 'rxjs';
import { PriceWidgetService } from 'src/app/services/price-widget.service';
import { PriceWidget } from './arp-price-widget';

@Component({
  selector: 'arp-price-widget',
  templateUrl: './arp-price-widget.component.html',
  styleUrls: ['./arp-price-widget.component.scss'],
  providers: [PriceWidgetService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArpPriceWidgetComponent {
  /**
   * The css class for the width of the price widget component
   */
  widgetWidthClass$: Observable<'shorter' | 'longer'>;
  /**
   * Observable streaming the price widgets data.
   */
  priceWidgets$: Observable<PriceWidget[]>;
  /**
   * Template reference to NguCarousel component
   */
  protected _carousel: NguCarousel<any>;
  /**
   * Get template reference to NguCarousel component
   * Doing this in this setter method because the NguCarousel
   * is not available in template when ngAfterViewInit is called,
   * as it is inside a ng-container conditionally rendered by
   * ngIf="priceWidgets$ | async".
   */
  @ViewChild(NguCarousel) set carousel(carousel: NguCarousel<any>) {
    if (this._carousel !== carousel) {
      this._carousel = carousel;
    }
  }
  /**
   * Event Emitter to toggle price display
   */
  @Output() expandDisplay = new EventEmitter<any>();
  /**
   * NguCarousel configuration
   */
  nguCarouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    speed: 250,
    loop: true,
    interval: { timing: 3000 },
    animation: 'lazy',
  };

  constructor(private priceWidgetService: PriceWidgetService) {
    this.widgetWidthClass$ = this.priceWidgetService.widgetWidthClass$;
    this.priceWidgets$ = this.priceWidgetService.priceWidgets$;
  }

  /**
   * Emits an event to toggle the price display
   */
  expandPriceDisplay(displayComponent: any): void {
    this.expandDisplay.emit(displayComponent);
  }
  /**
   * Move the carousel to the right
   * @param carousel the carousel to move
   */
  moveCarouselRight(carousel: NguCarousel<any>) {
    carousel.moveTo((carousel.currentSlide + 1) % 3);
  }
  /**
   * Move the carousel to the left
   * @param carousel the carousel to move
   */
  moveCarouselLeft(carousel: NguCarousel<any>) {
    carousel.moveTo((carousel.currentSlide + 2) % 3);
  }
  /**
   * A trackBy function to optimize the rendering
   * of the price widgets, in the event the price widgets
   * are shuffled around in their original array.
   * @param index the index of price widget data
   * @param widget the price widget data at the given index
   * @returns a unique identifier for the price widget data
   * at a given index
   */
  trackByTitle(index: number, widget: PriceWidget) {
    return widget.title;
  }
}
