import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { WINDOW_TOKEN } from 'src/app/injection-tokens/injection-tokens';
import { Manufacturer } from '../helpers/enum-manufacturer';
import { GPSNRForm } from '../models/api/responses/user-info';
import { StorageService } from '../services/storage.service';
import { checkFormBaseURL } from '../utils/checkFormBaseURL';

@Injectable({
  providedIn: 'root',
})
export class GPSNRLoginGuard {
  constructor(
    private storage: StorageService,
    private router: Router,
    @Inject(WINDOW_TOKEN) private window: Window,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree {
    const gpsnrForms = this.storage.retrieve<GPSNRForm[]>('gpsnrForm') ?? [];
    const allowedForms = gpsnrForms.map((item) => item.manufacturer);

    if (!checkFormBaseURL(this.window.location.href)) {
      return this.router.parseUrl('/login');
    }

    if (!this.storage.check('Authorization')) {
      this.storage.clear();
      return true;
    }

    // To be extracted into its own guard
    if (
      allowedForms.includes(Manufacturer.CONTINENTAL) &&
      state.url === '/continental'
    ) {
      return this.router.parseUrl('/continental-gpsnr-form');
    } else if (
      allowedForms.includes(Manufacturer.PIRELLI) &&
      state.url === '/pirelli'
    ) {
      return this.router.parseUrl('/pirelli-gpsnr-form');
    }

    this.storage.clear();
    return true;
  }
}
