import { Component, Input } from '@angular/core';

@Component({
  selector: 'arp-divider',
  templateUrl: './arp-divider.component.html',
  styleUrls: ['./arp-divider.component.scss'],
})
export class ArpDividerComponent {
  @Input() text: string;
}
