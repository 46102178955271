import {
  ErrorHandler,
  Inject,
  Injectable,
  Injector,
  NgZone,
} from '@angular/core';

import { ExceptionHandlingService } from 'src/app/services/exception-handler.service';
@Injectable({
  providedIn: 'root',
})
export class ApplicationErrorHandler implements ErrorHandler {
  constructor(
    @Inject(Injector) private readonly injector: Injector,
    private zone: NgZone,
  ) {}

  handleError(error: any): void {
    const messageService = this.injector.get(ExceptionHandlingService);
    messageService.log(error);
  }
}
