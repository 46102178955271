<div id="hc-modal" class="{{ modalData.pageName | transloco }}">
  <!-- half Modal  -->
  <div class="hc-modal-container" *ngIf="halfModal">
    <div *ngIf="showXButton">
      <img
        src="../../../assets/images/close.svg"
        class="modal-close"
        alt=""
        (click)="onCloseClick($event)"
        (keyup.enter)="onCloseClick($event)"
        tabindex="0" />
    </div>
    <div *ngIf="modalData.image !== ''">
      <img
        src="../../../assets/images/{{ modalData.image }}"
        alt=""
        width="300" />
    </div>

    <h3 class="modal-title">{{ modalData.title | transloco }}</h3>
    <p class="modal-text">{{ modalData.contentText | transloco }}</p>
    <div *ngIf="modalData.textarea">
      <textarea
        class="hc-textarea"
        name=""
        id=""
        cols="40"
        rows="10"
        [(ngModel)]="textAreaValue"></textarea>
    </div>
    <div *ngIf="modalData.rejectComment">
      <textarea
        class="hc-textarea"
        name=""
        id=""
        cols="40"
        rows="3"
        [(ngModel)]="rejectCommentValue"></textarea>
    </div>
    <div *ngIf="modalData.forgotPasswordValue">
      <input
        class="hc-email"
        type="email"
        (change)="forgotPasswordFill($event)" />
      <span class="error" *ngIf="errorEmailStatus">{{
        "MESSAGE.FILL_EMAIL_ADDRESS" | transloco
      }}</span>
    </div>
    <div *ngIf="modalData.displayTextArea">
      <textarea
        class="hc-textarea"
        name=""
        id=""
        cols="40"
        rows="10"
        disabled
        >{{ modalData.textData }}</textarea
      >
    </div>

    <div class="button-container">
      <span
        class="preview-button"
        (click)="onPreviewClick()"
        (keyup.enter)="onPreviewClick()"
        tabindex="0"
        *ngIf="modalData.buttonPreviewText">
        <img src="./../../../assets/images/preview.svg" alt="" />
        {{ modalData.buttonPreviewText }}
      </span>
      <button
        mat-button
        (click)="onNextButtonClick($event)"
        *ngIf="modalData.buttonNextText"
        id="hc-button-primary">
        {{ modalData.buttonNextText | transloco }}
      </button>
      <button
        mat-button
        (click)="onRejectButtonClick($event)"
        *ngIf="modalData.buttonRejectText"
        id="hc-button-decline">
        {{ modalData.buttonRejectText | transloco }}
      </button>
      <button
        mat-button
        (click)="onCommentButtonClick()"
        *ngIf="modalData.buttonCommentText"
        [disabled]="!textAreaValue"
        id="hc-button-accept">
        {{ modalData.buttonCommentText | transloco }}
      </button>
      <button
        mat-button
        (click)="onForgotButtonClick()"
        *ngIf="modalData.buttonForgotText"
        [disabled]="!forgotValue"
        id="hc-button-primary">
        {{ modalData.buttonForgotText | transloco }}
      </button>
      <button
        mat-button
        (click)="onGoButtonClick($event)"
        *ngIf="modalData.buttonGoText"
        id="hc-modal-no-border-button">
        {{ modalData.buttonGoText | transloco }}
      </button>
    </div>
  </div>
  <!-- Full modal -->
  <div
    class="hc-modal-container hc-modal-full-container"
    *ngIf="!halfModal && !previewDisplay">
    <!-- Header -->
    <div *ngIf="showXButton">
      <img
        src="../../../assets/images/close.svg"
        class="modal-close"
        alt=""
        (click)="onCloseClick($event)"
        (keyup.event)="onCloseClick($event)"
        tabindex="0" />
    </div>
    <div *ngIf="modalData.image !== ''">
      <img
        src="../../../assets/images/{{ modalData.image }}"
        alt=""
        width="300" />
    </div>
    <div class="hc-grid hc-grid-header">
      <div class="hc-grid-item" *ngFor="let header of headerData">
        {{ header.title | transloco }}
      </div>
    </div>
    <!-- Data -->
    <div class="hc-grid-data">
      <div class="hc-grid" *ngFor="let item of modalDisplayData">
        <div class="hc-grid-item">
          {{ item.factory.factory_code }}
        </div>
        <div class="hc-grid-item">
          {{ item.factory.name }}
        </div>
        <div class="hc-grid-item">
          {{ item.port.name }}
        </div>
        <div class="hc-grid-item">
          {{ item.month_year | date: monthFormat }}
        </div>
        <div class="hc-grid-item">
          {{ item.grade.name }}
        </div>
        <div class="hc-grid-item">
          {{ item.packing.name }}
        </div>
        <div class="hc-grid-item">
          {{ item.destination ? item.destination.name : "-" }}
        </div>
        <div class="hc-grid-item">
          {{ item.payment_term.name }}
        </div>
        <div class="hc-grid-item">
          {{ item.price | price }}
        </div>
        <div class="hc-grid-item">
          {{ item.volume | number: "1.2-2" }}
        </div>
        <div class="hc-grid-item">
          {{ item.remarks }}
        </div>
        <div *ngIf="showPrivateRemark" class="hc-grid-item">
          {{ item.private_remarks }}
        </div>
      </div>
    </div>
    <!-- Button -->
    <button
      mat-button
      (click)="onGoButtonClick($event)"
      id="hc-button-no-border">
      {{ modalData.buttonGoText | transloco }}
    </button>
    <button
      mat-button
      (click)="onNextButtonClick($event)"
      id="hc-button-primary">
      {{ modalData.buttonNextText | transloco }}
    </button>
  </div>
  <!-- Preview Modal -->
  <div
    class="hc-modal-container hc-modal-full-container"
    *ngIf="modalData.previewDisplay">
    <div>
      <img
        src="../../../assets/images/close.svg"
        class="modal-close"
        alt=""
        (click)="onCloseClick($event)"
        (keyup.enter)="onCloseClick($event)"
        tabindex="0" />
    </div>
    <ng-template ngFor let-consumer [ngForOf]="selectCustomer" let-i="index">
      <div>
        <h2>
          {{ consumer.consumer.name }}
        </h2>
        <!-- Header -->
        <div class="hc-grid hc-grid-header">
          <div class="hc-grid-item">
            {{ "COMMON.PORT" | transloco }}
          </div>
          <div
            class="hc-grid-item"
            *ngFor="let month of modalData.preview.months">
            {{ month.settlement_month_string }}
          </div>
        </div>
        <!-- Preview Data -->
        <div class="hc-grid-data">
          <div class="hc-grid" *ngFor="let port of modalData.preview.ports">
            <div class="hc-grid-item">
              {{ port.port_code }}
            </div>
            <div
              class="hc-grid-item"
              *ngFor="let month of modalData.preview.months">
              <ng-template
                ngFor
                let-premium
                [ngForOf]="modalData.preview.premiums">
                <ng-template
                  [ngIf]="premium.consumer.id === consumer.consumer.id">
                  <div
                    *ngIf="
                      modalData.preview.data[month.settlement_month][port.id][
                        premium.id
                      ].selected
                    ">
                    {{
                      modalData.preview.data[month.settlement_month][port.id][
                        premium.id
                      ].value | price
                    }}
                  </div>
                  <div
                    *ngIf="
                      !modalData.preview.data[month.settlement_month][port.id][
                        premium.id
                      ].selected
                    ">
                    -
                  </div>
                </ng-template>
              </ng-template>
            </div>
          </div>
        </div>
        <!-- Email Distribution List -->
        <div>
          <h2>
            {{ "COMMON.EMAIL" | transloco }}
          </h2>
          <div class="email-grid">
            <div
              *ngFor="let email of consumer.consumer.email_distribution_list">
              <div class="name">
                {{ email ? email.name : "-" }}
              </div>
              <div class="email">
                {{ email ? email.email : "-" }}
              </div>
            </div>
            <!-- EMPTY EMAIL DISTRIBUTION LIST -->
            <div *ngIf="consumer.consumer.email_distribution_list.length === 0">
              <div class="name">
                {{ "-" }}
              </div>
              <div class="email">
                {{ "-" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <!-- POST TO CUSTOMER Button -->
    <button
      mat-button
      (click)="onNextButtonClick($event)"
      id="hc-button-primary">
      {{ modalData.buttonNextText | transloco }}
    </button>
  </div>
</div>
