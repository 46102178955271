<div
  class="file-upload-preview"
  (click)="onFileClick()"
  (keyup.enter)="onFileClick()"
  tabindex="0"
  matRipple
  [matRippleDisabled]="true">
  <div class="file-name">{{ fileWrapper.fileName }}</div>
  <button mat-icon-button (click)="onRemoveClick($event)">
    <mat-icon>delete</mat-icon>
  </button>
</div>
