<div class="gpsnr-password-setup">
  <div *ngIf="username !== ''; else loading">
    <div
      class="form-container"
      *ngIf="!isLinkExpired && !isPasswordReset; else redirectLink">
      <div>
        <h2>{{ infoMessage }}</h2>
        <p id="username" *ngIf="username !== ''; else loading">
          {{ "LOGIN.PLEASE_SET_A_PASSWORD" | transloco }}: {{ username }}
        </p>
      </div>
      <div *ngIf="username !== ''">
        <app-gpsnr-password-setup-form
          [token]="token"
          [uid]="uid"
          (passwordReset)="
            passwordResetSuccess()
          "></app-gpsnr-password-setup-form>
      </div>
    </div>
    <ng-template #redirectLink>
      <div>
        <h1 id="link-expired" *ngIf="isLinkExpired">
          {{ "LOGIN.LINKED_ALREADY_EXPIRED_OR_INVALID" | transloco }}
        </h1>
        <ng-container *ngIf="isPasswordReset">
          <div id="password-changed" class="password-changed-container">
            <h1 class="password-changed">
              {{ "LOGIN.PASSWORD_CHANGED" | transloco }}
            </h1>
            <span>
              {{ "LOGIN.YOUR_PASSWORD_HAS_BEEN_CHANGED" | transloco }}
            </span>
            <span>{{ "LOGIN.CLOSE_WINDOW_AND_LOGIN" | transloco }}</span>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </div>
  <ng-template #loading>
    <div class="please-wait">{{ "LOGIN.PLEASE_WAIT" | transloco }}</div>
  </ng-template>
</div>
