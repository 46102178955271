import { Component, ElementRef, OnInit } from '@angular/core';
import { ChatList, ChatRoom } from 'src/app/models/chatroom';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-chat-favorites',
  templateUrl: './chat-favorites.component.html',
  styleUrls: ['./chat-favorites.component.scss'],
})
export class ChatFavoritesComponent implements OnInit {
  chatList: ChatList;

  currentRooms: ChatRoom[] = [];

  chatListSearched: ChatList = {
    chatGroupList: [],
    chatUserList: [],
  };

  search: string;

  chatRoomExpansion = {
    chatGroup: true,
    chatUser: true,
  };

  constructor(
    private eRef: ElementRef,
    private chatService: ChatService,
  ) {}

  ngOnInit(): void {
    this.loadRoom();
  }

  private loadRoom() {
    this.chatService.roomList.subscribe((rooms: ChatRoom[]) => {
      this.chatList = this.chatService.formatChatList(
        rooms.filter((chat: ChatRoom) => chat.favourite),
      );
      this.searchList({});
    });
  }

  searchList(e: any) {
    this.chatListSearched = JSON.parse(JSON.stringify(this.chatList));
    for (const type of Object.keys(this.chatList)) {
      this.chatListSearched[type] = this.chatList[type].filter((item: any) =>
        !this.search ? true : this.checkSearch(item.name),
      );
    }
  }

  private checkSearch(value: string) {
    return !value
      ? false
      : value.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1;
  }

  openChat(room: ChatRoom) {
    this.chatService.open(room);
  }
}
