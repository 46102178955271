import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
/*
 * Returns the safe url
 * Takes an url argument.
 *   {{safeUrl:url }}
 */
@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(url: string): string {
    return this.domSanitizer.sanitize(SecurityContext.URL, url);
  }
}
