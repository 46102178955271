import {
  HttpClientJsonpModule,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ErrorHandler, NgModule, inject } from '@angular/core';
import {
  FirebaseApp,
  initializeApp,
  provideFirebaseApp,
} from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoModule, provideTransloco } from '@ngneat/transloco';
import { provideTranslocoPersistLang } from '@ngneat/transloco-persist-lang';
import { StoreModule } from '@ngrx/store';
import { NotifierModule } from 'angular-notifier';
import 'src/app/@types/array-prototypes';
import 'src/app/@types/number-prototypes';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import {
  APP_CONTAINERS,
  APP_INITIALIZERS,
  DEFAULT_THEME_PALLETTE,
  DI_INTERCEPTORS,
  NOTIFIER_OPTIONS,
  TRANSLOCO_CONFIG,
  TRANSLOCO_PERSIST_LANG_CONFIG,
} from './app.config';
import { BackupCodeModalComponent } from './components/modal/backup-code-modal/backup-code-modal.component';
import { ForgetMeModalComponent } from './components/modal/forget-me-modal/forget-me-modal.component';
import { SharedModule } from './components/shared.module';
import { CoreModule } from './core/core.module';
import { ChatModule } from './modules/chat/chat.module';
import { ApplicationErrorHandler } from './modules/exception-handling/error-handler/application-error-handler';
import { GpsnrPasswordSetupFormComponent } from './modules/gpsnr-password-setup/gpsnr-password-setup-form/gpsnr-password-setup-form.component';
import { GpsnrPasswordSetupComponent } from './modules/gpsnr-password-setup/gpsnr-password-setup.component';
import { NonHcUserComponent } from './modules/non-hc-user/non-hc-user.component';
import { NotificationModule } from './modules/notification/notification.module';
import { PasswordSetupFormComponent } from './modules/password-setup/password-setup-form/password-setup-form.component';
import { PasswordSetupComponent } from './modules/password-setup/password-setup.component';
import { PriceWidgetModule } from './modules/price-widget/price-widget.module';
import { TopMenuModule } from './modules/top-menu/top-menu.module';
import { AppRoutingModule } from './routes/app-routing.module';

@NgModule({
  declarations: [
    AppComponent,
    ForgetMeModalComponent,
    BackupCodeModalComponent,
    NonHcUserComponent,
    PasswordSetupComponent,
    PasswordSetupFormComponent,
    GpsnrPasswordSetupComponent,
    GpsnrPasswordSetupFormComponent,
    ...APP_CONTAINERS,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientJsonpModule,
    CoreModule,
    ReactiveFormsModule,
    NotifierModule.withConfig(NOTIFIER_OPTIONS),
    NotificationModule,
    PriceWidgetModule,
    ChatModule,
    TopMenuModule,
    StoreModule.forRoot({}, {}),
    TranslocoModule,
    provideFirebaseApp(() => initializeApp(environment.FIREBASE)),
    provideFirestore(() => getFirestore(inject(FirebaseApp))),
    provideAuth(() => getAuth(inject(FirebaseApp))),
  ],
  providers: [
    DI_INTERCEPTORS,
    provideHttpClient(withInterceptorsFromDi()),
    provideTransloco(TRANSLOCO_CONFIG),
    provideTranslocoPersistLang(TRANSLOCO_PERSIST_LANG_CONFIG),
    APP_INITIALIZERS,
    DEFAULT_THEME_PALLETTE,
    {
      provide: ErrorHandler,
      useClass: ApplicationErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
