import { Injectable } from '@angular/core';
import {
  webSocket,
  WebSocketSubject,
  WebSocketSubjectConfig,
} from 'rxjs/webSocket';

export interface RetryLogic {
  // number of retries before transitioning to slow mode
  transitionThreshold: number;
  // scale for timer after each retry
  scalingDuration: number;
  // after a certain number of retries, transition to slow mode using the maxDuration
  maxDuration: number;
}

@Injectable({
  providedIn: 'root',
})
export class WebSocketFactoryService {
  /**
   * Wrapper of webSocket to be able to inject it to the service
   * Makes it easier to mock rxjs/webSocket
   * @param urlConfigOrSource
   * @returns WebSocketSubject
   */
  public makeSocket<T>(
    urlConfigOrSource: string | WebSocketSubjectConfig<T>,
  ): WebSocketSubject<T> {
    return webSocket<T>(urlConfigOrSource);
  }
}
