import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import SGXInstrumentURL from 'src/assets/content/services/sgx-instrument-service.json';
import { BASE_URL, SHOW_LOADER } from '../interceptors/interceptor-context';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {
  Instrument,
  PhysicalIndexDataPoint,
} from '../models/sicom-latest-price';

@Injectable({
  providedIn: 'root',
})
export class SgxInstrumentApiService {
  /**
   * JSON object containing the service URLs
   * for the SGX Instrument API
   */
  private serviceURL = SGXInstrumentURL;
  /**
   * Default context for the http request
   */
  private defaultContext = new HttpContext()
    .set(BASE_URL, environment.SGX_INSTRUMENT_URL)
    .set(SHOW_LOADER, false);

  constructor(private http: HttpClient) {}

  /**
   * Get the SGX Instrument Map
   * @param context optional context for the http request,
   * will use the default context if not provided
   * @returns Observable of the http response
   */
  getSicomInstrumentMap(params: any = {}, context?: HttpContext) {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...params,
        },
      }),
      context: context ?? this.defaultContext,
    };
    return this.http.get(this.serviceURL.instrumentsMap, httpOptions);
  }

  /**
   * Get the SGX Instrument Data, with provided params
   * @param params the params for this http request should
   * include fields for isc (instrument code), datefrom, dateto
   * and pk
   * @param context optional context for the http request,
   * will use the default context if not provided
   * @returns Observable of the http response
   */
  getSGXInstrumentData(
    params: any = {},
    context?: HttpContext,
  ): Observable<Instrument> {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...params,
        },
      }),
      context: context ?? this.defaultContext,
    };
    return this.http.get<Instrument>(this.serviceURL.graphData, httpOptions);
  }
  /**
   * Get the Physical Index Data with provided params
   * @param params the params for this http request should
   * include fields for datefrom, dateto
   * @param context optional context for the http request,
   * will use the default context if not provided
   * @returns Observable of the Physical Index Data
   */
  getPhysicalIndexData(params: any = {}, context?: HttpContext) {
    const httpOptions = {
      params: new HttpParams({
        fromObject: {
          ...params,
        },
      }),
      context: context ?? this.defaultContext,
    };
    return this.http.get<PhysicalIndexDataPoint[]>(
      this.serviceURL.physicalIndexData,
      httpOptions,
    );
  }
}
