import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/*
 * Returns the price as string with 3 places
 */
const digitsInfo = '1.3-3';
@Pipe({ name: 'price' })
export class PricePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}
  transform(value?: number | string, locale?: string): string | null {
    if (!value) {
      return '0';
    }
    return this.decimalPipe.transform(value, digitsInfo, locale);
  }
}
