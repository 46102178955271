<h3>{{ dataSource?.chartTitle }}</h3>
<div>
  <canvas
    baseChart
    [type]="doughnutChartType"
    [datasets]="doughnutChartData.datasets"
    [labels]="dataSource.label"
    [plugins]="doughnutChartPlugin"
    [options]="doughnutChartOptions">
  </canvas>
</div>
