import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IFilter } from 'src/app/modules/factory/factory-filter/filter';
import Format from 'src/assets/content/format/date-time-format.json';

export interface FilterType {
  filters: IFilter[];
  searchInputText: string;
}

@Component({
  selector: 'app-base-filter',
  templateUrl: './base-filter.component.html',
  styleUrls: ['./base-filter.component.scss'],
})
export class BaseFilterComponent {
  @Input() filters: IFilter[];
  @Input() tooltipText: string = null;
  @Input() filterNameText = '';
  @Output() filterChange = new EventEmitter<FilterType>();
  @Output() refreshPage = new EventEmitter<FilterType>();
  filterChanged: Subject<string> = new Subject<string>();

  searchInputText: string;
  monthFormat = Format.monthFormat;

  constructor() {
    this.filterChanged
      .pipe(debounceTime(1000), distinctUntilChanged()) // only emit if value is different from previous value
      .subscribe((query) => {
        this.filterChange.emit({
          filters: this.filters,
          searchInputText: this.searchInputText,
        });
      });
  }

  onFilterChange(query) {
    this.filterChanged.next(query);
  }

  handleFilterChange = (e, filter) => {
    filter.selectedOption = e;
    this.filterChange.emit({
      filters: this.filters,
      searchInputText: this.searchInputText,
    });
  };

  handleClearFilters = () => {
    this.filters.forEach((filter) => {
      filter.selectedOption = [];
    });
    this.clearSearchAction();
  };

  clearSearchAction = () => {
    this.clearSearchInput();
    this.refreshPage.emit({
      filters: this.filters,
      searchInputText: this.searchInputText,
    });
  };

  clearSearchInput = () => {
    this.searchInputText = '';
    this.onFilterChange(this.searchInputText);
  };

  shouldFilter() {
    let requireFilter =
      this.searchInputText && this.searchInputText.slice(0, 13).length > 0;
    this.filters.forEach((filter) => {
      if (filter.selectedOption && filter.selectedOption.length > 0) {
        requireFilter = true;
      }
    });
    return requireFilter;
  }

  getSelectedText = (filter) => {
    if (Array.isArray(filter.selectedOption)) {
      return filter.selectedOption && filter.selectedOption.length > 0
        ? filter.selectedOption[0]
        : filter.placeHolderText;
    } else {
      return filter.selectedOption
        ? filter.selectedOption
        : filter.placeHolderText;
    }
  };
}
