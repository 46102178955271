<button
  mat-stroked-button
  [matMenuTriggerFor]="menu"
  (menuOpened)="isMenuOpened = true"
  (menuClosed)="isMenuOpened = false">
  <div class="lang-container">
    <span class="flag-icon">
      <mat-icon
        [inline]="true"
        [svgIcon]="currentLang.langDisplayIcon"></mat-icon>
    </span>
    <span>{{ currentLang.langDisplayId }}</span>
    <mat-icon class="animated-arrow" [class.up]="isMenuOpened"
      >expand_more</mat-icon
    >
  </div>
</button>
<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    *ngFor="let language of languages"
    (click)="chooseLanguage(language)">
    <span class="flag-icon">
      <mat-icon [inline]="true" [svgIcon]="language.langDisplayIcon"></mat-icon>
    </span>
    <span>{{ language.langDisplayName }}</span>
  </button>
</mat-menu>
