import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { NguCarouselModule } from '@ngu/carousel';
import { SharedModule } from 'src/app/components/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { ArpFxDisplayComponent } from './arp-fx-display/arp-fx-display.component';
import { ArpPhysicalIndexComponent } from './arp-physical-index/arp-physical-index.component';
import { ArpPriceWidgetComponent } from './arp-price-widget/arp-price-widget.component';
import { ArpSicomDisplayComponent } from './arp-sicom-display/arp-sicom-display.component';

@NgModule({
  declarations: [
    ArpPriceWidgetComponent,
    ArpSicomDisplayComponent,
    ArpFxDisplayComponent,
    ArpPhysicalIndexComponent,
  ],
  imports: [
    CoreModule,
    CommonModule,
    SharedModule,
    NguCarouselModule,
    TranslocoModule,
  ],
  exports: [ArpPriceWidgetComponent],
})
export class PriceWidgetModule {}
