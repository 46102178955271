import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { fromEvent, merge, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Company } from 'src/app/models/company';
import { IDisplayable } from 'src/app/models/display-models';
import {
  InputFieldModel,
  SelectFieldModel,
} from 'src/app/models/form-field-models';

@Component({
  selector: 'arp-filter-panel',
  templateUrl: './arp-filter-panel.component.html',
  styleUrls: ['./arp-filter-panel.component.scss'],
})
export class ArpFilterPanelComponent implements AfterViewInit, OnDestroy {
  /**
   * Subject used to stop all subscription
   * on component destruction.
   */
  destroy$ = new Subject<boolean>();
  /**
   * Used to acquire reference to query list of element ref for
   * input elements in template
   */
  @ViewChildren('inputField') inputList: QueryList<ElementRef>;
  /**
   * List of input fields to show in the filter panel
   */
  @Input() inputFields: InputFieldModel[] = [
    {
      placeholder: 'Contract ID',
      label: 'Search Contract ID',
      inputString: 'hello',
      inputType: 'text',
      for: 'contractId',
    },
  ];
  /**
   * List of select fields to show in the filter panel
   */
  @Input() selectFields: SelectFieldModel[] = [
    {
      placeholder: 'LEGAL_CONTRACT.CONTRACT_TYPE',
      label: 'LEGAL_CONTRACT.CONTRACT_TYPE',
      multiple: true,
      options: [
        {
          displayName: 'SPOT',
          value: 'SPOT',
        } as IDisplayable<string>,
        {
          displayName: 'LTC',
          value: 'LTC',
        } as IDisplayable<string>,
      ],
      selectedOptions: [],
      for: 'contractType',
    },
    {
      placeholder: 'LEGAL_CONTRACT.COUNTER_PARTY',
      label: 'LEGAL_CONTRACT.COUNTER_PARTY',
      multiple: true,
      options: [
        {
          displayName: 'ABP',
          value: {
            id: 1,
            name: 'ABP',
          },
        } as IDisplayable<Company>,
        {
          displayName: 'Bridgestone',
          value: {
            id: 2,
            name: 'Bridgestone',
          },
        } as IDisplayable<Company>,
      ],
      selectedOptions: [],
      for: 'counterParty',
    },
    {
      placeholder: 'LEGAL_CONTRACT.MONTH_CREATED',
      label: 'LEGAL_CONTRACT.MONTH_CREATED',
      multiple: true,
      options: [
        {
          displayName: 'Jan 2023',
          value: '2023-01-01',
        } as IDisplayable<string>,
        {
          displayName: 'Feb 2023',
          value: '2023-02-01',
        } as IDisplayable<string>,
        {
          displayName: 'Mar 2023',
          value: '2023-03-01',
        } as IDisplayable<string>,
        {
          displayName: 'Apr 2023',
          value: '2023-04-01',
        } as IDisplayable<string>,
      ],
      selectedOptions: [],
      for: 'createdDate',
    },
  ];
  /**
   * Emit event whenever an input field (debounced) and/or
   * select field value has been changed.
   */
  @Output() filtersChange = new EventEmitter();

  /**
   * Collect all input fields keyup event to debounce and handle whenever
   * input value changes.
   */
  ngAfterViewInit(): void {
    const inputObs = this.inputList.map((e) =>
      fromEvent(e.nativeElement, 'keyup'),
    );
    merge(...inputObs)
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe((e: any) => {
        this.handleInputChange(e.target.value);
      });
  }

  /**
   * Subject used to stop all subscriptions
   * on component destruction.
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  /**
   * handle opened/closed event from select fields to only emit filtersChange
   * event when the select field is closed, i.e. to allow for multiple selections
   * before emitting event.
   * @param opened boolean value indicating if select field is opened/closed.
   * @returns void
   */
  handleSelectOpenedChange(opened: boolean) {
    if (opened) {
      return;
    }
    const parsedQueryParams = this.parseQueryParams(
      this.inputFields,
      this.selectFields,
    );
    this.filtersChange.emit(parsedQueryParams);
  }

  /**
   * handle input field change event. To emit filtersChange event when the input field
   * value is changed (debounced).
   * @param value value in the input field.
   */
  handleInputChange(value: string) {
    const parsedQueryParams = this.parseQueryParams(
      this.inputFields,
      this.selectFields,
    );
    this.filtersChange.emit(parsedQueryParams);
  }

  /**
   * parse query params by iterating through all the input field values and select field
   * values to create a new queryParams object for event emission.
   * @returns queryParams object from the input fields values and select fields values
   */
  parseQueryParams(
    inputFields: InputFieldModel[],
    selectFields: SelectFieldModel[],
  ) {
    const queryParams = {};
    inputFields.forEach((inputField) => {
      queryParams[inputField.for] = inputField.inputString;
    });
    selectFields.forEach((selectField) => {
      queryParams[selectField.for] = selectField.selectedOptions;
    });
    return queryParams;
  }
}
