import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
import ErrorMessage from 'src/assets/content/error/error-message.json';
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements AfterViewInit, OnChanges {
  // WK: regex for validating decimal places
  private negativeTwoDecimalRegex = new RegExp(/^(-|\d)+(\.)?(\d{0,2})?$/g);
  private positiveTwoDecimalRegex = new RegExp(/^(\d)+(\.)?(\d{0,2})?$/g);

  // regex for validating three decimal places
  private negativeThreeDecimalRegex = new RegExp(/^(-|\d)+(\.)?(\d{0,3})?$/g);
  private positiveThreeDecimalRegex = new RegExp(/^(\d)+(\.)?(\d{0,3})?$/g);

  inline = ErrorMessage.inline;
  errorMessage: string;

  @Input() parentValue: number;
  @Input() placeholder: number;
  @Input() isVolume = false;
  @Input() isContainer = false;
  // Validation Values
  @Input() floorPrice: number;
  @Input() ceilingPrice: number;
  @Input() bidPrice: number;
  @Input() askPrice: number;
  // Additional Customization
  @Input() disabled = false;
  @Input() allowPositiveOnly = false;
  @Input() allowNull = false;
  @Input() allowZero = true;
  @Input() resetValue = true;

  @Output() changeEvent = new EventEmitter<number>();
  @Output() invalidValueEvent = new EventEmitter();
  // WK: input ref for clearing input value
  @ViewChild('hcInput') inputRef: ElementRef;

  ngAfterViewInit() {
    if (this.parentValue !== null && this.parentValue !== undefined) {
      this.formatValue();
      this.inputRef.nativeElement.value = this.parentValue;
    }
  }
  ngOnChanges() {
    if (
      this.parentValue !== null &&
      this.parentValue !== undefined &&
      this.inputRef
    ) {
      this.formatValue();
      this.inputRef.nativeElement.value = this.parentValue;
    }
  }
  formatValue() {
    // WK: BE may pass value as string format '100.0000' doing the conversion here instead of service level
    if (typeof this.parentValue === 'string') {
      this.parentValue =
        this.isVolume || this.isContainer
          ? Number(this.parentValue as string).toFixed(2)
          : Number(this.parentValue as string).toPrice();
    } else {
      this.parentValue =
        this.isVolume || this.isContainer
          ? this.parentValue.toFixed(2)
          : this.parentValue.toPrice();
    }
  }
  showError(message: string) {
    this.errorMessage = message;
  }

  clearErrorMessage() {
    this.errorMessage = undefined;
  }

  onInputPressed() {
    this.clearErrorMessage();
  }

  onInputMouseDown(event) {
    event.cancelBubble = true;
  }

  onInputChange(event: InputEvent) {
    let changedValue: string | number = (event.target as HTMLInputElement)
      .value;
    const value = this.isValid(changedValue);
    if (value) {
      // WK: value IS NaN when null is allowed
      changedValue = parseFloat(changedValue);
      this.changeEvent.emit(changedValue);
    } else {
      this.invalidValueEvent.emit();
    }
  }

  private validateNull(value: string): boolean {
    // NULL CHECK
    if (!this.allowNull && value.length === 0) {
      if (this.isVolume) {
        this.errorMessage = this.inline.inputVolume;
      } else if (this.isContainer) {
        this.errorMessage = this.inline.inputContainer;
      } else {
        this.errorMessage = this.inline.inputPrice;
      }
      this.resetInputValue();
      return false;
    }

    // 2DP GUARD
    if (this.isVolume || this.isContainer) {
      if (this.validateTwoDecimal(value) === null && value.length > 0) {
        this.errorMessage = this.inline.inputVolume;
        this.resetInputValue();
        return;
      }
    } else {
      if (this.validateThreeDecimal(value) === null && value.length > 0) {
        this.errorMessage = this.inline.inputPrice;
        this.resetInputValue();
        return false;
      }
    }

    return true;
  }

  private isValid(value: string): boolean {
    // check if input value is valid (depending on whether allowNull is true or false
    const isNullCheckValid = this.validateNull(value);
    if (!isNullCheckValid) {
      return false;
    }
    const digit = parseFloat(value);

    // check if input value is 0 (depending on whether allowZero is true or false)
    if (digit === 0 && !isNaN(digit)) {
      // ALLOW ZERO CHECK
      if (!this.allowZero) {
        this.errorMessage = this.isVolume
          ? this.inline.inputVolume
          : this.inline.inputPrice;
        this.resetInputValue();
        return false;
      } else {
        this.inputRef.nativeElement.value =
          this.isVolume || this.isContainer
            ? Number('0').toFixed(2)
            : Number('0').toPrice();
      }
    }

    // format the input into appropriate volume|container or price format in remaining cases
    if (digit !== 0 && !isNaN(digit)) {
      this.inputRef.nativeElement.value =
        this.isVolume || this.isContainer ? digit.toFixed(2) : digit.toPrice();
    }

    // validate price against price guards such as floor, ceiling,
    // bid, ask guards
    return this.validatePrice(digit);
  }

  validatePrice(digit): boolean {
    // FLOOR / CEILING PRICE GUARD
    if (!this.validateFloorPrice(digit) && !isNaN(digit)) {
      this.errorMessage = this.inline.inputPriceLessThanFloorPrice;
      this.resetInputValue();
      return false;
    }
    if (!this.validateCeilingPrice(digit) && !isNaN(digit)) {
      this.errorMessage = this.inline.inputPriceMoreThanCeilingPrice;
      this.resetInputValue();
      return false;
    }

    // BID / ASK PRICE GUARD
    if (!this.validateBidPriceForProducer(digit) && !isNaN(digit)) {
      this.errorMessage = this.inline.inputPriceProducerValidation;
      this.resetInputValue();
      return false;
    }
    if (!this.validateAskPriceForConsumer(digit) && !isNaN(digit)) {
      this.errorMessage = this.inline.inputPriceConsumerValidation;
      this.resetInputValue();
      return false;
    }
    return true;
  }
  public validate(): boolean {
    return this.isValid(this.inputRef.nativeElement.value);
  }

  getValue() {
    return this.inputRef.nativeElement.value;
  }

  public clear() {
    this.inputRef.nativeElement.value = '';
  }

  // RESET VALUE
  private resetInputValue() {
    if (this.resetValue) {
      this.inputRef.nativeElement.value = this.parentValue
        ? this.parentValue.toString()
        : '';
    }
  }

  // VALIDATION CHECKS
  private validateTwoDecimal(value: string) {
    return String(value).match(
      this.allowPositiveOnly
        ? this.positiveTwoDecimalRegex
        : this.negativeTwoDecimalRegex,
    );
  }

  private validateThreeDecimal(value: string) {
    return String(value).match(
      this.allowPositiveOnly
        ? this.positiveThreeDecimalRegex
        : this.negativeThreeDecimalRegex,
    );
  }

  private validateFloorPrice(value: number) {
    if (!this.floorPrice || value === 0) {
      return true;
    }
    return value >= this.floorPrice;
  }
  private validateCeilingPrice(value: number) {
    if (!this.ceilingPrice) {
      return true;
    }
    return value <= this.ceilingPrice;
  }
  private validateBidPriceForProducer(value: number) {
    if (!this.bidPrice) {
      return true;
    }
    // WK: As a producer, your ask price (value) must be >= to bid price
    return value >= this.bidPrice;
  }
  private validateAskPriceForConsumer(value: number) {
    // if ask price is for open offer, allow true
    if (!this.askPrice || parseInt(`${this.askPrice}`, 10) === 0) {
      return true;
    }
    // WK: As a consumer, your bid price (value) must be <= to ask price
    return value <= this.askPrice;
  }
}
