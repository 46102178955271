/**
 * @description Websocket notification from HCP Backend
 */
export enum HCPNotificationType {
  // CHAT
  NewChatMessage = 'new-chat-message',
  // QUOTATION
  PulledQuotation = 'pulled-quotation',
  NewOffer = 'new-offer',
  NewBid = 'new-bid',
  NewCounter = 'new-counter',
  NewDeal = 'new-deal',
  NewAccept = 'new-accept',
  NewDecline = 'new-decline',
  // APPROVALS
  NewApprovalRequest = 'new-approval-request',
  RevisedApprovalRequest = 'revised-approval-request',
  ReviewedApprovalRequest = 'reviewed-approval-request',
}
