<div class="hc-modal-container">
  <h3 class="mat-dialog-title">{{ modalData.title | transloco }}</h3>
  <mat-dialog-content>
    <!-- TODO: change to material input -->
    <input
      class="hc-email"
      type="email"
      [placeholder]="'FORGET_ME.EMAIL' | transloco"
      (input)="forgetPasswordFill($event)" />
    <span class="error" *ngIf="errorEmailStatus">{{
      "FORGET_ME.PLEASE_ENTER_VALID_EMAIL" | transloco
    }}</span>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>
      {{ "FORGET_ME.CANCEL" | transloco }}
    </button>
    <button
      mat-button
      id="hc-button-primary"
      [disabled]="errorEmailStatus"
      (click)="onSendClick()">
      {{ "FORGET_ME.SUBMIT" | transloco }}
    </button>
  </mat-dialog-actions>
</div>
