<div id="hc-modal" class="offer-error">
  <!-- Full modal -->
  <div class="hc-modal-container">
    <!-- Header -->
    <div>
      <img
        src="../../../assets/images/close.svg"
        class="modal-close"
        (click)="handleClose()"
        (keyup.enter)="handleClose()"
        tabindex="0"
        alt="close-icon" />
    </div>
    <div>
      <mat-icon inline="true" [svgIcon]="message.icon"></mat-icon>
    </div>
    <h3 class="modal-title">{{ message.title | transloco }}</h3>
    <p class="modal-text">{{ message.content }}</p>
    <!-- Data -->

    <!-- Button -->
    <button mat-button (click)="handleClose()" id="hc-button-primary">
      {{ message.buttonPrimaryText | transloco }}
    </button>
    <button
      mat-button
      (click)="handleAction()"
      id="hc-button-no-border"
      *ngIf="message.buttonSecondaryText !== undefined">
      {{ message.buttonSecondaryText | transloco }}
    </button>
  </div>
</div>
