import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChatList, ChatRoom } from 'src/app/models/chatroom';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-chat-discuss',
  templateUrl: './chat-discuss.component.html',
  styleUrls: ['./chat-discuss.component.scss'],
})
export class ChatDiscussComponent implements OnInit {
  @ViewChild('roomList', { static: false }) set roomListElement(
    content: ElementRef,
  ) {
    if (content?.nativeElement) {
      this.roombody = content.nativeElement as HTMLElement;
    }
  }

  @ViewChild('menu', { static: false }) set menuElement(content: ElementRef) {
    if (content?.nativeElement) {
      content.nativeElement.focus();
      if (content?.nativeElement) {
        this.menuBody = content.nativeElement as HTMLElement;
      }
    }
  }

  chatList: ChatList;

  currentRooms: ChatRoom[] = [];

  chatListSearched: ChatList = {
    chatGroupList: [],
    chatUserList: [],
  };

  search: string;

  chatRoomExpansion = {
    chatGroup: true,
    chatUser: true,
  };

  roombody: HTMLElement;
  menuBody: HTMLElement;
  openMenu = false;
  menuStyle: any;
  focusRoom: ChatRoom;
  downloadWindowOpen: boolean;
  // activatedChatMoreMenu: number;
  unreadChatRoom = {};

  constructor(
    private eRef: ElementRef,
    private chatService: ChatService,
  ) {}

  ngOnInit(): void {
    this.loadRoom();
    if (this.chatService.downloadChat) {
      this.chatService.downloadChat.subscribe((res) => {
        this.downloadWindowOpen = typeof res !== 'undefined';
      });
    }
  }

  private loadRoom() {
    this.chatService.roomList.subscribe((rooms: ChatRoom[]) => {
      this.chatList = this.chatService.formatChatList(rooms);
      this.searchList({});
    });
  }

  searchList(e: any) {
    this.chatListSearched = JSON.parse(JSON.stringify(this.chatList));
    for (const type of Object.keys(this.chatList)) {
      this.chatListSearched[type] = this.chatList[type].filter((item: any) =>
        !this.search ? true : this.checkSearch(item.name),
      );
    }
  }

  private checkSearch(value: string) {
    return !value
      ? false
      : value.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1;
  }

  onClickMoreMenu(event: Event, room: ChatRoom) {
    event.stopPropagation();
    this.openMenu = true;
    this.focusRoom = room;
    const menuPosition = (event.target as HTMLElement).getBoundingClientRect();
    const boxPosition = this.roombody.getBoundingClientRect();

    // Add the buffered padding left
    let left = boxPosition.width - 200;
    if (this.downloadWindowOpen) {
      left = boxPosition.width + 100;
    }

    this.menuStyle = {
      'left.px': left,
      'top.px': menuPosition.top - 15,
    };
  }

  openChat(room: ChatRoom) {
    if (room.unread_messages > 0) {
      this.chatService.readMessage(room);
    }
    this.chatService.open(room);
  }

  onRoomScroll(): void {
    this.closeMenu();
  }

  closeMenu(): void {
    if (this.openMenu) {
      this.openMenu = false;
    }
  }

  downloadChat(): void {
    this.chatService.addToChatDownload(this.focusRoom);
    this.openMenu = false;
  }

  starChat(): void {
    this.chatService.updateChatroom({
      ...this.focusRoom,
      favourite: !this.focusRoom.favourite,
    });
    this.openMenu = false;
  }

  readChat(): void {
    this.chatService.readMessage(this.focusRoom);
    this.openMenu = false;
  }
}
