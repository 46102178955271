import { Injectable, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, throwError } from 'rxjs';
import {
  AvailableStatus,
  ChatDownloadSelected,
  ChatList,
  ChatMessage,
  ChatMessageObject,
  ChatMessageStore,
  ChatRoom,
  ChatRoomMessageRead,
  ChatRoomType,
  ChatRoomUpdate,
  ChatTimeline,
} from '../models/chatroom';
import { StorageService } from './storage.service';
import { ChatIntegrationService } from './chat-integration.service';
import { UserSettingsService } from './user-settings.service';
import { Settings } from '../models/settings';
import { User } from '../models/user';
import { catchError, map, tap } from 'rxjs/operators';
import { Company, CompanyType } from '../models/company';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private LIMIT_CHAT = 2;
  public user: BehaviorSubject<User>;
  public company: BehaviorSubject<Company>;
  public userSetting: BehaviorSubject<Settings>;
  public showChat: BehaviorSubject<boolean>;
  public openRooms: BehaviorSubject<ChatRoom[]>;
  public roomList: BehaviorSubject<ChatRoom[]>;
  public activeRoom: BehaviorSubject<ChatRoom>;
  public downloadChat: BehaviorSubject<ChatDownloadSelected>;
  public forwardMessage: BehaviorSubject<ChatMessage>;
  public chatStatus: BehaviorSubject<AvailableStatus>;
  public messageStore: ChatMessageStore;
  public chatStatusList = [
    { name: 'Available', label: 'Available', icon: 'online' },
    { name: 'Offline', label: 'Offline', icon: 'offline' },
  ];
  @Output() membersStatusUpdated = new EventEmitter<void>();

  constructor(
    private storageService: StorageService,
    private chatIntegrationService: ChatIntegrationService,
    private userSettingsService: UserSettingsService,
  ) {}

  initialService(): void {
    this.cleanProperty();
    this.loadUserSetting().then(() => {
      this.loadRoomList();
    });
  }

  closeService(): void {
    this.cleanProperty();
  }

  private cleanProperty(): void {
    this.openRooms = new BehaviorSubject([]);
    this.roomList = new BehaviorSubject([]);
    this.downloadChat = new BehaviorSubject(undefined);
    this.forwardMessage = new BehaviorSubject(undefined);
    this.user = new BehaviorSubject(undefined);
    this.company = new BehaviorSubject(undefined);
    this.userSetting = new BehaviorSubject(undefined);
    this.activeRoom = new BehaviorSubject(undefined);
    this.chatStatus = new BehaviorSubject({
      name: 'Available',
      label: 'Available',
      icon: 'online',
    });
    const checkShowChat = this.storageService.check('show_chat');
    this.showChat = checkShowChat
      ? new BehaviorSubject(this.storageService.retrieve<boolean>('show_chat'))
      : new BehaviorSubject(checkShowChat);
    this.messageStore = {};
  }

  chatMaximize() {
    this.showChat.next(true);
    this.storageService.store('show_chat', true);
  }

  chatMinimize() {
    this.showChat.next(false);
    this.storageService.store('show_chat', false);
  }

  public open(room: ChatRoom): void {
    const existChatIndex = this.openRooms.value.findIndex(
      (item: ChatRoom) => item.id === room.id,
    );
    if (existChatIndex < 0) {
      if (this.openRooms.value.length >= this.LIMIT_CHAT) {
        this.openRooms.value.splice(0, 1);
      }
      this.openRooms.value.push(room);
    }
    this.openRooms.next(this.openRooms.value);
  }

  public close(room: ChatRoom): void {
    const index = this.openRooms.value.findIndex(
      (item: ChatRoom) => item.id === room.id,
    );
    this.openRooms.value.splice(index, 1);
    this.openRooms.next(this.openRooms.value);
  }

  public updateChatroom(room: ChatRoom): void {
    this.chatIntegrationService
      .updateChatroom('' + room.id, {
        id: room.id,
        name: room.raw_name,
        is_hidden: room.is_hidden,
        favourite: room.favourite,
      } as ChatRoomUpdate)
      .subscribe((res) => {
        const roomUpdate = this.getRoomById(room.id);
        roomUpdate.is_hidden = room.is_hidden;
        roomUpdate.favourite = room.favourite;
        this.roomList.next(this.roomList.value);
      });
  }

  public getRoomById(roomId: number): ChatRoom {
    return this.roomList.value.find(
      (roomObj: ChatRoom) => roomObj.id === roomId,
    );
  }

  private loadRoomList() {
    this.chatIntegrationService
      .getChatroom(1000, 1)
      .subscribe((roomList: any) => {
        this.roomList.next(
          roomList.results.map((room) => this.mapJsonToModel(room)),
        );
      });
  }

  private loadUserSetting(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.userSettingsService.getUserInfo().subscribe((user: any) => {
        this.user.next({
          id: user.id,
          created_at: new Date(user.created_at),
          name: user.name,
          company: user.company.id,
        } as User);
        this.company.next({
          id: user.company?.id,
          name: user.company?.name,
          legal_entity_name: user.company?.legal_entity_name,
          type: CompanyType[user.company?.type],
          company_logo_url: user.company?.company_logo_url,
        } as Company);

        this.userSetting.next({
          enable_sounds: user.settings?.enable_chat_sound ?? true,
          enable_chat_sound: user.settings?.enable_chat_sound ?? true,
          enable_notification_sound:
            user.settings?.enable_notification_sound ?? true,
        } as Settings);
        resolve(true);
      });
    });
  }

  public toggleSoundSetting() {
    this.userSettingsService
      .patchUserSettings({
        enable_chat_sound: !this.userSetting.value.enable_sounds,
      })
      .subscribe((setting: any) => {
        this.userSetting.next({
          ...this.userSetting.value,
          enable_sounds: !this.userSetting.value.enable_sounds,
        } as Settings);
      });
  }

  public loadChatMessages(roomId: number, perPage: number, page: number) {
    const room = this.getRoomById(roomId);
    room.error = '';
    this.chatIntegrationService
      .getRoomMessage(roomId + '', perPage, page)
      .subscribe({
        next: (chatRes) => {
          this.setChatMessage(
            roomId,
            chatRes.results.map((chat: any) => this.formatChatMessage(chat)),
          );
        },
        error: (error: Error) => {
          room.error = error.message;
        },
      });
  }

  public setChatMessage(roomId: number, newChatList: ChatMessage[]): void {
    const oldChatList = this.retriveChat(roomId).value;
    const removeDup = {} as ChatMessageObject;
    oldChatList.forEach((chatObj) => (removeDup[chatObj.id] = chatObj));
    newChatList.forEach((chatObj) => (removeDup[chatObj.id] = chatObj));
    const chatMessage = Object.values(removeDup).sort((a, b) =>
      a.id > b.id ? 1 : -1,
    );
    this.retriveChat(roomId).next(chatMessage);
  }

  public sendChatMessage(
    roomId: number,
    newMessage: ChatMessage,
  ): Observable<ChatMessage> {
    const room = this.getRoomById(roomId);
    room.error = '';
    return this.chatIntegrationService
      .sendMessage(roomId + '', newMessage)
      .pipe(
        map((chat: any) => {
          const chatObj = this.formatChatMessage(chat);
          this.setChatMessage(roomId, [chatObj]);
          return chatObj;
        }),
        catchError((error: Error) => {
          room.error = error.message;
          return throwError(() => error);
        }),
      );
  }

  public formatChatMessage(chat: any): ChatMessage {
    return {
      id: chat.id,
      sent_at: new Date(chat.sent_at),
      type: chat.type,
      quotation: chat.quotation,
      message: chat.message,
      room: chat.room,
      user: chat.user,
      read_by: chat.read_by,
    } as ChatMessage;
  }

  public retriveChat(roomId): BehaviorSubject<ChatMessage[]> {
    if (typeof this.messageStore[roomId] === 'undefined') {
      this.messageStore[roomId] = new BehaviorSubject([]);
    }
    return this.messageStore[roomId];
  }

  public updateMemberStatus(roomId, userId, status) {
    const room_index = this.roomList.value.findIndex(
      (item: ChatRoom) => item.id === roomId,
    );
    const room = this.roomList.value[room_index];
    if (!room) return;

    const user_index = room.members.findIndex(
      (item) => item.user_id === userId,
    );
    if (user_index > 0) {
      room.members[user_index].online = status;
      this.membersStatusUpdated.emit();
    }
  }

  public formatChatList(chatRooms: ChatRoom[]): ChatList {
    const chatList = {
      chatGroupList: [],
      chatUserList: [],
    } as ChatList;
    chatRooms.forEach((room: ChatRoom) => {
      if (room.type === ChatRoomType.group) {
        chatList.chatGroupList.push(room);
      } else if (room.type === ChatRoomType.user) {
        chatList.chatUserList.push(room);
      }
    });
    return chatList;
  }

  public readMessage(room: ChatRoom) {
    this.chatIntegrationService
      .readMessage(room.id + '', {} as ChatRoomMessageRead)
      .subscribe(() => {
        room.unread_messages = 0;
      });
  }

  public downloadChatHistory(): Observable<any> {
    const chatDownloadList = this.downloadChat.value || {};
    const jobs = [];
    const chatList: ChatTimeline[] = [];
    for (const roomId of Object.keys(chatDownloadList)) {
      const chatTimeline = chatDownloadList[roomId] as ChatTimeline;
      chatList.push(chatTimeline);
      let startDatetime = this.dateFormat(chatTimeline.start_dt);
      let endDatetime = this.dateFormat(chatTimeline.end_dt);
      if (chatTimeline.timeline === 'Full Chat') {
        startDatetime = this.dateFormat(new Date(0));
        endDatetime = this.dateFormat(new Date());
      }
      jobs.push(
        this.chatIntegrationService.downloadChatroomHistory(
          `${roomId}`,
          startDatetime,
          endDatetime,
        ),
      );
    }

    return forkJoin(jobs).pipe(
      tap((csvContent: string[]) => {
        csvContent.forEach((chatContent: string, index: number) => {
          const fileName = `chat_download_${chatList[index].room.name}_${chatList[index].timeline}`;
          const blob = new Blob([chatContent], {
            type: 'text/csv;charset=utf-8',
          });
          saveAs(blob, fileName);
        });
      }),
    );
  }

  public dateFormat(dateInput: Date) {
    const year = dateInput.getFullYear();
    const month = dateInput.getMonth() + 1;
    const day = dateInput.getDate();
    return (
      year +
      '-' +
      ('' + month).padStart(2, '0') +
      '-' +
      ('' + day).padStart(2, '0')
    );
  }

  public dateFormatShort(dateInput: Date) {
    const year = dateInput.getFullYear();
    const month = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ][dateInput.getMonth()];
    const day = dateInput.getDate();
    return (
      ('' + day).padStart(2, '0') +
      ' ' +
      ('' + month) +
      ' ' +
      ('' + year).substr(2, 2)
    );
  }

  private mapJsonToModel(jsonObj: any): ChatRoom {
    return {
      id: jsonObj.id,
      room_id: jsonObj.room_id,
      producer: jsonObj.producer,
      consumer: jsonObj.consumer,
      producer_user: jsonObj.producer_user,
      consumer_user: jsonObj.consumer_user,
      name: this.formatRoomName(jsonObj),
      raw_name: jsonObj.name,
      type: ChatRoomType[jsonObj.type],
      is_hidden: jsonObj.is_hidden,
      favourite: jsonObj.favourite,
      members: jsonObj.members,
      unread_messages: jsonObj.unread_messages,
      error: '',
    };
  }

  private formatRoomName(room: ChatRoom): string {
    let removeWord = '';
    if (room.type === ChatRoomType.group) {
      const company = this.company.getValue();
      removeWord = company.name;
    } else if (room.type === ChatRoomType.user) {
      const user = this.user.getValue();
      removeWord = user.name;
    }
    return room.name
      .split('/')
      .filter((label) => label !== removeWord)
      .join('/');
  }

  public addToChatDownload(room: ChatRoom): void {
    const value = this.downloadChat.value || {};
    const now = new Date();
    value[room.id] = {
      room,
      timeline: 'Full Chat',
      start_dt: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
      end_dt: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
    } as ChatTimeline;
    this.downloadChat.next(value);
  }

  public setUnread(roomId: number): void {
    if (this.isActiveRoom(roomId)) {
      this.readMessage(this.activeRoom.value);
    } else {
      const roomUnread = this.getRoomById(roomId);
      if (roomUnread) {
        roomUnread.unread_messages++;
      }
    }
  }

  public isActiveRoom(roomId: number): boolean {
    if (this.activeRoom.value?.id === roomId) {
      return true;
    }
    return false;
  }
}
