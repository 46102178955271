import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationDropdownComponent } from './notification-dropdown/notification-dropdown.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { NotificationComponent } from './notification-list/notification/notification.component';
import { CoreModule } from 'src/app/core/core.module';
import { TranslocoModule } from '@ngneat/transloco';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    NotificationDropdownComponent,
    NotificationListComponent,
    NotificationComponent,
  ],
  imports: [CoreModule, CommonModule, TranslocoModule, ScrollingModule],
  exports: [NotificationDropdownComponent],
})
export class NotificationModule {}
